import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import UnicornTable from './unicornTable';
import unicorn from "./unicorn.jpg";
import { motion } from "framer-motion";
import { ArrowRight } from "lucide-react"

const UnicornTracker = () => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://api-hub.ventureintelligence.com/public/unicorn-tracker/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ key: 'value' }),
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        setTableData(result.data.reverse());
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const sortData = (key, isNumeric = false, isDate = false) => {
    const sortedData = [...tableData].sort((a, b) => {
      let valueA = a[key];
      let valueB = b[key];

      if (isNumeric) {
        return parseFloat(valueA) - parseFloat(valueB);
      } else if (isDate) {
        return new Date(valueA) - new Date(valueB);
      } else {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      }
    });
    setTableData(sortedData);
  };

  return (
    <Layout>
      <div className="min-h-screen">
        <div className="relative h-80 mb-12">
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{ 
              backgroundImage: `url(${unicorn})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="absolute inset-0 bg-black/50"></div>
          </div>
          
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="relative z-10 h-full flex flex-col items-center justify-center text-center px-4"
          >
            <h1 className="text-5xl font-bold text-[#efefef] mb-6 relative group">
              <span className="relative">
                Venture Intelligence Unicorn Tracker
                <span className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-[#c59c40] to-[#a07924] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </span>
            </h1>
            <h3 className="text-2xl md:text-[30px] text-[#efefef] font-semibold px-2 rounded-lg py-2">
              List of Indian Startups valued at 
              <span className="px-2 mx-1 rounded">$1 Billion</span>
              or more
            </h3>
          </motion.div>
        </div>

        <div className="mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <div className="w-full overflow-x-auto" style={{ maxWidth: '100%' }}>
              <UnicornTable
                loading={loading}
                tableData={tableData}
                sortByCompany={() => sortData("company")}
                sortBySector={() => sortData("sector")}
                sortByEntryValuation={() => sortData("entry_valuation", true)}
                sortByValuation={() => sortData("valuation", true)}
                sortByEntry={() => sortData("entry_date", false, true)}
                sortByLocation={() => sortData("location")}
              />
            </div>
          </motion.div>

          <div className="text-gray-800 mt-10 pl-6 leading-loose">
            <div className="grid grid-cols-1 gap-6 mb-12">
              <div className="space-y-2">
                <div className="grid grid-cols-[auto_1fr] gap-8 items-center">
                  <span className="font-bold whitespace-nowrap">Unicorn:</span>
                  <span>A unicorn company is any private company that is valued at $1-Billion or more and is Venture Capital funded.</span>
                </div>
                <div className="grid grid-cols-[auto_1fr] gap-8 items-center">
                  <span className="font-bold whitespace-nowrap">^ Graduated Unicorn:</span>
                  <span>A startup that has now become Publicly Listed (or) has been acquired by a Publicly Listed company.</span>
                </div>
                <div className="grid grid-cols-[auto_1fr] gap-8 items-center">
                  <span className="font-bold whitespace-nowrap">* Former Unicorn:</span>
                  <span>A startup that lost its Unicorn status due to a valuation degrading event.</span>
                </div>
                <div className="grid grid-cols-[auto_1fr] gap-8 items-center">
                  <span className="font-bold whitespace-nowrap">Entry Valuation:</span>
                  <span>The company valuation at the time it first became a Unicorn.</span>
                </div>
                <div className="grid grid-cols-[auto_1fr] gap-8 items-center">
                  <span className="font-bold whitespace-nowrap">Valuation:</span>
                  <span>The company valuation at the time of the latest round of funding.</span>
                </div>
              </div>
            </div>
            <div className="mb-8 space-y-6">
              <div className="space-y-2">
                <p className="text-lg">
                  Access the latest edition of the Indian Unicorns report for comprehensive insights and analysis.
                </p>
                <a 
                  href="/india_unicorn_report_form" 
                  className="inline-flex items-center px-4 py-2 bg-[#bc8b20] hover:bg-[#d6b15f] text-sm text-white rounded-md transition-colors duration-200 font-medium"
                >
                  Download Report
                </a>
              </div>
              
              <div className="space-y-2">
                <p className="text-lg">
                  Get detailed information about Unicorns including investment and valuation details, financials, return multiples, etc.,
                </p>
                <a 
                  href="/trial.html" 
                  className="inline-flex items-center px-4 py-2 bg-[#bc8b20] hover:bg-[#d6b15f] text-sm text-white rounded-md transition-colors duration-200 font-medium"
                >
                  Request Trial Access
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default UnicornTracker;