import React from "react";
import Loader from "../Loader";
import OverflowToolTip from "../shared/OverflowToolTip";

/**
 * The AcquirerOverview component displays the profile information of an acquirer, including their
 * CIN (Corporate Identification Number), website, city, country, and industry.
 * @returns The AcquirerOverview component is being returned.
 */
const AcquirerOverview = ({ profile, isLoading, cin }) => {
  return (
    <div>
      <div className="space-y-6 px-4 bg-[#EDEDED]">
        <div className="border">
          <div className="lg:mt-4 lg:bg-white rounded-[6px]" x-show="tab === 1">
            {isLoading ? (
              <div className="w-full h-40 flex items-center justify-center">
                <Loader />
              </div>
            ) : (
              <div className="w-full lg:grid lg:grid-cols-6 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
                <div className="mt-3 lg:mt-0 rounded-lg lg:rounded lg:col-span-6 lg:border-r bg-white">
                  <div className="px-5 py-4">
                    <p className="text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                      Profile
                    </p>
                    <div className="overflow-x-auto scrollbar mt-1">
                      <div className="w-full bg-[#FFFFFF] font-sans_book_body">
                        {/* First Row - CIN First */}
                        <div className="grid grid-cols-3 gap-7 pt-2">
                          {/* CIN Field - Only Show if CIN Exists */}
                          {cin && 
                          (
                            <div className="lg:leading-[21px]">
                              <p className="text-[11pt] leading-[18pt] lg:text-[14px] text-[#333333]">
                                CIN 
                              </p>
                              <p className="text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] font-semibold">
                                {cin ? cin : '--'}
                              </p>
                            </div>
                          )}

                          <div className="lg:leading-[21px]">
                            <p className="text-[11pt] leading-[18pt] lg:text-[14px] text-[#333333]">
                              Website
                            </p>
                            <div className="lg:leading-[21px]">
                              {profile?.acquirer_website ? (
                                <a
                                  href={profile?.acquirer_website}
                                  target="_blank"
                                  className="text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] font-semibold"
                                  rel="noreferrer"
                                >
                                  <OverflowToolTip
                                    component={profile?.acquirer_website}
                                  />
                                </a>
                              ) : (
                                "--"
                              )}
                            </div>
                          </div>

                          <div className="lg:leading-[21px]">
                            <p className="text-[11pt] leading-[18pt] lg:text-[14px] text-[#333333]">
                              City
                            </p>
                            <p className="text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] font-semibold">
                              {profile?.acquirer_city || "--"}
                            </p>
                          </div>

                          {!cin && (
                            <div className="lg:leading-[21px]">
                            <p className="text-[11pt] leading-[18pt] lg:text-[14px] text-[#333333]">
                              Country
                            </p>
                            <p className="text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] font-semibold">
                              {profile?.acquirer_country || "--"}
                            </p>
                          </div>
                          )}
                        </div>

                        {/* Second Row */}
                        <div className="grid grid-cols-3 gap-7 pt-4">
                          {cin && (
                          <div className="lg:leading-[21px]">
                            <p className="text-[11pt] leading-[18pt] lg:text-[14px] text-[#333333]">
                              Country
                            </p>
                            <p className="text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] font-semibold">
                              {profile?.acquirer_country || "--"}
                            </p>
                          </div>
                          )}

                          <div className="lg:leading-[21px]">
                            <p className="text-[11pt] leading-[18pt] lg:text-[14px] text-[#333333]">
                              Industry (Existing Targets)
                            </p>
                            <p className="text-[11pt] leading-[18pt] lg:text-[13px] text-[#333333] font-semibold">
                              {profile?.tagrget_industry || "--"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcquirerOverview;
