import React, { useState } from 'react';
import Layout from '../../../components/layout';
import { motion } from "framer-motion";
import { ArrowRight, Loader2 } from "lucide-react";
import ReCAPTCHA from "react-google-recaptcha";
import impact from "./impact.png";
import { baseUrl } from '../../../../Config/config';

const PEImpactReport = () => {
  const [formData, setFormData] = useState({
    name: '',
    firm_name: '',
    designation: '',
    email: '',
    city: '',
    country: '',
    phone: '',
    firm_type: '',
    about_our_service: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!recaptchaToken) {
      setError('Please complete the reCAPTCHA');
      return;
    }

    setLoading(true);
    setError('');
    
    try {
      const jsonData = {
        ...formData,
        recaptcha: recaptchaToken
      };

      const response = await fetch(`${baseUrl}/peImpactReport`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(jsonData)
      });

      if (!response.ok) throw new Error('Failed to submit form');

      const downloadPDF = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "PE_Impact_Report.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      response.json().then(data => {
        if (response.ok && data.download_url) {
            downloadPDF(data.download_url);
        } else {
            console.error("Failed to fetch the download URL");
        }
    }).catch(error => {
        console.error("Error parsing JSON:", error);
    });

      setSuccess(true);
      
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const formFields = [
    { name: 'name', type: 'text', label: 'Your Name' },
    { name: 'firm_name', type: 'text', label: 'Firm Name' },
    { name: 'designation', type: 'text', label: 'Designation' },
    { name: 'email', type: 'email', label: 'Email Id' },
    { name: 'city', type: 'text', label: 'City' },
    { name: 'country', type: 'text', label: 'Country' },
    { name: 'phone', type: 'text', label: 'Phone' },
  ];

  const firmTypes = [
    { id: 'pevc-investor', label: 'PE/VC Investor' },
    { id: 'company-seeking-funding', label: 'Company Seeking Funding' },
    { id: 'advisory-firm', label: 'Advisory Firm' },
    { id: 'other', label: 'Other' }
  ];

  return (
    <Layout>
      <div className="min-h-screen">
        <div className="relative h-48 mb-12">
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{ 
              backgroundImage: `url(${impact})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <div className="absolute inset-0 bg-black/20"></div>
          </div>
          
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="relative z-10 h-full flex flex-col items-center justify-center text-center px-4"
          >
            <h1 className="text-5xl font-bold text-[#efefef] mb-6 relative group">
              <span className="relative">
                PE Impact
                <span className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-[#c59c40] to-[#a07924] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
              </span>
            </h1>
          </motion.div>
        </div>

        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="max-w-4xl mx-auto mb-8"
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
              Private Equity Impact Report
            </h2>
            <p className="text-base text-gray-700 mb-4 leading-relaxed text-center">
              Private Equity Impact is Venture Intelligence's effort, in collaboration with the PE/VC industry, 
              to showcase the contribution of the industry towards the broader economy and other stakeholders in the ecosystem.
            </p>
            <h3 className="text-base text-center text-gray-800 mb-6">
              Just complete the following details to download the latest PE Impact report.
            </h3>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg py-8 px-6 mb-8"
          >
            <form onSubmit={handleSubmit} className="space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6">
                {formFields.map((field) => (
                  <div key={field.name} className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700 capitalize">
                      {field.label}*
                    </label>
                    <input
                      type={field.type}
                      name={field.name}
                      required
                      value={formData[field.name]}
                      onChange={handleChange}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#c59c40] focus:ring-[#c59c40] sm:text-sm px-4 py-2 border"
                    />
                  </div>
                ))}
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 mb-3">
                  Firm Type*
                </label>
                <div className="flex flex-nowrap overflow-x-auto gap-4 pb-2">
                  {firmTypes.map((type) => (
                    <div key={type.id} className="flex-shrink-0">
                      <div className="flex items-center h-12 bg-gray-50 rounded-lg px-4 hover:bg-gray-100 transition-colors whitespace-nowrap">
                        <input
                          type="radio"
                          id={type.id}
                          name="firm_type"
                          value={type.id}
                          onChange={handleChange}
                          required
                          className="focus:ring-[#c59c40] h-4 w-4 text-[#c59c40] border-gray-300"
                        />
                        <label htmlFor={type.id} className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer">
                          {type.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  How did you learn about our service?*
                </label>
                <textarea
                  name="about_our_service"
                  required
                  value={formData.about_our_service}
                  onChange={handleChange}
                  rows={3} 
                  className="block w-[75%] rounded-md border-gray-300 shadow-sm focus:border-[#c59c40] focus:ring-[#c59c40] sm:text-sm px-4 py-2 border"
                />
              </div>

              <div className="flex justify-center mt-6">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={handleRecaptcha}
                />
              </div>

              {error && (
                <p className="text-red-600 text-sm text-center">{error}</p>
              )}

              <button
                type="submit"
                disabled={loading}
                className="w-full max-w-md mx-auto flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#bc8b20] hover:bg-[#d6b15f] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#c59c40] transition-colors duration-200"
              >
                {loading ? (
                  <Loader2 className="w-5 h-5 animate-spin" />
                ) : (
                  <>
                    Submit <ArrowRight className="ml-2 h-4 w-4" />
                  </>
                )}
              </button>

              {success && (
                <p className="text-green-600 text-sm text-center">
                  Downloaded successfully!
                </p>
              )}
            </form>
          </motion.div>
        </div>
      </div>
    </Layout>
  );
};

export default PEImpactReport;