import React, { useEffect } from "react";
import { Radio } from "antd";
import { useSearchParams } from "react-router-dom";

const SubcategorySelector = ({ subCategory, toggleTableHandler, category, setSubCategory }) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Get subCategory from URL params if available, else default to "pe"
    const urlSubCategory = searchParams.get("subCategory");
    setSubCategory(urlSubCategory || "pe");
  }, [category, searchParams]); // Re-run when category or URL params change

  const onChange = (e) => {
    toggleTableHandler(e.target.value);
  };

  return (
    <div className="mt-7">
      <Radio.Group 
        value={subCategory} 
        onChange={onChange}
        buttonStyle="solid"
        size="middle"
      >
        <Radio.Button value="pe">PE</Radio.Button>
        <Radio.Button value="m&a">M&A</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default SubcategorySelector;
