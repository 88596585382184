import React, { useState, useRef } from 'react';
import Layout from '../../../components/layout';
import { motion } from "framer-motion";
import { ArrowRight, Loader2, Upload } from "lucide-react";
import ReCAPTCHA from "react-google-recaptcha";
import impact from "./impact.png";

const FundingRequestForm = () => {
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null);
  
  const [formData, setFormData] = useState({
    name: '',
    designation: '',
    registered_name: '',
    cin: '',
    country: '',
    city: '',
    industry: '',
    sector: '',
    website: '',
    amount_sought: '',
    revenue: '',
    email: '',
    mobile: '',
    linkedin: '',
    previously_funded: '',
    investors: '',
    comment: ''
  });
  
  const [files, setFiles] = useState({
    pitch_doc: null,
    logo: null
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleFileChange = (e) => {
    setFiles(prev => ({
      ...prev,
      [e.target.name]: e.target.files[0]
    }));
  };

  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!recaptchaToken) {
      setError('Please complete the reCAPTCHA');
      return;
    }

    // Required field validation
    const requiredFields = [
      'name', 'cin', 'registered_name', 'website', 
      'amount_sought', 'email', 'mobile'
    ];
    
    const missingFields = requiredFields.filter(field => !formData[field]);
    
    if (missingFields.length > 0 || !files.pitch_doc) {
      setError(`Please fill in all required fields: ${missingFields.join(', ')}${!files.pitch_doc ? ', pitch deck' : ''}`);
      return;
    }

    setLoading(true);
    setError('');
    
    try {
      const formDataToSend = new FormData();
      
      // Add all text fields to FormData
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });
      
      // Add files
      if (files.pitch_doc) {
        formDataToSend.append('pitch_doc', files.pitch_doc);
      }
      
      if (files.logo) {
        formDataToSend.append('logo', files.logo);
      }
      
      // Add recaptcha token
      formDataToSend.append('recaptcha', recaptchaToken);

      const response = await fetch('https://api-v2live.vionweb.com/api/funding-requests', {
        method: 'POST',
        body: formDataToSend
      });

      if (!response.ok) {
        const responseText = await response.text();
        console.error('Error response:', responseText);
        throw new Error('Failed to submit form');
      }

      const data = await response.json();
      
      if (data.success) {
        setSuccess(true);
        // Clear form
        setFormData({
          name: '',
          designation: '',
          registered_name: '',
          cin: '',
          country: '',
          city: '',
          industry: '',
          sector: '',
          website: '',
          amount_sought: '',
          revenue: '',
          email: '',
          mobile: '',
          linkedin: '',
          previously_funded: 'no',
          investors: '',
          comment: ''
        });
        setFiles({
          pitch_doc: null,
          logo: null
        });
      } else {
        throw new Error(data.message || 'Submission failed');
      }
      
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const basicFormFields = [
    { name: 'name', type: 'text', label: 'Your Name', required: true },
    { name: 'designation', type: 'text', label: 'Designation', required: false },
    { name: 'registered_name', type: 'text', label: 'Registered Name', required: true },
    { name: 'cin', type: 'text', label: 'CIN', required: true },
    { name: 'country', type: 'text', label: 'Country', required: false },
    { name: 'city', type: 'text', label: 'City', required: false },
    { name: 'industry', type: 'text', label: 'Industry', required: false },
    { name: 'sector', type: 'text', label: 'Sector', required: false },
    { name: 'website', type: 'url', label: 'Website URL', required: true },
    { name: 'email', type: 'email', label: 'Email ID', required: true },
    { name: 'mobile', type: 'text', label: 'Mobile No.', required: true },
    { name: 'linkedin', type: 'url', label: 'Business/Personal LinkedIn URL', required: false },
  ];

  const financialFormFields = [
    { name: 'amount_sought', type: 'number', label: 'Amount Sought (in INR Cr)', required: true },
    { name: 'revenue', type: 'number', label: 'Revenue (in INR Cr)', required: false },
  ];

  return (
    <Layout>
      <div className="min-h-screen bg-gray-50">
        <div className="relative h-48 mb-12">
            <div 
                className="absolute inset-0 bg-cover bg-center"
                style={{ 
                backgroundImage: `url(${impact})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
                }}
            >
                <div className="absolute inset-0 bg-black/20"></div>
            </div>
            
            <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="relative z-10 h-full flex flex-col items-center justify-center text-center px-4"
            >
                <h1 className="text-5xl font-bold text-[#efefef] mb-6 relative group">
                <span className="relative">
                Company Seeking Funding
                <span className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-[#c59c40] to-[#a07924] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                </span>
                </h1>
            </motion.div>
        </div>
        <div className="container mx-auto px-4 pb-16">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="max-w-6xl mx-auto mb-8"
          >
            <h2 className="text-xl font-base text-gray-700 mb-4 text-left">
              Please complete the following details to proceed.
            </h2>
            {/* <p className="text-base text-gray-700 mb-4 leading-relaxed text-center">
            Please complete the following details to proceed.
            </p> */}
            {/* <h3 className="text-sm text-center text-gray-600 mb-6">
              Fields marked with * are required.
            </h3> */}
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="max-w-6xl mx-auto bg-white rounded-xl shadow-lg py-10 px-8 mb-8"
          >
            <form onSubmit={handleSubmit} className="space-y-10" id="directoryform">
              {/* Basic Information */}
              <div>
                <h3 className="text-lg font-medium text-gray-800 mb-6 pb-2 border-b border-gray-200">Company Information</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {basicFormFields.map((field) => (
                    <div key={field.name} className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">
                        {field.label}{field.required && ' *'}
                      </label>
                      <input
                        type={field.type}
                        name={field.name}
                        id={field.name}
                        required={field.required}
                        value={formData[field.name]}
                        onChange={handleChange}
                        className="block w-full h-12 rounded-md border-gray-300 shadow-sm focus:border-[#c59c40] focus:ring-[#c59c40] sm:text-sm px-4 border"
                      />
                    </div>
                  ))}
                </div>
              </div>

              {/* Financial and Contact Information */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {financialFormFields.map((field) => (
                    <div key={field.name} className="space-y-2">
                      <label className="block text-sm font-medium text-gray-700">
                        {field.label}{field.required && ' *'}
                      </label>
                      <input
                        type={field.type}
                        name={field.name}
                        id={field.name}
                        required={field.required}
                        value={formData[field.name]}
                        onChange={handleChange}
                        className="block w-full h-12 rounded-md border-gray-300 shadow-sm focus:border-[#c59c40] focus:ring-[#c59c40] sm:text-sm px-4 border"
                      />
                    </div>
                  ))}
                  {/* Funding Status */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Previously funded? *
                    </label>
                    <div className="flex gap-4 h-12 items-center">
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="previously_funded_no"
                          name="previously_funded"
                          value="no"
                          checked={formData.previously_funded === 'no'}
                          onChange={handleChange}
                          className="focus:ring-[#c59c40] h-5 w-5 text-[#c59c40] border-gray-300"
                        />
                        <label htmlFor="previously_funded_no" className="ml-3 block text-sm font-medium text-gray-700">
                          No
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          type="radio"
                          id="previously_funded_yes"
                          name="previously_funded"
                          value="yes"
                          checked={formData.previously_funded === 'yes'}
                          onChange={handleChange}
                          className="focus:ring-[#c59c40] h-5 w-5 text-[#c59c40] border-gray-300"
                        />
                        <label htmlFor="previously_funded_yes" className="ml-3 block text-sm font-medium text-gray-700">
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>

                 
                </div>
                 {/* Conditional Investors Field */}
                 {formData.previously_funded === 'yes' && (
                  <div className="mt-8">
                    <label className="block text-sm font-medium text-gray-700">
                      Names of Investors (comma separated)
                    </label>
                    <input
                      type="text"
                      name="investors"
                      id="investors"
                      value={formData.investors}
                      onChange={handleChange}
                      className="block w-[65%] h-12 rounded-md border-gray-300 shadow-sm focus:border-[#c59c40] focus:ring-[#c59c40] sm:text-sm px-4 border mt-2"
                    />
                  </div>
                )}

                  {/* Additional Comment */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Comment
                </label>
                <textarea
                  name="comment"
                  id="comment"
                  value={formData.comment}
                  onChange={handleChange}
                  rows={5} 
                  className="block w-[65%] rounded-md border-gray-300 shadow-sm focus:border-[#c59c40] focus:ring-[#c59c40] sm:text-sm px-4 py-3 border"
                />
              </div>

              {/* File Uploads */}
              <div>
                <h3 className="text-lg font-medium text-gray-800 mb-6 pb-2 border-b border-gray-200">Documents</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {/* Pitch Deck Upload */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Pitch Deck *
                    </label>
                    <div className="flex items-center h-12">
                      <input
                        type="file"
                        name="pitch_doc"
                        id="pitch_doc"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept=".pdf,.ppt,.pptx,.doc,.docx"
                        className="sr-only"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => fileInputRef.current?.click()}
                        className="inline-flex items-center px-6 h-12 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#c59c40]"
                      >
                        <Upload className="h-4 w-4 mr-2" />
                        Choose File
                      </button>
                      <span className="ml-3 text-sm text-gray-500 truncate max-w-xs">
                        {files.pitch_doc ? files.pitch_doc.name : 'No file chosen'}
                      </span>
                    </div>
                    <p className="text-xs text-gray-500">
                      Note: The file size must not exceed 10MB.
                    </p>
                  </div>

                  {/* Logo Upload */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Brand Logo Image
                    </label>
                    <div className="flex items-center h-12">
                      <input
                        type="file"
                        name="logo"
                        id="logo"
                        ref={logoInputRef}
                        onChange={handleFileChange}
                        accept="image/*"
                        className="sr-only"
                      />
                      <button
                        type="button"
                        onClick={() => logoInputRef.current?.click()}
                        className="inline-flex items-center px-6 h-12 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#c59c40]"
                      >
                        <Upload className="h-4 w-4 mr-2" />
                        Choose File
                      </button>
                      <span className="ml-3 text-sm text-gray-500 truncate max-w-xs">
                        {files.logo ? files.logo.name : 'No file chosen'}
                      </span>
                    </div>
                    <p className="text-xs text-gray-500">
                      Note: The file size must not exceed 2MB.
                    </p>
                  </div>

                  
                </div>

                
              </div>

              

              {/* reCAPTCHA */}
              <div className="flex justify-center mt-6">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={handleRecaptcha}
                />
              </div>

              {error && (
                <div className="bg-red-50 border border-red-200 rounded-md p-4">
                  <p className="text-red-600 text-sm text-center">{error}</p>
                </div>
              )}

              {/* Submit Button */}
              <div className="pt-4">
                <button
                  type="submit"
                  id="nextbtn"
                  disabled={loading}
                  className="w-full max-w-[11rem] mx-auto flex justify-center items-center py-4 px-6 border border-transparent rounded-md shadow-md text-base font-medium text-white bg-[#bc8b20] hover:bg-[#d6b15f] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#c59c40] transition-colors duration-200"
                >
                  {loading ? (
                    <Loader2 className="w-5 h-5 animate-spin" />
                  ) : (
                    <>
                      Submit <ArrowRight className="ml-2 h-5 w-5" />
                    </>
                  )}
                </button>
              </div>

              {success && (
                <div className="mt-4 p-4 bg-green-50 border border-green-200 rounded-md">
                  <p className="text-green-600 text-center font-medium">
                    Your funding request has been submitted successfully!
                  </p>
                </div>
              )}
            </form>
          </motion.div>

          {/* Success Container (hidden by default) */}
          <div id="contenthide2" className="max-w-6xl mx-auto bg-white rounded-xl shadow-lg py-8 px-6 mb-8 hidden">
            <div className="text-center">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                Thank You for Your Submission!
              </h2>
              <p className="text-gray-700 mb-4">
                Your funding request has been successfully submitted. Our team will review it and get back to you shortly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FundingRequestForm;