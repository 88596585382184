import React, { useState, useEffect } from "react";
import Table from "./Table";
import Summary from "./Summary";
import Layout from "../../components/layout/index";
import './league.css'
import CategorySelector from "./CategorySelector";
import ArchiveContent from "./ArchiveContent"; // Import the archive component
import YearSelector from "../../components/league components/YearSelector"; // Import directly for Archive tab
import headerimg from "../../assets/assets/leage_table.png";
import ArchiveYearSelector from "../../components/league components/ArchiveYearSelector";

const League = () => {
  const [category, setCategory] = useState("t");
  const [subCategory, setSubCategory] = useState("pe");
  const fromDate = "2013-01-01"
  const toDate = '2024-12-31';
  const [isChecked, setIsChecked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSearchForm, setShowSearchForm] = useState(false);
  const [sortedData, setSortedData] = useState(tableData);
  const [activeTab, setActiveTab] = useState("live");
  
  // Separate state for archive tab
  const currentYear = new Date().getFullYear();
  const [archiveYear, setArchiveYear] = useState(currentYear - 1);
  const [archiveYearType, setArchiveYearType] = useState("cy");
  const [archiveLoading, setArchiveLoading] = useState(false);

  const toggleMainTableHandler = (mainTable) => {
    setCategory(mainTable);
  };
  
  const toggleSubTableHandler = (subTable) => {
    setSubCategory(subTable);
  };

  useEffect(() => {
    setSortedData(tableData);
  }, [tableData]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Handler specifically for archive year changes
  const handleArchiveYearChange = (year, type) => {
    setArchiveYear(year);
    setArchiveYearType(type || "cy");
    setArchiveLoading(true);
    // Reset loading after a short delay to show visual feedback
    setTimeout(() => setArchiveLoading(false), 500);
  };

  return (
    <Layout>
      <div 
        className="relative bg-cover bg-center h-[30vh] md:h-[40vh] lg:h-[45vh]"
        style={{
          backgroundImage: ` url(${headerimg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <div className="absolute inset-0 flex items-center justify-center">
          <h2 className="text-3xl md:text-5xl lg:text-6xl pb-14 font-bold text-white tracking-wider">
            LEAGUE TABLES
          </h2>
        </div>
      </div>

      <div className="container mx-auto px-4 -mt-[5rem] relative z-10">
        <div className="bg-white rounded-lg shadow-lg p-6 lg:mx-32 md:mx-2">
          <Summary fromDate={fromDate} toDate={toDate} />
        </div>
      </div>

      <div className="container mx-auto px-4 my-8">
        <div className="lg:mx-32 md:mx-2">
          <div className="flex justify-center border-b border-gray-300">
            <div className="pb-2 relative cursor-pointer px-6 text-center mx-4" onClick={() => handleTabChange("live")}>
              <span className={`text-lg font-medium ${activeTab === "live" ? "text-[#bc8b20]" : "text-gray-600"}`}>Live</span>
              {activeTab === "live" && (
                <div className="absolute bottom-0 left-0 w-full h-1 bg-[#bc8b20]"></div>
              )}
            </div>
            <div className="pb-2 relative cursor-pointer px-6 text-center mx-4" onClick={() => handleTabChange("archive")}>
              <span className={`text-lg font-medium ${activeTab === "archive" ? "text-[#bc8b20]" : "text-gray-600"}`}>Archive</span>
              {activeTab === "archive" && (
                <div className="absolute bottom-0 left-0 w-full h-1 bg-[#bc8b20]"></div>
              )}
            </div>
          </div>
          
          {/* Content based on active tab */}
          <div className="mt-4">
            {activeTab === "live" ? (
              <div className="font-sans text-black">
                <div className="lg:mx-0 md:mx-0">
                  <CategorySelector 
                    category={category}
                    setCategory={setCategory}
                    toggleMainTableHandler={toggleMainTableHandler}
                    toggleSubTableHandler={toggleSubTableHandler}
                    subCategory={subCategory}
                    setSubCategory={setSubCategory}
                    fromDate={fromDate}
                    toDate={toDate}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    setTableData={setTableData}
                    setLoading={setLoading}
                    showSearchForm={showSearchForm}
                    setShowSearchForm={setShowSearchForm}
                    sortedData={sortedData}
                  />
                </div>
                
                <div className="mt-6">
                  <Table 
                    loading={loading} 
                    subCategory={subCategory} 
                    sortedData={sortedData} 
                    setSortedData={setSortedData}  
                    tableData={tableData} 
                    setShowSearchForm={setShowSearchForm} 
                    showSearchForm={showSearchForm} 
                  />
                </div>
              </div>
            ) : (
              <div className="font-sans text-black">
                {/* Simplified archive controls - directly use YearSelector */}
                <div className="bg-white shadow-sm rounded-md p-4 mb-6">
                  <div className="flex justify-center items-center w-full">
                    <ArchiveYearSelector 
                      onYearChange={handleArchiveYearChange}
                    />
                  </div>
                </div>
                
                <div className="mt-6">
                  <ArchiveContent 
                    yearType={archiveYearType} 
                    selectedYear={archiveYear}
                    loading={archiveLoading}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default League;