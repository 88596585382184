import React, { useEffect, useState } from 'react';
import SortIcon from 'mdi-react/SortIcon';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import RandomLogo from '../RandomLogo';
import Loader from '../Loader';
import CompanyLogo from '../CompanyLogo';
import OverflowToolTip from '../shared/OverflowToolTip';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';

const Table = ({ tableData, loading, onSort, sortConfig }) => {
  const columns = [
    { label: 'Company', accessor: 'registered_name', align: 'left' },
    { label: 'Industry', accessor: 'industry', align: 'left' },
    { label: (<div className="text-center">	Funding Sought (₹Cr)</div>), accessor: 'amount_sought', align: 'center' },
    { label: 'Revenue (₹Cr)', accessor: 'revenue', align: 'right' },
    { label: (<div className="text-center">Already Funded</div>), accessor: 'previously_funded', align: 'center' },
    { label: 'Pitchdeck', accessor: 'pitchdeck', align: 'left' },
    { label: 'Added On', accessor: 'created_at', align: 'left' },
  ];

  const handleSort = (accessor) => {
    if (accessor === 'pitchdeck') return;
    onSort(accessor);
  };
  
  const getSortIcon = (accessor) => {
    if (accessor === 'pitchdeck') return null; 
    if (sortConfig.sort_by !== accessor) {
      return <SortIcon className="pl-2 block h-[15px]" />;
    }
    return sortConfig.order === 'asc'
      ? <SortAscendingIcon className="pl-2 block h-[15px]" />
      : <SortDescendingIcon className="pl-2 block h-[15px]" />;
  };
  
  console.log(tableData, "tableData");

  return (
    <div className="md:col-span-4 col-span-5">
      <div className="lg:col-span-4">
        <div className="scrollbar">
          <div className="overflow-x-auto scrollbar rounded rounded-[6px] rounded-t-none shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
            <table className="listPage table w-full p-1 bg-grey-50 divide-y">
              <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]">
                <tr>
                  {columns.map((column, i) => (
                    <th
                      key={i}
                      className={`${
                        i === 0
                          ? "md:pl-5 md:pr-5 pl-5 pr-[10rem] lg:pr-[9rem] text-left lg:relative bg-[#EBEBEB] sticky left-0"
                          : "px-5"
                      } py-2 font-sans_book_body cursor-pointer`}
                      onClick={() => handleSort(column.accessor)}
                    >
                      <span className="flex whitespace-nowrap items-center">
                        <a className="cursor-text hover:text-[#000000]">{column.label}</a>
                        {getSortIcon(column.accessor)}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] text-left font-sans_book_body">
                {loading ? (
                  <tr>
                    <td colSpan={columns.length} className="bg-white">
                      <div className="flex justify-center items-center min-h-[200px]">
                        <Loader />
                      </div>
                    </td>
                  </tr>
                ) : (
                  tableData.length > 0 ? 
                  (

                  tableData.map((row, i) => (
                    <tr key={i} className="group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF] border-b bg-white">
                    <td className="md:w-[28%] px-5 leading-[2rem] text-left sticky left-0 lg:relative text-[#333333] bg-white py-3 font-sans_book_body items-center justify-center">
                        {!row?.logo_url ? (
                          <RandomLogo name={row?.registered_name} />
                        ) : (
                          <CompanyLogo id={row?.fund_req_id} name={row.registered_name} page="company page" logo={row?.logo_url} />
                        )}
                        <div className="whitespace-normal break-words">
                          <OverflowToolTip
                            className="w-[10rem] md:w-[10rem] lg:w-auto lg:max-w-[13rem] 2xl:max-w-[30rem] flex flex-row gap-2"
                            component={
                              <a 
                                className="flex text-start leading-[1.8rem] lg:inline lg:whitespace-normal hover:text-[#BC8B20] font-medium"
                                href={`/csf/company/${row.fund_req_id}`}
                              >
                                {row.registered_name ? row.registered_name : "--"}
                              </a>
                            }
                          />
                        </div>
                      </td>
                      <td className="md:w-[20%] pl-5 py-3 font-sans_book_body">
                        <a className="text-start hover:text-[#000000] cursor-text">{row.industry}</a>
                      </td>
                      <td className="md:w-[14%] px-5 py-3 font-sans_book_body text-center">
                        <a>
                          {row.amount_sought && !isNaN(Number(row.amount_sought.toString().replace(/,/g, ""))) && Number(row.amount_sought.toString().replace(/,/g, "")) > 0 
                            ? (Number(row.amount_sought.toString().replace(/,/g, "")) > 10000000 
                              ? (Number(row.amount_sought.toString().replace(/,/g, "")) / 10000000).toFixed(2)   // Convert large values to Cr
                              : Number(row.amount_sought.toString().replace(/,/g, "")).toFixed(2))  // Ensure two decimal places for small values
                            : "--"}
                        </a>
                      </td>
                      <td className="md:w-[10%] px-5 py-3 font-sans_book_body text-right">
                        <a>{row.revenue && row.revenue >= 0.01 ? row.revenue : "--"}</a>
                      </td>
                      <td className="md:w-[12%] px-5 py-3 font-sans_book_body text-center">
                        <a>{row.previously_funded == "1.00" ? "Yes" : "No" }</a>
                      </td>
                      <td className="md:w-[8%] px-5  py-3 font-sans_book_body">
                        <div className=" text-center cursor-pointer hover:text-[#BC8B20]">
                          <span>{row.pitchdeck}</span>
                        </div>
                      </td>
                      <td className="md:w-[9%] px-5 py-3 font-sans_book_body">
                        <a>{row.created_at}</a>
                      </td>
                    </tr>
                  ))
                  ):(
                    <tr>
                    <td colSpan={columns.length} className="bg-white">
                      <div className="flex justify-center items-center min-h-[200px]">
                      <NoDataPlaceholder showButton={false}/>
                      </div>
                    </td>
                  </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
