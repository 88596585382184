import { React, useState, useMemo, useContext } from 'react'
import { useParams, Link, useLocation, matchPath } from 'react-router-dom';
import moment from 'moment'
import { routeNames } from '../../../routeSegments';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import routePathSegments from "../../../routeSegments";
import OverflowToolTip from '../../shared/OverflowToolTip';
import Paginate from '../../Paginate';
import Constants from '../../../Constants';
import { usePagination, useSortBy, useTable } from 'react-table';
import ConfirmPopup from '../../../Utils/ConfirmPopup';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import LockIcon from 'mdi-react/LockIcon';
import Loader from '../../Loader';
import useModule from '../../../hooks/useModule';
import { UserContext } from '../../../Contexts/UserContext';
import NewLogo from '../../NewLogo';
import { Tooltip } from 'antd';


/**
 * The `InvestorPortfolioCompanies` function is a React component that displays a table of portfolio
 * companies and their investments for a specific investor.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `InvestorPortfolioCompanies` component. These properties can include data related to the investor's
 * portfolio companies, such as the investor's ID, portfolio data, and other relevant information.
 * @returns The InvestorPortfolioCompanies component is returning a JSX element.
 */
const InvestorPortfolioCompanies = (props) => {
    const { investorId } = useParams();
    const [showXlDownload, setshowXlDownload] = useState(false);
    const [alert, setalert] = useState({ message: "", show: false });

    let investorIdPE = parseInt(investorId)
    const location = useLocation();

    const {primaryMasterId} = useModule();
    const {isStudent, isDirectoryUser,isTrialExport} = useContext(UserContext)

    /* The above code is defining a custom sorting function called `customStringSortMemo` using the
    `useMemo` hook in React. This function takes in two rows (`rowA` and `rowB`), a column ID, and a
    boolean value `desc` indicating whether the sorting should be in descending order. */
    const customStringSortMemo = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';
            return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(rowB?.values[columnId]?.name ?? defaultVal);
        }
    }, []);

    /* The above code is defining a custom sorting function called `dealDateSort`. This function is
    used to compare two rows (`rowA` and `rowB`) based on a specific column (`columnId`) that
    contains date values. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])
    const peMatch = matchPath(`${routePathSegments.pe}/*`, location.pathname);

    /* The above code is defining a function called `decimalNumberSort` using the `useMemo` hook in
    JavaScript. This function is used for sorting decimal numbers in a table. It takes four
    parameters: `rowA`, `rowB`, `columnId`, and `desc`. */
    const decimalNumberSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const rowAValue = Number(rowA.values[columnId])
            const rowBValue = Number(rowB.values[columnId])
            if (rowAValue > rowBValue) return 1;
            if (rowBValue > rowAValue) return -1;
            return 0;
        }
    }, [])


  /**
   * The function `addCommasToNumber` takes a number as input and returns a string representation of
   * the number with commas added for thousands separators.
   * @param number - The `number` parameter is the number that you want to add commas to.
   * @returns The function `addCommasToNumber` returns a string with commas added to the number.
   */
    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };

    /* The above code is defining an array of columns for a table. Each column has properties such as
    id, Header (column header text), accessor (data accessor), className (CSS classes for styling),
    Cell (rendering component for the cell), and sortType (custom sort function). The columns are
    created using the useMemo hook, which memoizes the columns array to optimize performance. */
    const columns = useMemo(() => [
        
        {
            id: "companyName",
            Header: "Company Name",
            accessor: "companyName",
            className: "clickable text-left lg:relative sticky left-0 bg-white",
            headerspanClassName: "",
            headerClassName: "text-left lg:relative sticky left-0 bg-[#EDEDED]",
            style: { minWidth: '220px', width: '220px' }, 
            headerStyle: { minWidth: '220px', width: '220px' }, 
            Cell: ({ value }) => {
                return <div className='leading-[2rem] font-sans_book_body items-center justify-center'>
                    <NewLogo
                        name={value.name}
                        id={value?.id}
                        menu="pe"
                    />
                    <OverflowToolTip className="w-full" component={
                        <Link className="text-start lg:inline flex hover:text-[#BC8B20]"
                            state={{ cameFrom: location.state?.prevPath }}
                            to={peMatch != null ? routeNames.peCompanyFundById(value?.id)
                             : routeNames.vcCompanyFundById(value?.id)}>
                            {value.name ?? "--"}
                        </Link>
                    } />
                </div>
            },
            sortType: customStringSortMemo,
        },
        {
            id: "sector",
            Header: "Sector",
            accessor: "sector",
            className: "text-left",
            style: { width: '250px', maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
            headerStyle: { width: '250px', maxWidth: '250px' },
            Cell: ({ value }) => {
                return (
                    <div className='leading-[2rem] font-sans_book_body overflow-hidden text-overflow-ellipsis whitespace-nowrap'>
                        <OverflowToolTip className="w-full" component={value || "--"} />
                    </div>
                );
            },
        },
        
        {
            id: "dealDate",
            Header: "Deal Date",
            accessor: "dealDate",
            className: "w-[15%]",
            Cell: (cell) => {
                return <Link 
                    target='_blank'
                    onClick={(e) => {
                        if(isDirectoryUser){
                            e.preventDefault();
                            e.stopPropagation();
                            onBlurColumnClick();
                        }
                    }}
                    state={{ cameFrom: location.state?.prevPath ?? "" }} to={peMatch != null ? routeNames.peCompanyInvestmentDealById(cell.value?.id, cell.value?.dealId) : routeNames.vcCompanyInvestmentDealById(cell.value?.id, cell.value?.dealId)}>
                    { moment(cell.value.date).format('MMM YYYY')}
                    {cell.value.agghide && (
                        <Tooltip 
                            placement="right" 
                            color="#ffffff"
                            key="#ffffff"
                            overlayStyle={{ maxWidth: '30%'  }}
                            title={<span style={{ color: '#000000' }}>Not Included for Aggregate calculation as it does not meet the Venture Intelligence definition of PE</span>}
                        >
                            <span className="rounded-md bg-[#f3e8ff] px-2 py-[3px] ml-2 text-xs font-sans_book_body text-[#333333]">
                            NIA
                            </span>
                        </Tooltip>
                    )}
                    {cell.value.debt && (
                        <span className="rounded-md bg-[#f3e8ff] px-2  py-[3px] ml-2 text-xs  font-sans_book_body text-[#333333]">
                            Debt
                        </span>
                    )}
                </Link>
            },
            sortType: dealDateSort
        },
        {
            Header: "Inv. Amount (US$ M)",
            accessor: "invAmountInUS",
            className: "text-right pr-[2.7rem] 2xl:pr-[3rem] w-[11%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Inv. Amount (INR Cr)",
            accessor: "invAmountInInr",
            className: "text-right pr-[2.8rem] 2xl:pr-[3rem] w-[10%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Post Money Valuation (INR Cr)",
            accessor: "postMoneyValuationInInr",
            className: "text-right pr-[2.5rem] w-[10%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        },
        {
            Header: "Revenue Multiple",
            accessor: "revenueMultiple",
            className: "text-right pr-[2.5rem] w-[10%]",
            headerspanClassName: "ml-auto",
            Cell: (cell) => {
                return addCommasToNumber(cell?.value)
            },
        }
    ], [customStringSortMemo, location.state?.prevPath]);

   /* The above code is using the `useMemo` hook in React to create a memoized version of the `rowData`
   variable. It is iterating over the `props.portfolio` array and creating a new array of objects
   called `rows`. Each object in the `rows` array contains properties such as `companyName`,
   `dealDate`, `invAmountInUS`, `invAmountInInr`, `postMoneyValuationInInr`, and `revenueMultiple`.
   The values for these properties are extracted from the `company` object in the `props.portfolio`
   array. If any */
    const rowData = useMemo(() => {
        const rows = [];

        props?.portfolio?.forEach(inv => {
            if(inv.deal !== null){
                rows.push({
                    companyName: { name: inv?.deal?.company?.name, dealId: inv?.deal?.id, id: inv?.deal?.company?.id, restricted: inv?.revenue_multiple },
                    sector: inv?.deal?.company?.sector || "--",
                    dealDate: { date: inv?.deal?.date, dealId: inv?.deal?.id, id: inv?.deal?.company?.id, debt: inv?.deal?.is_debt_deal, agghide : inv?.deal?.is_agg_hide },
                    invAmountInUS: inv?.amount_m == 0 || inv?.is_hide_amount ? "" : inv?.amount_m,
                    invAmountInInr: inv?.amount_inr == 0 || inv?.is_hide_amount ? "" : inv?.amount_inr,
                    postMoneyValuationInInr: inv?.deal?.company_valuation_post_money == 0 ? "" : inv?.deal?.company_valuation_post_money,
                    revenueMultiple: inv?.deal?.revenue_multiple_post_money == 0 ? "" : inv?.deal?.revenue_multiple_post_money,
                })
            }
        });
        setshowXlDownload(rows.length != 0)

        return rows;
    }, [props?.portfolio])

    /* The above code is defining a constant variable called `initialSort` using the `useMemo` hook in
    React. The `useMemo` hook is used to memoize the result of a function so that it is only
    recomputed when its dependencies change. */
    const initialSort = useMemo(() => {
        if(props?.fundingData?.isSort === false){
            return []
        }else{
            return [
                {
                    id: "dealDate",
                    desc: true
                }
            ]
        }
    }, [props?.fundingData?.isSort]);

   /**
    * The function `onConfirmation` sets the `show` property of an `alert` object to `false` and clears
    * the `message` property.
    * @param flag - A boolean value indicating whether the confirmation is true or false.
    */
    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets an alert message with a warning and a close button.
     * @param cell - The `cell` parameter is the cell that was clicked on in a column.
     */
    const onBlurColumnClick = (cell) => {
        setalert({ show: true, message: Constants.reachSalesTeamMsg, isAlertOnly: true, closeBtnName: "OK" })
    }

    /* The above code is using the `useTable` function from a library (possibly React-Table) to create
    an instance of a table. */
    const tableInstance = useTable({
        columns,
        data: rowData,
        disableSortRemove: true,
        initialState: {
            pageSize: props?.show == "All" ? rowData?.length :  Constants.noOfRowPerPage,
            pageIndex: 0,
            sortBy: initialSort,
        },
    },
        useSortBy,
        usePagination,
    );

    /* The above code is using destructuring assignment to extract properties and methods from the
    `tableInstance` object. These extracted properties and methods are then used to set up and
    manipulate a table in JavaScript. */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize }
    } = tableInstance;


    return (
        <>
            <div className={`${props?.show === "All" ? "px-0 py-4" : "px-4" } bg-[#EDEDED]`}>
                <div className=" bg-white rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] pb-2 overflow-x-auto">
                    <div className="pt-4 px-5 border-b border-b-[#F1F1F1] ">
                        <div className="flex justify-between mb-2">
                            <p className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  mb-2 lg:leading-[22px]">Portfolio Companies - Investments</p>
                            {!isDirectoryUser ? (isStudent == 'false' && isTrialExport!='false' && showXlDownload) && 
                                <ExcelDownloader
                                    url={exportExcel}
                                    fileName={Constants.exportNames.portfolio_companies_investments(props.investorName)}
                                    data={{
                                        "type":primaryMasterId,
                                        "export_from": "detail",
                                        "module_type":"investors",
                                        "category": "investments",
                                        "section":"company_investments",
                                        "investor_id": [investorIdPE]
                                    }} />
                            : ""}
                        </div>
                    </div>

                    <div className="space-y-6">
                        <div className="bg-white pb-2 rounded-[6px] overflow-x-auto scrollbar">
                            {rows?.length > 0 ? 
                            <table className=" datatable w-full" {...getTableProps()}>

                                <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-bold py-4 px-0 text-center ">
                                    {
                                        headerGroups.map(headerGroup => (

                                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map(column => (
                                                        <th {...column.getHeaderProps([
                                                            {
                                                                className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                                style: column.headerStyle,
                                                            },
                                                            column.getSortByToggleProps(),
                                                        ])} title="">
                                                            <div className="flex">
                                                                <span className='mr-1'>{column.render('Header')}</span>
                                                                {column.disableSortBy ? "" : column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                        : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                    : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                            </div>
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                </thead>

                                <tbody {...getTableBodyProps()} className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                                    {page.length > 0 && !props?.isLoading
                                        ? page.map(row => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()} className="border-b">
                                                    {

                                                        row.cells.map(cell => {
                                                            if (cell.value == Constants.restrictedPlaceholder) {
                                                                return (<td onClick={onBlurColumnClick} className=' relative whitespace-nowrap cursor-pointer' {...cell.getCellProps()}>
                                                                    <div className='blur-sm bg-[#C9C9C9] select-none w-full h-full px-5 py-4'>{Constants.restrictedPlaceholder}</div>
                                                                    <div className='absolute h-full w-full flex items-center justify-center top-0 bottom-0'>
                                                                        <LockIcon size={16} />
                                                                    </div>
                                                                </td>)
                                                            }

                                                            return (
                                                                <td colSpan={cell.column.colspan} rowSpan={cell.column.rowspan} {...cell.getCellProps([
                                                                    {
                                                                        className: `data-td-animate lg:whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                                                        style: cell.column.style,
                                                                    },
                                                                ])}>
                                                                    {
                                                                        cell.value ? cell.render('Cell') : " "
                                                                    }
                                                                </td>


                                                            )
                                                        })}
                                                </tr>

                                            )
                                        })
                                        : <></>}
                                </tbody>
                            </table>
                        : 
                        <div className='w-full h-16 flex items-center justify-center text-[#333333] text-[15px] mt-2'>
                            {props.isLoading ? <Loader /> : "No data found"}
                        </div>
                        }
                        </div>

                    </div>
                    {props?.show == "All" ? <></> :
                    rows?.length <= Constants.noOfRowPerPage || props?.isLoading
                        ? <></>
                        : <Paginate
                            currentPage={pageIndex + 1}
                            totalCount={rows?.length}
                            pageSize={pageSize}
                            onPageChange={(page) => {
                                gotoPage(page - 1);
                            }}
                            from={"investments"}
                            investorId={investorId}
                        />}
                    {<ConfirmPopup {...alert} onChange={onConfirmation} />}
                </div>
            </div>
        </>
    )
}

export default InvestorPortfolioCompanies
