import React from 'react'
import Card from '../../Components/RE/DealDetail/Card'
import RefineSearch from '../../Components/RE/RefineSearchComponent'
import CompanyDealDetail from '../../Components/RE/DealDetail/CompanyDealDetail'
import ReLayout from '../../Components/ReLayout'
import ReInvestmentRefineSearch from '../../Components/RefineSearch/ReInvestmentRefineSearch'
import ReExitRefineSearch from '../../Components/RefineSearch/ReExitRefineSearch'
import ReBackedIpoRefineSearch from '../../Components/RefineSearch/ReBackedIpoRefineSearch'
import ReOtermaRefineSearch from '../../Components/RefineSearch/ReOthermaRefineSearch'
import { useLocation } from 'react-router'
import { routeNames } from '../../../src/routeSegments'

/* The `DealPage` component is a functional component in JavaScript React. It takes in three props:
`menu`, `category`, and `type`. */
const DealPage = ({menu ,category, type}) => {

    const location = useLocation(); 

    return (
        <div>
            <ReLayout>
                <div className="flex h-screen flex-col">                    
                    <Card  category={category} />
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-[#EDEDED]"
                        >
                            <CompanyDealDetail  menu={menu} category={category} type={category} />
                            {/* Your content */}
                        </section>

                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-full flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch > 
                                   {type =="re-investments"?
                                   <ReInvestmentRefineSearch customPathname={routeNames.re_companies_re_investments} menu={"re-investments"}/>
                                   :type=="re-exits"?
                                   <ReExitRefineSearch customPathname={routeNames.re_companies_re_investments} menu={"re-exits"}/>
                                   :type=="re-ipo"?
                                   <ReBackedIpoRefineSearch customPathname={routeNames.re_companies_re_investments} menu={"re-ipo"}/>
                                   :type=="other-manda"?
                                   <ReOtermaRefineSearch customPathname={routeNames.re_companies_re_investments} menu={"other-manda"}/>
                                   :<ReInvestmentRefineSearch customPathname={routeNames.re_companies_re_investments} menu={"re-investments"}/>

                                   } 
                                    </RefineSearch>
                            </div>
                        </aside>
                    </div>
                </div>
            </ReLayout>
        </div>
    )
}

export default DealPage
