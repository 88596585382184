import React, { useState, useEffect } from "react";
import YearSelector from "./YearSelector";
import IndustrySelector from "./IndustrySelector";

const SearchContent = ({
  searchHandler,
  isChecked,
  setIsChecked,
  category,
  onIncludeExcludeChange,
  onYearChange,
  onIndustryChange,
  resetDropdowns,
  SetResetDropdowns,
  handleSearchIconClick,
  isMobile,
  SetSearching
}) => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear - 1);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [includeOtherAdvisory, setIncludeOtherAdvisory] = useState(isChecked);

  useEffect(() => {
    setIncludeOtherAdvisory(isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (resetDropdowns) {
      setSelectedYear(currentYear - 1);
      setSelectedIndustry(null);
      SetResetDropdowns(false);
    }
  }, [resetDropdowns]);

  const handleYearChange = (year, type) => {
    setSelectedYear(year);
    onYearChange && onYearChange(year, type); 
    SetSearching(true);
  };

  const handleIndustryChange = (industry) => {
    setSelectedIndustry(industry);
    onIndustryChange && onIndustryChange(industry);
    // Auto-trigger search when selection changes
    SetSearching(true);
  };

  const handleIncludeChange = (e) => {
    const newValue = e.target.checked;
    setIncludeOtherAdvisory(newValue);
    setIsChecked(newValue);
    onIncludeExcludeChange && onIncludeExcludeChange(newValue ? "Include" : "Exclude");
    // Auto-trigger search when selection changes
    SetSearching(true);
  };


  return (
    <div className="mt-[1.7rem]">
      <YearSelector 
        selectedYear={selectedYear} 
        onYearChange={(year, type) => handleYearChange(year, type)}
      />
      
    </div>
  );
};

export default SearchContent;