import React, { useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Layout from "../../Components/CfsLayout";
import MainMenu from "../../Components/IOC/IocMainmenu";
import Table from "../../Components/IOC/Tablecomponent";
import RefineSearch from "../../Components/CFS/RefineSearchComponent";
import ExampleTable from "../../Components/CFS/ExampleTable";
import { INVESTOR_TABLE_QUERY } from "../../Queries/Pe";
import chatPopupIcon from "../../images/chatPopupIcon.png";
import ChatPopup from "../../Components/ChatPopup";
import TopSection from "../../Components/InvestorLimitedpartnerDetail/TopSection";
// import LimitedPartBreadCrum from './LimitedPartBreadCrum'
import { TableDataContext } from "../../Contexts/TableDataContext";
import CFSRefineSearch from "../../Components/RefineSearch/CFSRefineSearch";
import InvestmentRefineSearch from "../../Components/RefineSearch/InvestmentRefineSearch";
import { routeNames } from '../../routeSegments'
import FilterTag from "../../Components/Tag";
import Constants from "../../Constants";
import IOCRefineSearch from "../../Components/RefineSearch/IOCRefineSearch";
import { AiOutlineArrowLeft } from "react-icons/ai";

/**
 * The `IOC` component is a React component that renders a layout with a top navigation bar, a side
 * menu, and a main section with a table component and a refine search component.
 * @returns The `IOC` component is returning a JSX structure that represents the UI of the
 * component. It includes various nested components such as `Layout`, `TopSection`, `MainMenu`,
 * `Table`, and `RefineSearch`. The returned JSX structure represents a page layout with a top
 * navigation section, a main content section with a table and filter options, and a sidebar section
 * for refining search options.
 */
const IOC = () => {
  
        const title="CFS - Venture Intelligence "
        document.title = title;

  const [showModal, setShowModal] = React.useState(false);
  const location = useLocation();
  const { currentPage, setCurrentPage } = useContext(TableDataContext);


 /* The `data` constant is using the `React.useMemo` hook to memoize an array of objects. This means
 that the array will only be recalculated if the dependencies (in this case, an empty array `[]`)
 change. The array contains objects with properties `sno`, `name`, and `count`. This data can be
 used to populate a table or any other component that requires this data. */


  
  return (
    <div>
      <>
        <Layout>
          <div className="flex h-screen flex-col">
            {/* Top nav*/}
            <TopSection module="cfs"/>
            <div className="flex min-h-0 flex-1 overflow-hidden">
              <main className="min-w-0 flex-1 border-t border-gray-200 flex">
                {/* Primary column */}
                <section className="flex h-full min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]"> 
                <div className="flex flex-row">

                  <div className='mt-[0.9rem] m-4 flex'>
                      <NavLink
                          to={'/cfs'}>
                          <button
                              className="flex  bg-[#BC8B20] px-2 h-[24px] text-[#FFFFFF] text-[14px] rounded-[5px] leading-[17px] items-center">
                              <AiOutlineArrowLeft className='mr-2' />
                              <span className="whitespace-nowrap"> Back</span>
                          </button>
                      </NavLink>

                  </div>
                <FilterTag />                 
                </div>
                  <MainMenu menu="ioc" />
                  <div className="overflow-y-auto">
                   {/* <ExampleTable data={data} /> */}
                   <Table menu="ioc" sub_type_master_id={Constants.subCategoryIds.cfs_id}/>
                  </div>
                </section>
                <aside className="order-first block flex-shrink-0">
                  <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                    <RefineSearch menu="ioc">
                      <IOCRefineSearch
                        customPathname={routeNames.ioc}
                        menu="ioc"
                      ></IOCRefineSearch>
                    </RefineSearch>
                  </div>
                </aside>
              </main>
            </div>
          </div>
        </Layout>
      </>
    </div>
  );
};

export default IOC;
