import React from 'react';
import Layout from '../../components/layout';
import { motion } from "framer-motion";

const Landing2 = () => {
    return(
        <Layout>
            <div className="min-h-screen bg-gray-50">
                {/* First div with header and points */}
                <div className="max-w-6xl mx-auto px-4 pt-12 pb-8">
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-6"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                            <span className="text-[#c59c40]">Venture Intelligence products are your one point source for:</span> 
                        </h2>
                        <ul className="space-y-5 text-black mb-4">
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>Private Equity/Venture Capital and M&A deals - including valuations</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>Financials of Privately Held Companies in India</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>Contacts of PE/VC investors and Advisory Firms</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>PE/VC Funds being raised</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>News on Companies seeking funding and M&A deals</div>
                            </li>
                        </ul>
                    </motion.div>

                    {/* Second div with only points */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-12"
                    >
                        <ul className="space-y-6 text-black">
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>Are you from a PE-VC / Strategic Investment / Advisory Firm? <a 
                                    href="/trial2.htm" 
                                    target="_blank"
                                    className="text-[#c59c40] hover:text-[#a07924] font-semibold"
                                >Request a Demo Here</a></div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>Company Seeking to Raise Capital? <a 
                                    href="/entrepreneurs" 
                                    target="_blank"
                                    className="text-[#c59c40] hover:text-[#a07924] font-semibold"
                                >Click Here</a></div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>Student? Involve someone authorized to make a purchase decision (eg: Librarian / Professor) and then <a 
                                    href="/trial2.htm" 
                                    target="_blank"
                                    className="text-[#c59c40] hover:text-[#a07924] font-semibold"
                                >Request a Demo</a></div>
                            </li>
                        </ul>
                    </motion.div>
                </div>
            </div>
        </Layout>
    )
};

export default Landing2;