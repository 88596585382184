import React from 'react';
import { CheckCircle } from 'lucide-react';

const SubscriptionOptions = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-white p-4 py-8">
      <div className="w-full max-w-2xl">
        <h1 className="text-center text-3xl font-bold mb-6">Subscription Options</h1>
        
        <div className="bg-white p-8 rounded-lg shadow-md border border-gray-200">
          <div className="flex flex-wrap justify-center items-center mb-4 text-base sm:text-lg font-bold text-center">
            <span className="px-2">1 Year Subscription</span>
            <span className="text-gray-300 px-1">|</span>

            <span className="px-2 relative">
              2 Years Subscription
              <sup className="text-red-500 text-[10px] sm:text-xs font-semibold ml-1 px-1.5 py-[1px] border border-red-500 rounded-lg bg-red-100 inline-block leading-tight tracking-wide relative -top-[0.5rem] sm:-top-[0.7rem]">
                10% Off
              </sup>
            </span>

            <span className="text-gray-300 px-1">|</span>
            <span className="px-2">Custom</span>
          </div>
          
          <h2 className="text-center text-lg font-semibold mb-6">What you get</h2>
          
          <ul className="space-y-4">
            <li className="flex items-center">
              <CheckCircle className="text-[#bc8b20] mr-3 flex-shrink-0" size={20} />
              <span>PE-VC Transactions (Investments, Exits & IPO deals)</span>
            </li>
            <li className="flex items-center">
              <CheckCircle className="text-[#bc8b20] mr-3 flex-shrink-0" size={20} />
              <span>Angel Investments and Incubation data</span>
            </li>
            <li className="flex items-center">
              <CheckCircle className="text-[#bc8b20] mr-3 flex-shrink-0" size={20} />
              <span>Pre Money, Post Money & Enterprise Valuation</span>
            </li>
            <li className="flex items-center">
              <CheckCircle className="text-[#bc8b20] mr-3 flex-shrink-0" size={20} />
              <span>Exit Return Multiples</span>
            </li>
            <li className="flex items-center">
              <CheckCircle className="text-[#bc8b20] mr-3 flex-shrink-0" size={20} />
              <span>PE-VC Investor Directory</span>
            </li>
            <li className="flex items-center">
              <CheckCircle className="text-[#bc8b20] mr-3 flex-shrink-0" size={20} />
              <span>India-dedicated fund raising tracker</span>
            </li>
            <li className="flex items-center">
              <CheckCircle className="text-[#bc8b20] mr-3 flex-shrink-0" size={20} />
              <span>Data in excel & pdf format</span>
            </li>
            <li className="flex items-center">
              <CheckCircle className="text-[#bc8b20] mr-3 flex-shrink-0" size={20} />
              <span>Track companies by creating Alerts, Notes and Save to the list</span>
            </li>
            <li className="flex items-center">
              <CheckCircle className="text-[#bc8b20] mr-3 flex-shrink-0" size={20} />
              <span>Industry best TAT on update requests</span>
            </li>
          </ul>
          
          <div className="border-t border-gray-200 my-6 w-1/2 mx-auto"></div>
          
          <p className="text-center mt-6 text-sm">
            Ideal for PE/VC Firms, Investment Banks, Corporate Law firms and will be effective 
            package when combined with other databases.
          </p>
          
          <div className="text-center mt-6">
            <a 
              href="/trial2.htm" 
              target="_blank" 
              rel="noopener noreferrer"
              className="bg-yellow-600 text-white px-6 py-2 rounded hover:bg-yellow-700 transition-colors inline-block"
            >
              Request a Demo
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionOptions;