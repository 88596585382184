import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';
import useScrollIntoView from './useScrollIntoView';

/**
 * The `CountryFilter` function is a React component that renders a filter for selecting countries,
 * with options to select all countries or individual countries.
 * @returns a JSX element.
 */
function CountryFilter({ open, accordionClick, CountryOptions, setCountryOptions, customPathname, onChange, filterPathkey = "countries", label = "Country" }) {

    const { countries, refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.[filterPathkey]?.map(value => value.id) ?? [];
        setCountryOptions(countries?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [countries, customPathname, filterPathkey, refineSearchFilters, setCountryOptions])

    const handleCountryChange = (e) => {
        const { name, checked, id, filteredIds } = e.target;
    
        if (name === "allSelect") {
            let currentCountries = CountryOptions.map((country) =>
                ({ ...country, isChecked: checked })
            );
            setCountryOptions(currentCountries);
            onChange(currentCountries);
        } else if (name === "filteredSelect" && filteredIds) {
            let currentCountries = CountryOptions.map((country) => {
                if (filteredIds.includes(country.id)) {
                    return { ...country, isChecked: checked };
                }
                return country;
            });
            setCountryOptions(currentCountries);
            onChange(currentCountries);
        } else {
            let currentCountries = CountryOptions.map((country) => {
                const isNumeric = /^[0-9]+$/.test(id);
                const parsedId = isNumeric ? parseInt(id) : id;
                return country.id == parsedId
                    ? { ...country, isChecked: checked }
                    : country;
            });
    
            setCountryOptions(currentCountries);
            onChange(currentCountries);
        }
    };

    const len = CountryOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
                ref={listItemRef}
            > <FilterWrapper
                    isSelect={false}
                    label={label}
                    count={len}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleCountryChange}
                        primaryEntity={CountryOptions}
                    /> : <></>}

                </div>

            </li>

        </div>
    )
}

export default CountryFilter