import React, { useEffect, useMemo, useContext, useState } from 'react'
import './dashboarcd.css'
import axios from 'axios';
import { groupListApi, editGroupListApi, deleteGroupListApi } from '../../Config/config';
import { primaryMasterValues } from '../../Constants/values';
import { useFilters, usePagination, useTable } from 'react-table';
import DashboardPagination from '../shared/DashboardPagination';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import { UserContext } from '../../Contexts/UserContext';
import moment from "moment";
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Tooltip, Modal, Input, message } from 'antd';
import Constants from '../../Constants';
import { routeNames } from '../../routeSegments';

export const getPath = (value) => {
    let moduleId = value.primary_type_master_id;
    let type = value.type_name;
    if(moduleId == "1"){
        if(type == "Company"){
            let path = routeNames.pe_companies_pe_vc_investments 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investments_id) {
                    path = routeNames.pe_companies_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_exits_id) {
                    path = routeNames.pe_companies_exists_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_exitsma_id) {
                    path = routeNames.pe_companies_exists_ma_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_exitspm_id) {
                    path = routeNames.pe_companies_exists_pm_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_backed_ipos_id) {
                    path = routeNames.pe_companies_backed_ipos_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_angel_invesments_id) {
                    path = routeNames.pe_companies_angel_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_incubation_id) {
                    path = routeNames.pe_companies_incubation;
                    break;
                } else {
                    path = routeNames.pe_companies_pe_vc_investments;
                }
            }
            return path;
        }else if(type == "Investor"){
            let path = routeNames.pe_investors_pe_vc_investments;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_investments_id) {
                    path = routeNames.pe_investors_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_exits_id) {
                    path = routeNames.pe_investors_exists_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_exitsma_id) {
                    path = routeNames.pe_investors_exists_ma_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_exitspm_id) {
                    path = routeNames.pe_investors_exists_pm_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_backed_ipos_id) {
                    path = routeNames.pe_investors_backed_ipos_pe_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_investor_angel_invesments_id) {
                    path = routeNames.pe_investors_angel_investments;
                    break;
                } else {
                    path = routeNames.pe_investors_pe_vc_investments;
                }
            }
            return path;                
        }else if(type == "Legal Advisor"){
            let path = routeNames.pe_advisors_pe_vc_advisors;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.pe_ladvisor_investments_id) {
                    path = routeNames.pe_advisors_pe_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_ladvisor_exits_id) {
                    path = routeNames.pe_advisors_ma_advisors_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_ladvisor_exitsma_id) {
                    path = routeNames.pe_advisors_ma_pe_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_ladvisor_exitspm_id) {
                    path = routeNames.pe_advisors_pm_pe_vc_advisors;
                    break;
                } else {
                    path = routeNames.pe_advisors_pe_vc_advisors;
                }
            }
            return path;  
        }else if(type == "Transaction Advisor"){
            let path = routeNames.pe_transaction_advisors_pe_vc_advisors;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.pe_tadvisor_investments_id) {
                    path = routeNames.pe_transaction_advisors_pe_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_tadvisor_exits_id) {
                    path = routeNames.pe_transaction_advisors_ma_advisors_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_tadvisor_exitsma_id) {
                    path = routeNames.pe_transaction_advisors_ma_pe_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_tadvisor_exitspm_id) {
                    path = routeNames.pe_transaction_advisors_pm_pe_vc_advisors;
                    break;
                } else {
                    path = routeNames.pe_transaction_advisors_pe_vc_advisors;
                }
            }
            return path;  

        }else if(type == "Limited Partner"){
            let path = routeNames.pe_investors_pe_vc_limited_partners;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.pe_lp_investments_id) {
                    path = routeNames.pe_investors_pe_vc_limited_partners;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_lp_exits_id) {
                    path = routeNames.pe_investors_exists_pe_vc_limited_partners;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_lp_exitsma_id) {
                    path = routeNames.pe_investors_exists_ma_pe_vc_limited_partners;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.pe_lp_exitspm_id) {
                    path = routeNames.pe_investors_exists_pm_pe_vc_limited_partners;
                    break;
                } else {
                    path = routeNames.pe_investors_pe_vc_limited_partners;
                }
            }
            return path; 
        }else if(type == "Fund"){
            return routeNames.pe_investors_pe_vc_funds 
        }else if(type == "Incubator"){
            return routeNames.pe_investors_incubation;
        }            
    }
    else if(moduleId == "2"){
        if(type == "Company"){                
            let path = routeNames.vc_companies_vc_investments; 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investments_id) {
                    path = routeNames.vc_companies_vc_investments;;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_exits_id) {
                    path = routeNames.vc_companies_exists_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_exitsma_id) {
                    path = routeNames.vc_companies_exists_ma_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_exitspm_id) {
                    path = routeNames.vc_companies_exists_pm_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_backed_ipos_id) {
                    path = routeNames.vc_companies_backed_ipos_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_angel_invesments_id) {
                    path = routeNames.vc_companies_angel_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_incubation_id) {
                    path = routeNames.vc_companies_incubation;
                    break;
                } else {
                    path = routeNames.vc_companies_vc_investments;;
                }
            }
            return path;
        }else if(type == "Investor"){                
            let path = routeNames.vc_investors_vc_investments;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_investments_id) {
                    path = routeNames.vc_investors_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_exits_id) {
                    path = routeNames.vc_investors_exists_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_exitsma_id) {
                    path = routeNames.vc_investors_exists_ma_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_exitspm_id) {
                    path = routeNames.vc_investors_exists_pm_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_backed_ipos_id) {
                    path = routeNames.vc_investors_backed_ipos_vc_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_investor_angel_invesments_id) {
                    path = routeNames.vc_investors_angel_investments;
                    break;
                } else {
                    path = routeNames.vc_investors_vc_investments;
                }
            }
            return path;  
        }else if(type == "Legal Advisor"){                
            let path = routeNames.vc_advisors_vc_advisors;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.vc_ladvisor_investments_id) {
                    path = routeNames.vc_advisors_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_ladvisor_exits_id) {
                    path = routeNames.vc_advisors_ma_vc_advisors_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_ladvisor_exitsma_id) {
                    path = routeNames.vc_advisors_ma_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_ladvisor_exitspm_id) {
                    path = routeNames.vc_advisors_pm_vc_advisors;
                    break;
                } else {
                    path = routeNames.vc_advisors_vc_advisors;
                }
            }
            return path; 
        }else if(type == "Transaction Advisor"){                  
            let path = routeNames.vc_transaction_advisors_vc_advisors;
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.vc_tadvisor_investments_id) {
                    path = routeNames.vc_transaction_advisors_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_tadvisor_exits_id) {
                    path = routeNames.vc_transaction_advisors_ma_vc_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_tadvisor_exitsma_id) {
                    path = routeNames.vc_transaction_advisors_ma_vc_advisors;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.vc_tadvisor_exitspm_id) {
                    path = routeNames.vc_transaction_advisors_pm_vc_advisors;
                    break;
                } else {
                    path = routeNames.vc_transaction_advisors_vc_advisors;
                }
            }
            return path;  
      
        }else if(type == "Incubator"){
            return routeNames.vc_investors_incubation;
        }
    }else if(moduleId == "3"){
        if(type == "Company"){                 
            let path = routeNames.re_companies_re_investments; 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.re_investments_id) {
                    path = routeNames.re_companies_re_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_exitsma_id) {
                    path = routeNames.re_companies_re_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_others_ma_id) {
                    path = routeNames.re_companies_other_ma;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_backed_ipos_id) {
                    path = routeNames.re_companies_re_ipo;
                    break;
                } else {
                    path = routeNames.re_companies_re_investments;
                }
            }
            return path;
        }else if(type == "Investor"){                
            let path = routeNames.re_investors_re_investments; 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.re_investor_investment_id) {
                    path = routeNames.re_investors_re_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_investor_exits_id) {
                    path = routeNames.re_investors_exists;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_investor_ipo_id) {
                    path = routeNames.re_investors_backed_ipos;
                    break;
                } else {
                    path = routeNames.re_investors_re_investments;
                }
            }
            return path;
        }else if(type == "Legal Advisor"){                
            let path = routeNames.re_advisors_re_investments; 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.re_advisor_investment_id) {
                    path = routeNames.re_advisors_re_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_advisor_exits_id) {
                    path = routeNames.re_advisors_re_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_advisor_otherma_id) {
                    path = routeNames.re_advisors_other_ma;
                    break;
                } else {
                    path = routeNames.re_advisors_re_investments;
                }
            }
            return path;
        }else if(type == "Transaction Advisor"){                
            let path = routeNames.re_transaction_advisors_re_investments; 
            for (let i = 0; i < value.details.length; i++) {
                const val = value.details[i];
                if (val?.sub_type_master_id == Constants.subCategoryIds.re_tadvisor_investment_id) {
                    path = routeNames.re_transaction_advisors_re_investments;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_tadvisor_exits_id) {
                    path = routeNames.re_transaction_advisors_re_exits;
                    break;
                } else if (val?.sub_type_master_id == Constants.subCategoryIds.re_tadvisor_otherma_id) {
                    path = routeNames.re_transaction_advisors_other_ma;
                    break;
                } else {
                    path = routeNames.re_transaction_advisors_re_investments;
                }
            }
            return path;
        }else if(type == "Fund"){
            return routeNames.re_investors_funds ;
        }
    }else if(moduleId == "4"){
        if(type == "Company"){
            return routeNames.ma_companies;
        }else if(type == "Legal Advisor"){
            return routeNames.ma_advisors;
        }else if(type == "Transaction Advisor"){
            return routeNames.ma_transaction_advisor;
        }else if(type == "Acquirer"){
            return routeNames.ma_acquires;
        }
    }else if(moduleId == "5"){
        if(type == "Company"){
            return routeNames.cfs;
        }
    }
}

function DashboardTableTwo({ savedWishList, isLoading, fetchWishList }) {    
    const { getToken } = useContext(UserContext);
    const navigate = useNavigate();    
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editingGroup, setEditingGroup] = useState(null);
    const [newGroupName, setNewGroupName] = useState('');
    const [localWishList, setLocalWishList] = useState([]);

    useEffect(() => {
        onModuleChange();
        // Initialize local state with props
        setLocalWishList(savedWishList);
    }, [savedWishList]);

    const handleEdit = (group) => {
        setEditingGroup(group);
        setNewGroupName(group.groupName);
        setIsEditModalVisible(true);
    };

    const handleDelete = async (groupId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this list?',
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                try {
                    const response = await axios.post(deleteGroupListApi, {
                        group_id: groupId
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": getToken()
                        }
                    });

                    if (response?.data?.success) {
                        message.success('List deleted successfully');
                        
                        // Update local state directly
                        setLocalWishList(prevList => 
                            prevList.filter(item => item.id !== groupId)
                        );

                        // Also call parent's fetch function for syncing
                        fetchWishList();
                    } else {
                        // message.error('Failed to delete list');
                    }
                } catch (error) {
                    console.error('Delete API error:', error);
                    // message.error('Failed to delete list');
                }
            }
        });
    };

    const handleEditSubmit = async () => {
        try {
            const response = await axios.post(editGroupListApi, {
                group_id: editingGroup.actions.id,
                group_name: newGroupName
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getToken()
                }
            });

            if (response?.data?.success) {
                message.success('List name updated successfully');
                setIsEditModalVisible(false);
                
                // Update local state directly
                setLocalWishList(prevList => 
                    prevList.map(item => {
                        if (item.id === editingGroup.actions.id) {
                            return { ...item, group_name: newGroupName };
                        }
                        return item;
                    })
                );

                // Also call parent's fetch function for syncing
                fetchWishList(); 
            } else {
                // message.error('Failed to update list name');
            }
        } catch (error) {
            console.error('Edit API error:', error);
            // message.error('Failed to update list name');
        }
    };

    const onViewClick = (value) => {
        navigate(getPath(value),{state:{myList:{myList:[{id:0,group_id:value?.id,name:value?.group_name,type:value?.type_name}]},monthRange:['1998-01-01', moment().endOf("month").format("YYYY-MM-DD")]}})
    }

    const columns = useMemo(() => [
        {
            Header: "List Name",
            accessor: "groupName",
            className: "text-left pl-8",
            Cell: ({ value, row }) => {
                return (
                    <span 
                        className="cursor-pointer hover:text-[#bc8b20] font-semibold"
                        onClick={() => onViewClick(row.original.actions)}
                    >
                        {value}
                    </span>
                );
            }
        },
        {
            Header: "Module",
            accessor: "entityName",
            className: "text-center",
            Cell: ({ value }) => {
                return primaryMasterValues.find(data => data.value == value)?.title
            },
        },        
        {
            Header: "Type",
            accessor: "type",
            className: "text-center",
            Cell: ({ value }) => {
                return value || "N/A"
            }
        },
        {
            Header: "Created Date",
            accessor: "createdAt",
            className: "text-center"
        },
        {
            Header: "Actions",
            accessor: "actions",
            className: "text-center",
            Cell: ({ row }) => {
                return (
                    <div className="flex justify-center gap-4">
                        <Tooltip title="Edit">
                            <FiEdit2 
                                className="cursor-pointer text-[#bc8b20] hover:text-[#efce88]" 
                                onClick={() => handleEdit(row.original)}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <FiTrash2 
                                className="cursor-pointer text-[#bc8b20] hover:text-[#efce88]"
                                onClick={() => handleDelete(row.original.actions.id)}
                            />
                        </Tooltip>
                    </div>
                );
            }
        },
    ], []);

    const data = useMemo(() => {
        // Use localWishList instead of savedWishList
        return localWishList?.map(savedWish => ({
            entityName: savedWish.primary_type_master_id,
            groupName: savedWish.group_name,
            type: savedWish.type_name,
            createdAt: moment(savedWish.created_at).format('DD MMM YYYY') ?? moment(new Date()).format('DD MMM YYYY'),
            actions: savedWish
        })) || [];
    }, [localWishList]); // Change dependency to localWishList

    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageSize: 10 }
    },
        useFilters,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        gotoPage,
        state: { pageIndex, pageSize },
    } = tableInstance;

    const onPaginationChange = (page, pageSize) => {
        gotoPage(page - 1);
    };

    const onModuleChange = (e) => {        
        axios(`${groupListApi}`, {
            method: "POST",
            data: {
                primary_type_master_id: e?.target?.value ?? 'All',
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
        .then((response) => {
            if (response?.data?.success == true) {
                console.log(response?.data, "list group api response");
            }
        })
        .catch((error) => {
            console.log("API not working", error);
        });        
    };

    // Use localWishList for rendering check
    const wishListToCheck = localWishList?.length > 0 ? localWishList : savedWishList;
    const isLoadingToCheck = isLoading;

    return (  
        <>                  
            <div className="overflow-x-auto lg:thin-scrollbar scrollbar-remove">
                <table className="w-full h-full p-1 border-separate text-center border-spacing-y-1" {...getTableProps()}>
                    <thead className="bg-[#c3c3c3] font-sans_medium_body text-[14px] leading-[16px] text-[#273746] h-[2rem]">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps([
                                        { className: `whitespace-nowrap px-5 ${column.className}` }
                                    ])}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {!isLoadingToCheck && wishListToCheck.length > 0 && page.length > 0 ? (
                        <tbody className="text-[11pt] leading-[12pt] lg:text-[14px] lg:leading-[16px] text-[#333333] font-sans_book_body" {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr className="px-2 even:bg-[#E8E8E8] even:h-[2.3rem] odd:bg-white odd:h-8" {...row.getRowProps()} style={{height:'2.3rem'}}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps([
                                                { className: `whitespace-nowrap px-5 py-2 ${cell.column.className}` }
                                            ])}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody className='h-[27.5rem]'>
                            <tr>
                                <td colSpan={headerGroups[0].headers.length} className=''>
                                    {isLoadingToCheck ? (
                                        <div className='bg-white w-full flex items-center justify-center h-full'>
                                            <Loader />
                                        </div>
                                    ) : wishListToCheck.length < 1 || page.length < 1 ? (
                                        <div className='bg-white w-full flex items-center justify-center h-full'>
                                            <NoDataPlaceholder showButton={false} />
                                        </div>
                                    ) : null}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table>            
            </div>   

            {!isLoadingToCheck && (
                <div className='text-center mt-2 mb-2'>
                    <DashboardPagination
                        current={pageIndex + 1}
                        total={rows.length}
                        pageSize={pageSize}
                        onChange={onPaginationChange}
                    />
                </div>   
            )}

            <Modal
                title="Edit List Name"
                open={isEditModalVisible}
                onOk={handleEditSubmit}
                onCancel={() => setIsEditModalVisible(false)}
                okButtonProps={{ 
                    style: { 
                        backgroundColor: '#bc8b20', 
                        borderColor: '#bc8b20',
                    } 
                }}
                okText="Save"
                cancelButtonProps={{
                    style: {
                        marginRight: '8px', 
                    }
                }}
            >
                <Input
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                    placeholder="Enter new list name"
                />
            </Modal>
        </>
    );
}

export default DashboardTableTwo;