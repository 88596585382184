
import { useContext, useState, useEffect } from "react";
import { routeNames } from "../../routeSegments";
import { NavLink } from "react-router-dom";
import NewLogo from "../NewLogo";
import { Plus, Minus } from "lucide-react";
import { Tooltip } from "antd";
import { HeaderMenuContext } from "../../Contexts/HeaderMenuContext";
import { UserContext } from "../../Contexts/UserContext";
import Constants from "../../Constants";
import ConfirmPopup from "../../Utils/ConfirmPopup";
import useModule from "../../hooks/useModule";

export const SearchResult = ({ result, SearchResult, key, check }) => {
  const { request_params, found, grouped_hits } = result;
  const [hits, setHits] = useState([]);
  const collectionName = request_params?.collection_name;
  const { setSearchText } = useContext(HeaderMenuContext);
  const { getAllowedModules } = useContext(UserContext);
  const allowedModules = getAllowedModules();
  const [alert, setalert] = useState({ message: "", show: false });
  const [expandedCompanies, setExpandedCompanies] = useState(new Set());
  const { isVC, isMA } = useModule();
  
  useEffect(() => {
    if (result.hits) {
      setHits(result.hits);
    } else if (grouped_hits && grouped_hits.length > 0) {
      const extractedHits = extractHitsFromGroupedData(grouped_hits);
      setHits(extractedHits);
    } else {
      setHits([]);
    }
  }, [result, check, grouped_hits]);
  
  function extractHitsFromGroupedData(grouped_hits) {
    // Initialize an empty array to store all hits
    let allHits = [];
  
    // Map through each group in groupedHits array
    grouped_hits.forEach(group => {
      // For each group, add all hits to the allHits array
      allHits = allHits.concat(group.hits);
    });
  
    return allHits;
  }
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const asHumanDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
  }

  // Get company name from a hit
  const getCompanyName = (hit) => {
    return hit.document.company__name || 
           hit.document.name || 
           hit.document.full_name || 
           hit.document.fund__name ||
           hit.document.investor__name;
  };

  // Filter hits to keep only unique companies (first occurrence)
  const getUniqueHits = (hits) => {
    const seen = new Set();
    return hits.filter(hit => {
      const companyName = getCompanyName(hit);
      if (seen.has(companyName)) {
        return false;
      }
      seen.add(companyName);
      return true;
    });
  };

  // Get unique hits
  const uniqueHits = getUniqueHits(hits || []);

  const pevcSubscriber = allowedModules.includes("pe") || allowedModules.includes("vc")
  const cfsSubscriber = allowedModules.includes("cfs");
  const reSubscriber = allowedModules.includes("re");
  const maSubscriber = allowedModules.includes("ma");

  const generalUrlMappings = [
    { accessor: 'pe_company', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) :  routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'pe_investment', link: (cmpId, dealId) => (isVC) ? routeNames.vcCompanyInvestmentDealById(cmpId, dealId) : routeNames.peCompanyInvestmentDealById(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'pe_exit', link: (cmpId, dealId) => (isVC) ? routeNames.vcCompanyExitsDealById(cmpId, dealId) : routeNames.peCompanyExitsDealById(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'pe_ipo', link: (cmpId, dealId) => (isVC) ? routeNames.vcCompanyBackedIPOSDealById(cmpId, dealId) : routeNames.peCompanyBackedIPOSDealById(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'angel', link: (cmpId, dealId) => (isVC) ? routeNames.vcCompanyAngelDealById(cmpId, dealId) : routeNames.peCompanyAngelDealById(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'incubation', link: (cmpId, dealId) => (isVC) ? routeNames.vcCompanyIncubationDealById(cmpId, dealId) : routeNames.peCompanyIncubationDealById(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'pe_investor', link: (cmpId) => (isVC) ? routeNames.vcInvestorById(cmpId) : routeNames.peInvestorById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'angel_investor', link: (cmpId) => (isVC) ? routeNames.vcAngelInvestorById(cmpId) : routeNames.peAngelInvestorById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'incubator', link: (cmpId) => (isVC) ? routeNames.vcIncubationInvestorById(cmpId) : routeNames.peIncubationInvestorById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'pe_fund', link: (cmpId, dealId) => routeNames.pe_investors_funds_detail_page(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'pe_advisor', 
      link: (cmpId, dealId, advisor_type) => {        
        if (advisor_type === 'L') {
          // Legal Advisor
          return (isMA) ? 
            routeNames.maAdvisorsById(cmpId) : 
            (isVC) ? 
              routeNames.vcAdvisorById(cmpId) : 
              routeNames.peAdvisorById(cmpId);
        } else if (advisor_type === 'T') {
          // Transaction Advisor
          return (isMA) ? 
            routeNames.maTransactionAdvisorsById(cmpId) : 
            (isVC) ? 
              routeNames.vcTransactionAdvisorById(cmpId) : 
              routeNames.peTransactionAdvisorById(cmpId);
        } else {
          // Default case if no specific type is found
          return (isMA) ? 
            routeNames.maAdvisorsById(cmpId) : 
            (isVC) ? 
              routeNames.vcAdvisorById(cmpId) : 
              routeNames.peAdvisorById(cmpId);
        }
      }, 
      isAllowed: pevcSubscriber 
    },
    { accessor: 'pe_acquirer', link: (cmpId) => routeNames.maAcquiresById(cmpId), isAllowed: maSubscriber },
    { accessor: 'merger_acquisition', link: (cmpId, dealId) => routeNames.maCompanyDealById(cmpId, dealId), isAllowed: maSubscriber },
    { accessor: 're_company', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber },
    { accessor: 're_investment', link: (cmpId, dealId) => routeNames.reCompanyInvestmentDealById(cmpId, dealId), isAllowed: reSubscriber },
    { accessor: 're_exit', link: (cmpId, dealId) => routeNames.reCompanyExitsDealById(cmpId, dealId), isAllowed: reSubscriber },
    { accessor: 're_ipo', link: (cmpId, dealId) => routeNames.reCompanyBackedIPOSDealById(cmpId, dealId), isAllowed: reSubscriber },
    { accessor: 're_investor', link: (cmpId) => routeNames.reInvestorById(cmpId), isAllowed: reSubscriber },
    { accessor: 're_advisor', 
      link: (cmpId, dealId, advisor_type) => {
        if(advisor_type === 'L'){
          return routeNames.reAdvisorById(cmpId)
        }else{
          return routeNames.reTransactionAdvisorById(cmpId)
        }
      }, 
      isAllowed: reSubscriber 
    },
    { accessor: 'cfs_company', link: (cmpId) => routeNames.cfsCompanyPageById(cmpId), isAllowed: cfsSubscriber },
    { accessor: 'limited_partner', link: (cmpId) => (isVC) ? routeNames.vcInvestorsLimitedPartnerById(cmpId) : routeNames.investorsLimitedPartnerById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 're_fund', link: (invId, fundId) => routeNames.re_investors_funds_detail(invId, fundId), isAllowed: reSubscriber},
    { accessor: 're_merger_acquisition', link: (cmpId, dealId) => routeNames.reCompanyOtherMaDealById(cmpId, dealId), isAllowed: reSubscriber}
  ];

  const companyUrlMappings = [
    { accessor: 'pe_investment', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) : routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'pe_exit', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) : routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'pe_ipo', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) : routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'angel', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) : routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'incubation', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) : routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
    { accessor: 'pe_fund', link: (cmpId, dealId) => routeNames.pe_investors_funds_detail_page(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 're_fund', link: (cmpId, dealId) => routeNames.re_investors_funds_detail(cmpId, dealId), isAllowed: pevcSubscriber },
    { accessor: 'merger_acquisition', link: (cmpId) => routeNames.maCompanyById(cmpId), isAllowed: maSubscriber },
    { accessor: 're_investment', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber },
    { accessor: 're_exit', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber },
    { accessor: 're_ipo', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber },
    { accessor: 're_merger_acquisition', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber}
  ];


  const generateUrl = (collectionName, cmpId, dealId, advisor_type) => {
    const urlMapping = generalUrlMappings.find(mapping => mapping.accessor === collectionName);
    return urlMapping ? urlMapping.link(cmpId, dealId, advisor_type) : '/';
  };

  const generateCompanyUrl = (collectionName, cmpId, deal_id) =>{
    const urlMapping = companyUrlMappings.find(mapping => mapping.accessor === collectionName);
    return urlMapping ? urlMapping.link(cmpId, deal_id) : '/';
  };

  const fieldNameMapHuman = {
    company__name: "Co name",
    investor__name: "Investor",
    acquirer__name: "Acquirer",
    additional_info: "Addl info",
    advisors_for_buyer__name: "Advisor",
    advisors_for_company__name: "Advisor",
    advisors_for_exit_company__name: "Advisor",
    advisors_for_investor__name: "Advisor",
    advisors_for_ma_acquirer__name: "Advisor",
    advisors_for_ma_company__name: "Advisor",
    advisors_for_seller__name: "Advisor",
    auditor_name: "Auditor",
    board_info__name: "Co Board",
    business_description: "Description",
    c_tag: "Competitor",
    cin: "CIN",
    company_group: "Group Co",
    contact_name: "Contact",
    contact_person: "Contact",
    description: "Description",
    email: "Email",
    exit_investors__name: "Investor",
    full_name: "Co name",
    fund__name: "Fund",
    incubator__name: "Incubator",
    investment_details: "Investment",
    investment_info: "Investment",
    investor__name: "Investor",
    investors__name: "Investor",
    ipo_investors__name: "Investor",
    key_contact: "Contact",
    limited_partners: "LP",
    management_info__name: "Management",
    manager: "Manager",
    more_info: "Deal details",
    name: "Co name",
    old_cin: "CIN",
    previous_name: "Previous name",
    project_name: "Project",
    sector: "Sector",
    selling_investors: "Investor",
    short_name: "Short name",
    subsector: "Sector",
    tags: "Tag",
    website: "Website",
    company__cin: "CIN",
    company__tags: "Tags",
    company__c_tag: "Competitor tag",
    company__sector: "Sector",
    company__website: "Website",
    company__email: "Email",
    company__additional_info: "Addl info",
    company__board_info__name: "Co Board",
    company__management_info__name: "Management",
    company__cin__business_description: "Business description",
    cin__business_description: "Business description"
  }

  const getDescription = (doc) => {
    return doc.business_description !== "None" &&
      doc.description !== "None" &&
      doc.cin__business_description !== "None" &&
      doc.company__cin__business_description !== "None";
  };

  const getFirstNonEmptyField = (doc) => {
    return doc.business_description ||
      doc.description ||
      doc.cin__business_description ||
      doc.company__cin__business_description;
  };

  const onConfirmation = () => {
    setalert({ show: false, message: "" })
  }

  const onBlurColumnClick = () => {
    setalert({ show: true, message: Constants.subscribeProductMsg, isAlertOnly: true, closeBtnName: "OK" })
  }


  const skipDescriptionIfPossible = (highlight, index) => {
    return (
      <span className="whitespace-nowrap overflow-hidden overflow-ellipsis mr-3">
        <Tooltip 
          placement="top"
          color="#ffffff"
          key="#ffffff"
          overlayStyle={{ maxWidth: '30%' }}
          title={
            <span
              key={index}
              dangerouslySetInnerHTML={{ __html: highlight.snippet }}
              className="mark-custom text-black font-sans_book_body flex-grow text-[13px] lg:text-[14px] break-words"
            />
        }>
          <span className="bg-gradient-to-br from-amber-100 to-rose-100 px-1 py-1 flex rounded text-black text-xs hover:cursor-help">
            {fieldNameMapHuman[highlight.field] || highlight.field}
          </span>
        </Tooltip>

      </span>
    )
  }

  const checkMarkUptext = (snippet, coName) => {
    return snippet?.toLowerCase()?.replace(/<\/?mark>/g, '') == coName?.toLowerCase()
  }

  const highlightsSectionHandler = (hit) => {
    const skippableNames = ["company__name", "full_name", "name", "fund__name", "c_tag", "company__c_tag", "short_name"];
    const coName = getCompanyName(hit);
    const highlights = hit.highlights.filter((h) => (!(skippableNames.includes(h.field) && checkMarkUptext(h.snippet, coName))));
    return highlights.map((h, index) => skipDescriptionIfPossible(h, index))?.splice(0, 2);
  };

// Group hits by company name
const groupHitsByCompany = (hits) => {
  const groups = {};
  hits?.forEach(hit => {
    const companyName = getCompanyName(hit);
    if (!groups[companyName]) {
      groups[companyName] = [];
    }
    groups[companyName].push(hit);
  });
  return groups;
};
  const toggleExpand = (companyName) => {
    setExpandedCompanies(prev => {
      const newSet = new Set(prev);
      if (newSet.has(companyName)) {
        newSet.delete(companyName);
      } else {
        newSet.add(companyName);
      }
      return newSet;
    });
  };

  const renderHitRow = (hit, index, isHidden = false, isFirstRow = false) => (
    <div key={index} className={`py-1 border-b border-slate-300 align-middle grid grid-cols-1 md:grid-cols-[80%_20%] justify-between items-center gap-4 px-4 ${isHidden ? 'bg-gray-50' : ''}`}>
      <div className="flex items-center my-2 text-[#c48a09]">
        <div className="flex items-center gap-1 min-w-max">
          <NewLogo 
            id={hit.document.company_id}
            menu={collectionName === "cfs_company" ? "cfs" : collectionName === "pe_fund" ? "investor" : ["re_company", "re_investment"].includes(collectionName) ? "re" : "pe"}
            name={getCompanyName(hit)}
          />
          <div className="flex items-center">
            <NavLink
              className="overflow-hidden overflow-ellipsis"
              onClick={(e) => {
                setSearchText("");
                if(hit.document.date) {
                  const urlMapping = companyUrlMappings.find(mapping => mapping.accessor === collectionName);
                  if (!urlMapping?.isAllowed) {
                    e.preventDefault();
                    e.stopPropagation();
                    onBlurColumnClick();
                  }
                } else {
                  const urlMapping = generalUrlMappings.find(mapping => mapping.accessor === collectionName);
                  if (!urlMapping?.isAllowed) {
                    e.preventDefault();
                    e.stopPropagation();
                    onBlurColumnClick();
                  }
                }
              }}
              to={hit.document.date 
                ? generateCompanyUrl(collectionName, hit.document.company_id || hit.document.investor_id, hit.document?.deal_id)
                : generateUrl(collectionName, hit.document.company_id || hit.document.investor_id, hit.document?.deal_id, hit.document.advisor_type ? hit.document.advisor_type : null)}
            >
              {getCompanyName(hit)}
              {hit.document.date ? (
                <NavLink 
                  className="bg-gradient-to-br from-sky-100 to-red-100 py-0.5 px-2 rounded text-gray-500 text-xs ml-1 "
                  onClick={(e) => {
                    setSearchText("");
                    const urlMapping = generalUrlMappings.find(mapping => mapping.accessor === collectionName);
                    if (!urlMapping.isAllowed) {
                      e.preventDefault();
                      e.stopPropagation();
                      onBlurColumnClick();
                    }
                  }}
                  to={generateUrl(collectionName, hit.document.company_id || hit.document.investor_id, hit.document?.deal_id)}
              >
                {asHumanDate(hit.document.date)}
              </NavLink>) : ""}
              {hit.document.advisor_type ? (
                  <span 
                    className="bg-gradient-to-br from-sky-100 to-red-100 py-0.5 px-2 rounded text-gray-500 text-xs ml-1"
                  >
                    {hit.document.advisor_type === "L" ? "Legal" : hit.document.advisor_type === "T" ? "Transaction" : ""}
                  </span>
                ) : null
              }
            </NavLink>
            {isFirstRow && hits.filter(h => getCompanyName(h) === getCompanyName(hit)).length > 1 && (
              <button
                onClick={() => toggleExpand(getCompanyName(hit))}
                className="ml-2 p-1 hover:bg-gray-100 rounded-full"
              >
                {expandedCompanies.has(getCompanyName(hit)) ? (
                  <Minus className="w-4 h-4 text-gray-600" />
                ) : (
                  <Plus className="w-4 h-4 text-gray-600" />
                )}
              </button>
            )}
          </div>
        </div>
        {/* Only show description for the first row */}
        {isFirstRow && (
          <div className="ml-4 whitespace-nowrap w-full overflow-hidden overflow-ellipsis">
            <p className="text-sm text-black overflow-hidden overflow-ellipsis">
              {getFirstNonEmptyField(hit.document)}
            </p>
          </div>
        )}
      </div>
      <div className="md:col-span-1 flex pr-2">
        {highlightsSectionHandler(hit)}
      </div>
    </div>
  );

  const groupedHits = groupHitsByCompany(hits);

  return (
    <>
      <div className={`${SearchResult?.length - 1 === key ? "border-b" : ""}`}>
        <div className="border border-slate-200">
          <div className="w-full text-left">
            <div className="border-y border-slate-300 bg-white text-gray-700 grid grid-cols-1 md:grid-cols-[80%_20%] gap-4 justify-between items-center w-full font-semibold px-4 py-1">
              <div className="hidden md:block col-span-1">Entity Name</div>
              <div className="md:col-span-1">Search Result Indicator</div>
            </div>
            <div>
              {Object.entries(groupedHits).map(([companyName, companyHits], groupIndex) => (
                <div key={groupIndex}>
                  {renderHitRow(companyHits[0], 0, false, true)} {/* First row with description */}
                  {expandedCompanies.has(companyName) && companyHits.slice(1).map((hit, index) => (
                    renderHitRow(hit, index + 1, true, false) /* Expanded rows without description */
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </>
  );
};
