import React from 'react';
import Layout from '../../components/layout';
import { motion } from "framer-motion";
import impact from "../unicorn/impact.png";

const Entrepreneurs = () => {
    return(
        <Layout>
            <div className="min-h-screen bg-gray-50">
                {/* Hero section with image */}
                <div className="relative h-48 mb-12">
                    <div 
                        className="absolute inset-0 bg-cover bg-center"
                        style={{ 
                        backgroundImage: `url(${impact})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                        }}
                    >
                        <div className="absolute inset-0 bg-black/20"></div>
                    </div>
                    
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="relative z-10 h-full flex flex-col items-center justify-center text-center px-4"
                    >
                        <h1 className="text-5xl font-bold text-[#efefef] mb-6 relative group">
                        <span className="relative">
                            ENTREPRENEURS
                        <span className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-[#c59c40] to-[#a07924] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                        </span>
                        </h1>
                    </motion.div>
                </div>

                {/* Content Container */}
                <div className="max-w-6xl mx-auto px-4 pb-16">
                    {/* First Section - Contacts of PE-VC Investors (Information) */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-8"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                        <span className="text-[#c59c40]">Contacts of PE-VC Investors:</span> 
                        </h2>
                        <ul className="space-y-2 text-gray-700">
                        <li className="flex items-start">
                            <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                            <div>
                            <a href="https://www.google.com/search?q=Contacts+of+PE-VC+InvestorsThe+India+Private+Equity+%2F+Venture+Capital+DirectoryCustomized+Investor+Directories&sourceid=chrome&ie=UTF-8" 
                                className=" hover:text-[#a07924] " 
                                target="_blank"
                                rel="noopener noreferrer">
                                The India Private Equity / Venture Capital Directory 
                            </a>
                            </div>
                        </li>
                        <li className="flex items-start">
                            <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                            <div>
                            <a href="https://www.ventureintelligence.info/" 
                                className=" hover:text-[#a07924] " 
                                target="_blank"
                                rel="noopener noreferrer">
                                Customized Investor Directories
                            </a>
                            </div>
                        </li>
                        </ul>
                    </motion.div>

                    {/* Second Section - Get Listed (Information) */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-8"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                        <span className="text-[#c59c40]">Get Listed in Our Premium "Companies Seeking Funding" Section</span> 
                        </h2>
                        <p className="text-gray-700 mb-4">
                        Fill out the&nbsp;
                        <a 
                            href="/compseekfund" 
                            className="hover:text-[#a07924] underline" 
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            form
                        </a>&nbsp;
                        with the necessary details and upload your pitch deck. Once submitted, we'll feature your request to our network of subscribers, including PE-VC investors, family offices, angel networks, investment banks, and advisors.
                        </p>
                    </motion.div>

                    {/* Third Section - Competitive Intelligence (Pricing) */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                        className="bg-white rounded-lg shadow-md overflow-hidden mb-8"
                    >
                        <div className="bg-gradient-to-r from-[#c59c40] to-[#a07924] p-4">
                        <h2 className="text-2xl font-bold text-white">
                            Want to Know your Competitors' P&L?
                        </h2>
                        </div>
                        <div className="p-6">
                        <ul className="space-y-2 text-gray-700 mb-4">
                            <li className="flex items-start">
                            <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                            <div>Wish you had intelligence about your competitors' financials?</div>
                            </li>
                            <li className="flex items-start">
                            <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                            <div>Venture Intelligence "Competitive Intelligence" provides you a crisp summary of Financials of your top competitors (including that of Private Limited companies that is not disclosed elsewhere).</div>
                            </li>
                        </ul>
                        
                        <div className="bg-gray-50 p-4 rounded-lg border-2 border-[#c59c40] mb-4 w-[47%]">
                            <p className="text-lg font-bold text-[#c59c40] mb-1">Pricing:</p>
                            <p className="text-gray-800 font-semibold">Just Rs 5,000 for 3 companies; Rs 1,500 per additional company.</p>
                        </div>
                        
                        <p className="text-gray-700 mb-4">
                            <span className="font-semibold">Custom requests</span><br />
                            Just email the list of companies (that you would like the financials for) along with your contact details to 
                            <a href="mailto:bizdev@ventureintelligence.com" className="text-[#c59c40] hover:text-[#a07924] hover:underline ml-1">
                            bizdev@ventureintelligence.com
                            </a>
                        </p>
                        </div>
                    </motion.div>

                    {/* Fourth Section - Showcase your Company (Pricing) */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.8 }}
                        className="bg-white rounded-lg shadow-md overflow-hidden"
                    >
                        <div className="bg-gradient-to-r from-[#c59c40] to-[#a07924] p-4">
                        <h2 className="text-2xl font-bold text-white">
                            Showcase your Company to PE-VC Firms
                        </h2>
                        </div>
                        <div className="p-6">
                        <p className="text-gray-700 mb-4">
                            Insert a short note (100 words or less) about your fundraising requirement in 5 issues of the Venture Intelligence &nbsp;
                        <a 
                            href="/Dealdigest.html" 
                            className="hover:text-[#a07924] underline" 
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Deal Digest daily
                        </a>&nbsp; e-newsletter (and one &nbsp;
                        <a 
                            href="/newsletters" 
                            className="hover:text-[#a07924] underline" 
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            weekly edition
                        </a>). The Deal Digest reaches over 450 firms (3,000+ individual recipients) across Private Equity/Venture Capital Funds, Angel Investors, Investment Banks, etc.
                        </p>
                        
                        <div className="bg-gray-50 p-4 rounded-lg border-2 border-[#c59c40] mb-4 w-[47%]">
                            <p className="text-lg font-bold text-[#c59c40] mb-1">Pricing:</p>
                            <p className="text-gray-800 font-semibold">INR 5,000+GST per insert.</p>
                        </div>
                        
                        <p className="text-gray-700 mb-4">
                            Let us know your funding requirement by email to 
                            <a href="mailto:bizdev@ventureintelligence.com" className="text-[#c59c40] hover:text-[#a07924] hover:underline mx-1">
                            bizdev@ventureintelligence.com
                            </a>
                            with the following details:
                        </p>
                        
                        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-4">
                            <p className="font-semibold mb-2">(Your Brand Name) looking to raise Rs. (Amt in Figures)</p>
                            <ul className="space-y-2 text-gray-700">
                            <li><span className="font-medium">Business:</span> (Add 1 or 2 Sentences to describe your business.)</li>
                            <li><span className="font-medium">Speciality:</span> (Add 1 Sentence to convey what is special / differentiated about your venture.)</li>
                            <li><span className="font-medium">Website:</span> (enter URL - pls include the http:// in the front)</li>
                            <li><span className="font-medium">For more details, contact</span> (Name, Designation) on (enter your Mobile No) / (enter your email id)</li>
                            </ul>
                        </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </Layout>
    )
};

export default Entrepreneurs;