import Container from "../layout/Container"
import CardsContainer from "../cardsContainer/CardsContainer"

const Blog = () => {
  return (
    <div id="whats-new" className="w-full">
      <Container>
        <div className="flex flex-col">
          <h2 className="text-3xl font-bold text-gray-900 text-center">What's New</h2>
          <div className="px-4 my-10 ">
            <CardsContainer />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Blog

