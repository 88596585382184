import React,{useContext, useEffect, useState} from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";

/**
 * The ToggleSwitch component is a customizable switch that toggles between two options and triggers a
 * callback function when toggled.
 */
const ToggleSwitch =({ onToggle,onLabel,offLabel, condn, from }) =>{

  const {setSwitchState} = useContext(PeFilterContext);

  const [value, setValue] = useState(true) 

  const buttonClassName = `flex items-center space-x-[6px] rounded py-[2px] px-[5px] text-[11px] `;
  const buttonClassNameActive = `light  text-[#FFFFFF] bg-[#BC8B20]`;
  const buttonClassNameInactive = `dark text-body-color`;
useEffect(()=>{
  value === false ? setSwitchState("and") : setSwitchState("or");
console.log("gjhjhg" , "inside")
},[onToggle])
  return (
    <div>
      <label for="themeSwitcherOne" className="themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1">
        <span
          onClick={() => {onToggle(from === "cfsRefineSearch" ? "and" : "bar");setValue(false)}}
          className={`${buttonClassName}  ${value === false ? `${buttonClassNameActive}` : `${buttonClassNameInactive}`}`}>
          {onLabel}
        </span>

        <span
          onClick={() => {onToggle(from === "cfsRefineSearch" ? "or" : "pie");setValue(true)}}
          className={`${buttonClassName}  ${value === true ? `${buttonClassNameActive}` : `${buttonClassNameInactive}`}`}>
          {offLabel}
        </span>
      </label>
    </div>

  );
}


export default ToggleSwitch;