import React from 'react';
import Layout from '../../components/layout';
import { motion } from "framer-motion";
import impact from "../unicorn/impact.png";

const Events = () => {
    return(
        <Layout>
            <div className="min-h-screen bg-gray-50">
                {/* Hero section with image */}
                <div className="relative h-48 mb-12">
                    <div 
                        className="absolute inset-0 bg-cover bg-center"
                        style={{ 
                        backgroundImage: `url(${impact})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                        }}
                    >
                        <div className="absolute inset-0 bg-black/20"></div>
                    </div>
                    
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="relative z-10 h-full flex flex-col items-center justify-center text-center px-4"
                    >
                        <h1 className="text-5xl font-bold text-[#efefef] mb-6 relative group">
                        <span className="relative">
                            EVENTS
                        <span className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-[#c59c40] to-[#a07924] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                        </span>
                        </h1>
                    </motion.div>
                </div>

                {/* Content Container */}
                <div className="max-w-6xl mx-auto px-4 pb-16">
                    {/* Main Header */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-8"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                            <span className="text-[#c59c40]">Venture Intelligence hosts premier events for the Private Equity-Entrepreneur Ecosystem</span> 
                        </h2>
                        <p className="text-gray-700 mb-4">
                            Our topical, focused and well attended events offer you an excellent platform for gaining knowledge as well as developing your network of contacts.
                        </p>
                    </motion.div>

                    {/* Recent Events Section */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-8"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                            <span className="text-[#c59c40]">Recent Events:</span> 
                        </h2>
                        <ul className="space-y-4 text-gray-700 mb-4">
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On Feb 18, 2025, Venture Intelligence organized the APEX'25 PE-VC Summit & Awards at Mumbai. <a href="/apex25.html" target='_blank'  className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On Sept 17, 2024, Venture Intelligence organized the PE-VC Conclave at Bangalore. <a href="/conclave24-b.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On June 5, 2024, Venture Intelligence organized the PE--VC Conclave at Mumbai. <a href="/conclave24-m.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On February 8, 2024 Venture Intelligence organized the APEX'24 PE-VC Summit & Awards at Mumbai. <a href="/apex24.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On October 11, 2023, Venture Intelligence organized the PE-VC Conclave at Delhi-NCR. <a href="/conclave23-d.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On July 13, 2023, Venture Intelligence organized the PE/VC Conclave at Bengaluru. <a href="/conclave23-b.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On Feb 15, 2023, Venture Intelligence organized its flagship APEX PE-VC Summit & Awards at Mumbai. <a href="/apex23.htm" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On Nov 3, 2022, Venture Intelligence organized the PE-VC Conclave at Mumbai. <a href="/conclave22-m.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On Sep 6, 2022, Venture Intelligence organized the PE/VC Conclave at Bengaluru. <a href="/conclave22-b.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On February 24, 2022, Venture Intelligence organized an online interaction (webinar) with Jeffrey Zirlin, Co-Founder & Growth Lead, Sky Mavis / Axie Infinity</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On Feb 27, 2019, Venture Intelligence organized its flagship APEX PE/VC Summit at Mumbai. <a href="/apex19.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On September 11, 2018, Venture Intelligence partnered MFIN to organize the 5th Edition of the Microfinance Investment Conclave at Mumbai</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On Feb 28, 2018, Venture Intelligence organized its flagship APEX PE/VC Summit at Mumbai. <a href="/apex18.htm" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On Feb 22, 2017, Venture Intelligence organized its flagship APEX PE/VC Summit at Mumbai. <a href="/apex17.htm" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click Here</a> for the highlights.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On October 20, 2016, Venture Intelligence helped SIDBI organize the Launch of the <a href="http://venturefund.sidbi.in/?q=content/aspire-fund-0" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Aspire Fund of Funds </a> by the Hon. Minister of Micro, Small & Medium Enterprises, Government of India</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On March 9, 2016, Venture Intelligence organized the Special 10th Edition of the <a href="/apex16.htm" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">APEX Private Equity - Venture Capital Summit </a> in Bangalore</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On February 15, 2016, Venture Intelligence helped CanBank Ventures organize the <a href="https://ventureintelligence.blogspot.com/2016/02/edf-to-back-funds-from-kitven-exfinity.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Launch of the Electronics Development Fund (EDF) </a> by the Hon. Minister of Communications & IT, Government of India</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>On November 5, 2015, Venture Intelligence helped CanBank Ventures organize the <a href="http://ventureintelligence.blogspot.in/2015/11/canbank-venture-capital-fund-to-manage.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline">Symposium on Electronics Development Fund (EDF) </a> at Mumbai to discuss the fund-of-funds initiative of the Department of Electronics & Information Technology (DeitY), Ministry of Communications & IT</div>
                            </li>
                        </ul>
                    </motion.div>

                    {/* Why attend Section */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-8"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                            <span className="text-[#c59c40]">Why attend Venture Intelligence Events?</span> 
                        </h2>
                        
                        <h3 className="text-xl font-bold text-gray-800 mt-4 mb-2">1. The Content</h3>
                        <p className="text-gray-700 mb-4">
                            With several publications dedicated to private equity, Venture Intelligence is highly focused and knows its market intimately. We are uniquely placed to develop events that are highly relevant and address the right topics at the right time.
                        </p>
                        
                        <h3 className="text-xl font-bold text-gray-800 mt-4 mb-2">2. The Speakers</h3>
                        <p className="text-gray-700 mb-4">
                            Venture Intelligence events enjoy the enthusiastic support of the most significant players in this industry. Delegates at our events get to meet and hear from leaders who are shaping the PE/M&A industry.
                        </p>
                        
                        <h3 className="text-xl font-bold text-gray-800 mt-4 mb-2">3. The Networking</h3>
                        <p className="text-gray-700 mb-4">
                            We work hard to ensure our events provide the best value to attendees - not just content, but also networking.
                        </p>
                        
                        <p className="text-gray-700 mb-4">
                            <a href="/trial2.htm" className="text-[#c59c40] hover:text-[#a07924] hover:underline">Click here</a> to learn about past Venture Intelligence events.
                        </p>
                    </motion.div>

                    {/* Partnership Section */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        className="bg-white rounded-lg shadow-md p-6"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                            <span className="text-[#c59c40]">Partnership for Custom Events</span> 
                        </h2>
                        <p className="text-gray-700 mb-4">
                            Would you like to organize an event targeting participation from PE/VC Firms, Entrepreneurs, Investment Bankers and related players in the transactions ecosystem? Venture Intelligence, leveraging its experience organizing such events since 2006, is your ideal partner for this purpose. <a href="/trial2.htm" className="text-[#c59c40] hover:text-[#a07924] hover:underline">Contact Us</a> for more details.
                        </p>
                    </motion.div>
                </div>
            </div>
        </Layout>
    )
};

export default Events;