import React, { useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const ArchiveYearSelector = ({ onYearChange }) => {
  const currentYear = new Date().getFullYear();
  const earliestYear = 2013;
  const [startYear, setStartYear] = useState(currentYear - 1);
  const [selectedYear, setSelectedYear] = useState(currentYear - 1);

  // Generate 6 years starting from startYear
  const generateYearRange = () => {
    return Array.from({ length: 6 }, (_, i) => startYear - i).filter(
      (year) => year >= earliestYear
    );
  };

  const handleLeftArrowClick = () => {
    if (startYear < currentYear - 1) {
      setStartYear((prev) => prev + 1);
    }
  };

  const handleRightArrowClick = () => {
    if (startYear - 5 > earliestYear) {
      setStartYear((prev) => prev - 1);
    }
  };

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    onYearChange(year, "cy");
  };

  const yearRange = generateYearRange();

  return (
    <div className="flex items-center justify-center space-x-4">
      <button
        onClick={handleLeftArrowClick}
        disabled={startYear === currentYear - 1}
        className={`
          p-2 rounded-full 
          ${startYear === currentYear - 1 ? "text-gray-300 cursor-not-allowed" : "text-black hover:bg-gray-100"}
        `}
      >
        <LeftOutlined />
      </button>

      <div className="flex space-x-2">
        {yearRange.map((year) => (
          <div
            key={year}
            onClick={() => handleYearSelect(year)}
            className={`
              px-3 py-1 rounded-md cursor-pointer
              ${selectedYear === year 
                ? "bg-[#A26F35] text-white" 
                : "bg-white border border-[#A26F35] text-[#A26F35] hover:bg-gray-50"}
            `}
          >
            {year}
          </div>
        ))}
      </div>

      <button
        onClick={handleRightArrowClick}
        disabled={startYear - 5 <= earliestYear}
        className={`
          p-2 rounded-full 
          ${startYear - 5 <= earliestYear ? "text-gray-300 cursor-not-allowed" : "text-black hover:bg-gray-100"}
        `}
      >
        <RightOutlined />
      </button>
    </div>
  );
};

export default ArchiveYearSelector;
