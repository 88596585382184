import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/vi-logo.png';
import RingTop from '../images/ring1.png';
import RingBottom from '../images/ring3.png';
import Laptop from '../images/laptop.png';
import { FaHome } from "react-icons/fa";

const Logout = () => {
    const title = "Logged Out - Venture Intelligence";
    document.title = title;

    return (
        <div>
            <div className="inset-0 h-full w-full object-cover relative overflow-hidden">
                <div className="absolute top-4 right-4">
                    <Link
                        to="/"
                        className="inline-flex items-center justify-center transition-transform transform hover:scale-110 focus:outline-none"
                    >
                        <FaHome className="text-[#A5711A] hover:text-[#BC8B20] text-3xl transition-colors duration-200 ease-in-out" />
                    </Link>
                </div>
                <div className="h-max">
                    <div className="grid grid-cols-2">
                        {/* Left side with background image */}
                        <div className="lg:col-span-1 hidden lg:block h-screen lg:bg-[#DBB25B]">
                            <div className="relative">
                                <img src={RingTop} className="absolute w-[130px] ml-[62%] xl:ml-[65.25%] xl:mt-[10%] mt-[9%]" alt="" />
                                <img src={RingBottom} className="absolute mt-[48%] ml-[12.5%] w-[60px]" alt="" />
                                <img src={Laptop} className="absolute m-[16%]" alt="" />
                            </div>
                        </div>
                        
                        {/* Right side with logout message */}
                        <div className="lg:col-span-1 col-span-2 mt-[3rem] px-6 md:px-12 lg:px-22 xl:px-24">
                            <div className="mx-auto mt-[80px] w-full max-w-screen-sm lg:w-96 lg:px-8">
                                <div className="2xl:mt-8">
                                    <img className="w-60 mx-auto" src={Logo} alt="vi" />
                                    <h2 className="mt-6 2xl:mt-[3rem] text-[24px] lg:text-[16px] text-center tracking-[-0.31pt] font-sans_book_body font-semibold leading-[26.4px] text-[#333333]">
                                        You have been logged out
                                    </h2>
                                </div>
                                
                                <div className="mt-8">
                                    {/* <p className="text-center font-sans_light_body text-[16px] lg:text-[14px] text-[#2B2A29] leading-[24px] mb-8">
                                        Thank you for using Venture Intelligence. Your session has ended successfully.
                                    </p> */}
                                    
                                    <Link to="/login">
                                        <button 
                                            className="w-full flex justify-center text-center py-2.5 border border-transparent rounded-[5px] shadow-sm text-[14px] text-[#FFFFFF] tracking-[-0.3px] leading-[17px] bg-[#BC8B20] focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        >
                                            Sign in again
                                        </button>
                                    </Link>
                                    
                                    <p className="mt-6 font-sans_light_body text-[13px] lg:text-[12px] text-[#2B2A29] leading-[24px] text-center">
                                        If you have any questions or need assistance, please contact support.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Logout;