import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';

function ExitTypeFilter({ open, accordionClick }) {

    const { ExitType, setExitType, selectedExitTypeLength } = useContext(PeFilterContext);

    const handleExitTypeChange = (e) => {
        const { name, checked, id, selectedEntities, filteredIds } = e.target;
        let updatedExitTypes;
    
        if (name === "allSelect") {
            updatedExitTypes = ExitType.map(exitstype => ({ ...exitstype, isChecked: checked }));
        } else if (name === "filteredSelect" && filteredIds) {
            updatedExitTypes = ExitType.map(exitstype => ({
                ...exitstype,
                isChecked: filteredIds.includes(parseInt(exitstype.id)) ? checked : exitstype.isChecked
            }));
        } else if (name === "selectedEntities" && selectedEntities) {
            const selectedEntityIds = selectedEntities.map(entity => parseInt(entity.id));
            updatedExitTypes = ExitType.map(exitstype => ({
                ...exitstype,
                isChecked: selectedEntityIds.includes(exitstype.id) ? !exitstype.isChecked : exitstype.isChecked
            }));
        } else {
            updatedExitTypes = ExitType.map(exitstype =>
                exitstype.id === parseInt(id) ? { ...exitstype, isChecked: checked } : exitstype
            );
        }
    
        setExitType(updatedExitTypes);
    };

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <button
                    type="button"
                    className="w-full text-left"
                    onClick={accordionClick}
                >
                    <div className="flex items-center justify-between">
                        <span className="text-[#333333] text-[14px] leading-[17px] tracking-[-0.3px]">Type - Public Market (PE-VC)</span>
                        {open ? (

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-[17px] w-[17px] rotate-180 cursor-pointer transition-transform duration-200 mt-3 top-1/2 transform -translate-y-1/2 right-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>

                        ) : (
                            <div className='flex items-center  gap-4'>
                                <div className='mt-3'>
                                    <span className={selectedExitTypeLength > 0 ? "bg-[#BC8B20] text-white rounded-full text-[12px] p-0.5 items-center justify-center flex " + (selectedExitTypeLength < 99 ? 'h-4 w-4' : '') : ''}> {selectedExitTypeLength > 0 ? selectedExitTypeLength : ""}</span>
                                </div>
                                <div className='mt-3'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-[17px] w-[17px] rotate-0 cursor-pointer transition-transform duration-200 mt-[0.5rem]  top-1/2 transform -translate-y-1/2 right-3"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19 9l-7 7-7-7"
                                        />
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>
                </button>

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleExitTypeChange}
                        primaryEntity={ExitType}
                    /> : <></>}

                </div>

            </li>

        </div>
    )
}

/**
 * The `SelectTypeFilter` function is a React component that renders a dropdown select input for
 * filtering options based on a selected exit type.
 * @returns a JSX element.
 */
function SelectTypeFilter({ open, accordionClick, title, TypeOptions, setTypeOptions, customPathname,heading }) {
    const { ExitType, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.exitType?.map(value => value.id) ?? [];
        setTypeOptions(ExitType?.map(industry => ({ ...industry, isChecked: industryIds.includes(parseInt(industry.id)) })))
    }, [ExitType, customPathname, refineSearchFilters, setTypeOptions])


    const handleChange = (e) => {
        const { value } = e.target;
        const currentIndustry = TypeOptions.map((type) =>
            ({ ...type, isChecked: type.id === parseInt(value) })
        );
        setTypeOptions(currentIndustry);
    };

    const selectedExitType = TypeOptions?.filter(indutry => indutry.isChecked)?.at(0)

    let options = [<option key={"exitype.id"}>All</option>]

    TypeOptions.forEach(type => {
        options.push(<option key={type.id} value={type.id}>{type.name}</option>)
    });

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={true}
                    label={selectedExitType?.name ? `${heading} - ` + selectedExitType?.name : `${heading}`}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    <select name="" id="" value={selectedExitType?.id ?? ""} onChange={handleChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {TypeOptions && TypeOptions.length > 0
                            ? (options)
                            : (<h1>No results found!</h1>)
                        }
                    </select>
                </div>

            </li>

        </div>
    )
}

export { SelectTypeFilter };
export default ExitTypeFilter