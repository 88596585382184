import React from 'react';
import { CheckCircle } from 'lucide-react';

const SubscriptionOptions = () => {
  return (
    <section className="bg-[#ffffff] min-h-screen flex items-center" id="products">
      <div className="max-w-[64rem] mx-auto px-4">
        <h2 className="text-center text-3xl font-semibold mb-8">Subscription Options</h2>
        
        <div className="grid md:grid-cols-2 gap-24">
          {/* One-off Requirement */}
          <div className="bg-white p-8 rounded-lg shadow-md flex flex-col h-full border border-gray-200">
            <h3 className="text-xl font-semibold mb-6 text-center">One-off Requirement</h3>
            <ul className="space-y-5">
              <li className="flex items-start">
                <CheckCircle className="text-green-600 mr-2" />
                <span>P&L, BS, CF Statements in excel format</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="text-green-600 mr-2" />
                <span>Annual Filings</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="text-green-600 mr-2" />
                <span>₹5,000 for 3 companies</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="text-green-600 mr-2" />
                <span>₹1,500 for every additional company</span>
              </li>
            </ul>
            
            <div className="border-t border-gray-200 my-4 w-1/2 mx-auto"></div>
            
            <p className="text-center my-4">
              Ideal for competitive intelligence and<br />one-off requirements
            </p>
            
            <div className="mt-auto flex justify-center">
              <a 
                href="mailto:bizdev@ventureintelligence.com" 
                className="bg-yellow-600 text-white px-6 py-2 rounded text-center hover:bg-yellow-700 transition-colors"
              >
                Mail Us
              </a>
            </div>
          </div>

          {/* Annual Subscription */}
          <div className="bg-white p-8 rounded-lg shadow-md flex flex-col h-full border border-gray-200">
            <h3 className="text-xl font-semibold mb-6 text-center">Annual Subscription</h3>
            <ul className="space-y-5">
              <li className="flex items-start">
                <CheckCircle className="text-green-600 mr-2" />
                <span>60,000 existing companies (with Filings)</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="text-green-600 mr-2" />
                <span>100+ companies added daily</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="text-green-600 mr-2" />
                <span>Custom company addition with industry best TAT</span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="text-green-600 mr-2" />
                <span>Pro-active & responsive customer support</span>
              </li>
            </ul>
            
            <div className="border-t border-gray-200 my-4 w-1/2 mx-auto"></div>
            
            <p className="text-center my-4">
              Ideal for PE/VC Firms, Investment Banks, Corporate
              Law firms and will be effective package when
              combined with other databases.
            </p>
            
            <div className="mt-auto flex justify-center">
              <a href='/trial.html' target='_blank' className="bg-yellow-600 text-white px-6 py-2 rounded hover:bg-yellow-700 transition-colors">
                Request a Demo
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscriptionOptions;
