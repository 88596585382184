import React, { useContext, useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity';
import FilterWrapper from './FilterWrapper';
import useScrollIntoView from './useScrollIntoView';

/**
 * The `ExitStatusFilter` function is a React component that renders a filter for exit status options,
 * allowing the user to select multiple options.
 * @returns a JSX element.
 */
function ExitStatusFilter({ open, accordionClick, ExitStatusOptions, setExitStatusOptions, customPathname, dataPath }) {
    const { exitStatusResponseData, refineSearchFilters } = useContext(PeFilterContext);

    const listItemRef = useScrollIntoView(open);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.exitStatus?.map(value => value.id) ?? [];
        setExitStatusOptions(exitStatusResponseData[dataPath]?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })));
    }, [exitStatusResponseData, customPathname, refineSearchFilters, setExitStatusOptions, dataPath]);

    const handleExitStatusChange = (e) => {
        const { name, checked, id, filteredIds } = e.target;
    
        if (name === "allSelect") {
            let currentExitStatus = ExitStatusOptions.map((exitStatus) =>
                ({ ...exitStatus, isChecked: checked })
            );
            setExitStatusOptions(currentExitStatus);
        } else if (name === "filteredSelect" && filteredIds) {
            let currentExitStatus = ExitStatusOptions.map((exitStatus) => {
                if (filteredIds.includes(exitStatus.id)) {
                    return { ...exitStatus, isChecked: checked };
                }
                return exitStatus;
            });
            setExitStatusOptions(currentExitStatus);
        } else {
            let currentExitStatus = ExitStatusOptions.map((exitStatus) =>
                exitStatus.id === parseInt(id) ? { ...exitStatus, isChecked: checked } : exitStatus
            );
            setExitStatusOptions(currentExitStatus);
        }
    };
    

    const len = ExitStatusOptions?.filter(option => option.isChecked)?.length ?? 0;

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"}
                ref={listItemRef}
            >
                <FilterWrapper
                    isSelect={false}
                    label={"Exit Status"}
                    onClick={accordionClick}
                    count={len}
                    open={open}
                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleExitStatusChange}
                        primaryEntity={ExitStatusOptions}
                        showAll={false}
                    /> : <></>}
                </div>
            </li>
        </div>
    );
}

export default ExitStatusFilter;
