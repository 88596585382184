import React, { useState } from "react";
import { Select } from "antd";
// import "antd/dist/reset.css";
import industriesLists from "./industriesList";

const IndustrySelector = ({ onIndustryChange, selectedIndustry, isMobile }) => {
  const [industry, setIndustry] = useState(selectedIndustry || "");

  const handleIndustryChange = (value) => {
    setIndustry(value);
    onIndustryChange && onIndustryChange(value === "" ? null : value);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  // Handle display based on screen size
  const getDisplayText = (text) => {
    if (isMobile) {
      return truncateText(text, 15);
    }
    return text;
  };

  // Generate options for Select component
  const industryOptions = [
    { value: "", label: "Industry" },
    ...industriesLists.map((industry) => ({
      value: industry.industryName,
      label: getDisplayText(industry.industryName)
    }))
  ];

  return (
    <div className="inline-flex items-center mt-7 mr-2">
      <Select
        value={industry}
        onChange={handleIndustryChange}
        options={industryOptions}
        style={{ 
          minWidth: isMobile ? "120px" : "180px",
          borderColor: "#b5844c"
        }}
        className="industry-select h-[35px] md:h-[40px] lg:h-[40px] xl:h-[40px]"
        placeholder="Industry"
      />
      
      <style jsx global>{`
        /* Remove shadow from select element in all states */
        .industry-select .ant-select-selector {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
        }
        
        /* Remove shadow in hover state */
        .industry-select:hover .ant-select-selector {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
        }
        
        /* Remove shadow in focus state */
        .industry-select.ant-select-focused .ant-select-selector {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
        }
        
        /* Remove shadow in opened state */
        .industry-select.ant-select-open .ant-select-selector {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
        }

        /* Font weight for selected item */
        .ant-select-selection-item {
          font-weight: 600 !important;
        }

        /* Apply to all possible interactions */
        .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
        }

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
        }
      `}</style>
    </div>
  );
};

export default IndustrySelector;