import React, { useState, useMemo, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom";
import CompanyLogo from "../NewLogo";
import RandomLogo from "../RandomLogo";
import { routeNames } from "../../routeSegments";
import ExcelDownloader from "../shared/ExcelDownloader";
import { exportExcel, getMergeAcquisitionsUrl } from "../../Config/config";
import { RaiseRequestContext } from "../../Contexts/RaiseRequestContext";
import axios from "axios";
import Constants from "../../Constants";
import { UserContext } from "../../Contexts/UserContext";
import Paginate from "../Paginate";
import ConfirmPopup from "../../Utils/ConfirmPopup";
import { useSortBy, useTable, usePagination } from "react-table";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";
import SortIcon from "mdi-react/SortIcon";
import LockIcon from "mdi-react/LockIcon";
import Loader from "../Loader";
import useModule from "../../hooks/useModule";
import OverflowToolTip from "../shared/OverflowToolTip";
import NewLogo from "../NewLogo";

/**
 * The code is a React component that fetches merger and acquisition data for a specific company and
 * displays it in a table. It also includes sorting and pagination functionality.
 */
const MergerAcquisition = ({ companyName, data }) => {
  const [mergerAcquisition, setMergerAcquisition] = useState();
  const [isExport, setIsExport] = useState();
  const [showXlDownload, setshowXlDownload] = useState(false);

  const { openRequestPopup } = useContext(RaiseRequestContext);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();

  let companyFundingId = parseInt(companyId);
  const { isTrialExport, getToken, isStudent, isDirectoryUser, getAllowedModules } = useContext(UserContext);
  const [alert, setalert] = useState({ message: "", show: false });
  const { primaryMasterId } = useModule();

  const getCaptable = () => {
    setMergerAcquisition(data);
    setIsLoading(false);
  };

  const onConfirmation = (flag) => {
    setalert({ show: false, message: "" });
  };

  const onBlurColumnClick = (cell) => {
    setalert({
      show: true,
      message: Constants.maSubscriptionMsg,
      isAlertOnly: true,
      closeBtnName: "OK",
    });
  };

  useEffect(() => {
    getCaptable();
  }, [companyFundingId]);

  /* The above code is defining a custom sorting function called `customStringSortMemo` using the
`useMemo` hook in React. This function takes in two rows (`rowA` and `rowB`), a column ID, and a
boolean value `desc` indicating whether the sorting should be in descending order. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? "--" : "A";
      return (rowA?.values[columnId]?.name ?? defaultVal).localeCompare(
        rowB?.values[columnId]?.name ?? defaultVal
      );
    };
  }, []);

  /* The above code is defining a custom sorting function called `dealDateSort`. This function is used
  to sort rows in a table based on a specific column called `columnId`. The sorting is done in
  ascending order by comparing the dates in the `columnId` of each row using the `moment.utc`
  function. The `useMemo` hook is used to memoize the sorting function so that it is only
  recalculated when its dependencies change. . */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment
        .utc(rowA?.values[columnId].date)
        .diff(moment.utc(rowB?.values[columnId].date));
    };
  }, []);
  let blurColumns = true;

  const allowedModulesStr = localStorage.getItem(
    Constants.localStorageKeys.allowedModules
  );
  const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];

  if (allowedModulesList.includes("pe")) {
    blurColumns = false;
  } else {
    blurColumns = true;
  }

  const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columns = useMemo(
    () => [
      {
        id: "companyName",
        Header: "Company Name",
        accessor: "companyName",
        className:
          "clickable  w-[15%] text-left sticky left-0  lg:relative bg-white",
        headerClassName:
          "md:pl-5 md:pr-5 pl-5 pr-[3rem] lg:pr-[8rem] text-left sticky left-0 lg:relative  bg-[#EDEDED] ",
        Cell: ({ value }) => {
          return (
            <span className="leading-[1.8rem] lg:leading-[2rem] font-sans_book_body items-center justify-center">
              <NewLogo id={value.id} name={value.name} menu={"pe"} />
              <OverflowToolTip
                className=" w-full"
                component={
                  <Link
                    onClick={(e) => {
                      if (value.restricted == Constants.restrictedPlaceholder) {
                        e.preventDefault();
                        e.stopPropagation();
                        onBlurColumnClick();
                      }
                    }}
                    rel="noopener noreferrer"
                    className="flex text-start lg:inline leading-[1.1rem] hover:text-[#BC8B20]"
                    to={routeNames.maCompanyById(value.id)}
                  >
                    {value.name ?? ""}
                    {value?.is_minority_deal && (
                      <span className="rounded-md bg-[#f3e8ff] px-2  py-[3px] ml-2 text-xs  font-sans_book_body text-[#3939ab]">
                        Minority/Strategic
                      </span>
                    )}
                    {value?.is_asset_sale && (
                      <span className="rounded-md bg-[#f3e8ff] px-2  py-[3px] ml-2 text-xs  font-sans_book_body text-[#3939ab]">
                        Asset Sale
                      </span>
                    )}
                  </Link>
                }
              />
            </span>
          );
        },
        sortType: customStringSortMemo,
      },
      {
        id: "dealDate",
        Header: "Deal Date",
        accessor: "dealDate",
        headerClassName: "",
        className: "w-[12%]",
        Cell: ({ value }) => {
          return (
            <Link
              target='_blank'
              onClick={(e) => {
                if (value.restricted == Constants.restrictedPlaceholder) {
                  e.preventDefault();
                  e.stopPropagation();
                  onBlurColumnClick();
                }
              }}
              to={routeNames.maCompanyDealById(value.id, value.dealId)}
            >
              {value?.date != "" ? moment(value?.date).format("MMM YYYY") : ""}
            </Link>
          );
        },
        sortType: dealDateSort,
      },
      {
        Header: "Deal Amount (US$ M)",
        accessor: "dealAmountInUS",
        className:
          "pr-[2.7rem] md:pr-[2.25rem] w-[4%] lg:pr-[3.7%] xl:pr-[3.8%] 2xl:pr-[4.4%] text-right",
        headerspanClassName: "ml-auto",
        headerClassName: "",
        Cell: (cell) => {
          return addCommasToNumber(cell?.value == "--" ? "" : cell?.value);
        },
      },
      {
        Header: "Acquirer",
        accessor: "Acquirer",
        headerClassName: "",
        className: "w-[14%]",
        sortType: customStringSortMemo,
        Cell: ({ value }) => {
          return (
            <p style={{ whiteSpace: "break-spaces" }}>
              <OverflowToolTip
                className=" w-[10rem]"
                component={
                  <Link
                    onClick={(e) => {
                      if (!allowedModulesList.includes("ma")) {
                        e.preventDefault();
                        e.stopPropagation();
                        onBlurColumnClick();
                      }
                    }}
                    rel="noopener noreferrer"
                    className="hover:text-[#BC8B20]"
                    to={routeNames.maAcquiresById(value.id)}
                  >
                    {value.name ?? " "}
                  </Link>
                }
              />
            </p>
          );
        },
      },

      {
        Header: "Enterprise Value",
        accessor: "companyValuation",
        className:
          "w-[4%] text-right pr-[1px] md:pr-0 lg:pr-[3.6%] xl:pr-[3.5%] 2xl:pr-[4.4%]",
        headerClassName: "",
        Cell: (cell) => {
          return addCommasToNumber(cell?.value == "--" ? "" : cell?.value);
        },
      },
      {
        Header: "Revenue Multiple",
        accessor: "revenueMultiple",
        className: " pr-[2.25rem] w-[2%] lg:pr-[3.7%] text-right ",
        headerspanClassName: "ml-auto",
        headerClassName: "",
        Cell: (cell) => {
          return addCommasToNumber(cell?.value == "--" ? "" : cell?.value);
        },
      },
    ],
    [primaryMasterId]
  );

  const getPEFirmDisplayName = (acquirerName) => {
    const searchStrings = {
        "pe firm(s)": "PE Firm(s)",
        "undisclosed": "Undisclosed",
        "individual": "Individual"
    };

    const acquirerNameLower = acquirerName.toLowerCase();

    for (const [key, value] of Object.entries(searchStrings)) {
        if (acquirerNameLower.includes(key)) {
            return value;
        }
    }

    return acquirerName;
}

  const rowData = useMemo(() => {
    const rows = [];

    mergerAcquisition?.forEach((company) => {
        rows.push({
          companyName: {
            name: company?.company.name,
            id: company?.company.id,
            is_asset_sale: company.is_asset_sale,
            is_minority_deal: company.is_minority_deal
          },
          dealDate: {
            date: company?.date,
            id: company?.company.id,
            dealId: company?.id,
          },
          dealAmountInUS: company?.amount == 0 || company?.is_hide_amount ? "" : company?.amount,
          Acquirer: { name: getPEFirmDisplayName(company?.acquirer.name), id: company?.acquirer.id },
          companyValuation: !allowedModulesList.includes("ma") ? Constants.restrictedPlaceholder :
            company?.company_valuation == 0 ? "" : company?.company_valuation,
          revenueMultiple: !allowedModulesList.includes("ma") ? Constants.restrictedPlaceholder : 
            company?.revenue_multiple == 0 ? "" : company?.revenue_multiple,
        });
    });

    setshowXlDownload(rows.length != 0);

    return rows;
  }, [mergerAcquisition]);
  const initialSort = useMemo(
    () => [
      {
        id: "dealDate",
        desc: true,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: rowData,
      disableSortRemove: true,
      initialState: {
        pageSize: Constants.noOfRowPerPage,
        pageIndex: 0,
      },
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const NodataComponent = () => {
    return (
      <div className="flex flex-col items-center justify-center h-14">
        <p className="px-5 text-[14px] mb-2">
          No M&A activity found for this company.{"  "}
          <button
            className="text-[#A5711A] hover:underline"
            onClick={() => openRequestPopup("MandA", "M&A")}
          >
            Click Here
          </button>
          {" "}to double check with Venture Intelligence on this.
        </p>
      </div>
    );
  };

  return (
    <div className="mx-4">
      <div className="bg-white border sm:rounded-lg shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
        <div className="px-5 pt-4">
          <div className="flex justify-between mb-3 ">
            <h3 className="text-[14pt] leading-[19pt] text-[#333333] lg:text-[17px] font-semibold  lg:leading-[22px]">
              Merger & Acquisitions
            </h3>
            {isTrialExport != "false" &&
              isStudent == "false" &&
              showXlDownload && (
                <ExcelDownloader
                  url={exportExcel}
                  fileName={Constants.exportNames.mergeAndAcquisition(
                    companyName
                  )}
                  data={{
                    export_from: "detail",
                    module_type: "investments",
                    section: "merger_acquisition",
                    type: primaryMasterId,
                    company_id: [companyId],
                  }}
                />
              )}
          </div>
        </div>
        <div className="space-y-6">
          <div className="bg-white">
            <>
              <div className="overflow-x-auto scrollbar relative">
                {rows?.length > 0 ? (
                  <table className=" datatable w-full" {...getTableProps()}>
                    <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-bold py-4 px-0 text-center ">
                      {headerGroups.map((headerGroup) => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps([
                                {
                                  className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                  style: column.headerStyle,
                                },
                                column.getSortByToggleProps(),
                              ])}
                              title=""
                            >
                              <div className="flex">
                                <span className="mr-1">
                                  {column.render("Header")}
                                </span>
                                {column.disableSortBy ? (
                                  ""
                                ) : column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortAscendingIcon
                                      size={16}
                                      className="mt-[0.15rem] lg:mt-0"
                                    />
                                  ) : (
                                    <SortDescendingIcon
                                      size={16}
                                      className="mt-[0.15rem] lg:mt-0"
                                    />
                                  )
                                ) : (
                                  <SortIcon
                                    size={16}
                                    className="mt-[0.15rem] lg:mt-0"
                                  />
                                )}
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody
                      {...getTableBodyProps()}
                      className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left"
                    >
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="border-b">
                            {row.cells.map((cell) => {
                              if (
                                cell.column.blurColumns ||
                                cell.value == Constants.restrictedPlaceholder
                              ) {
                                return (
                                  <td
                                    onClick={onBlurColumnClick}
                                    className=" relative whitespace-nowrap cursor-pointer"
                                    {...cell.getCellProps()}
                                  >
                                    <div className="blur-sm bg-[#DCDCDC] select-none w-full h-full px-5 py-4 text-center">
                                      {Constants.restrictedPlaceholder}
                                    </div>
                                    <div className="absolute h-full w-full flex items-center justify-center top-0 bottom-0">
                                      <LockIcon size={16} color="#696969" />
                                    </div>
                                  </td>
                                );
                              }
                              return (
                                <td
                                  colSpan={cell.column.colspan}
                                  rowSpan={cell.column.rowspan}
                                  {...cell.getCellProps([
                                    {
                                      className: `data-td-animate lg:whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                      style: cell.column.style,
                                    },
                                  ])}
                                >
                                  {cell.value ? cell.render("Cell") : " "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <></>
                )}
              </div>
              {isLoading ? (
                <div className="flex flex-col items-center justify-center h-40">
                  <Loader />
                </div>
              ) : rows?.length <= 0 ? (
                <NodataComponent />
              ) : (
                <></>
              )}
              {rows?.length <= Constants.noOfRowPerPage ? (
                <></>
              ) : (
                <Paginate
                  currentPage={pageIndex + 1}
                  totalCount={rows?.length}
                  pageSize={pageSize}
                  onPageChange={(page) => {
                    gotoPage(page - 1);
                  }}
                />
              )}
              {<ConfirmPopup {...alert} onChange={onConfirmation} />}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MergerAcquisition;