import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';
import useScrollIntoView from './useScrollIntoView';

/**
 * The `RegionFilter` function is a React component that renders a filter for selecting regions, with
 * options that can be expanded or collapsed.
 * @returns a JSX element.
 */
function RegionFilter({ open, accordionClick, RegionOptions, setRegionOptions, customPathname, onChange, filterPathkey = "region", label = "Region" }) {
  const { refineSearchFilters } = useContext(PeFilterContext);
  const listItemRef = useScrollIntoView(open);

  useEffect(() => {
    const ids = refineSearchFilters[customPathname]?.[filterPathkey]?.map(value => value.id) ?? [];
    setRegionOptions(RegionOptions?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
  }, [customPathname, refineSearchFilters, filterPathkey, setRegionOptions])

  const handleRegionChange = (e) => {
    const { name, checked, id, selectedEntities, filteredIds } = e.target;
    let updatedOptions;

    if (name === "allSelect") {
        updatedOptions = RegionOptions.map(region => ({ ...region, isChecked: checked }));
    } else if (name === "filteredSelect" && filteredIds) {
        updatedOptions = RegionOptions.map(region => ({
            ...region,
            isChecked: filteredIds.includes(region.id) ? checked : region.isChecked
        }));
    } else if (name === "selectedEntities" && selectedEntities) {
        const selectedEntityIds = selectedEntities.map(entity => parseInt(entity.id));
        updatedOptions = RegionOptions.map(region => ({
            ...region,
            isChecked: selectedEntityIds.includes(region.id) ? !region.isChecked : region.isChecked
        }));
    } else {
        updatedOptions = RegionOptions.map(region =>
            region.id === parseInt(id) ? { ...region, isChecked: checked } : region
        );
    }

    setRegionOptions(updatedOptions);
    onChange(updatedOptions);
};


  // console.log("RegionOptions", RegionOptions);

  const len = RegionOptions?.filter(option => option.isChecked)?.length ?? 0

  return (
    <div className="border-b-[3px] border-[#E8E6E1]">
      <li
        className={
          open
            ? "bg-white relative px-3 py-1"
            : "relative px-3 py-1"
        }
        ref={listItemRef}
      ><FilterWrapper
          isSelect={false}
          label={label}
          count={len}
          onClick={accordionClick}
          open={open}
        />

        <div
          className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
          style={{ maxHeight: open ? "10rem" : "" }}
        >
          {open === true ? <MultiSelectEntity
            handleEntityChange={handleRegionChange}
            primaryEntity={RegionOptions}
          /> : <></>}

        </div>

      </li>

    </div>
  )
}

export default RegionFilter