import { React, useContext, useState, } from "react";
import { PeFilterContext, filterLabels } from "../../Contexts/PeFilterContext";
import { TableHeaderContext } from "../../Contexts/TableHeaderContext";
import IndustryFilter from "../RefineSearchComponents/IndustryFilter";
import AuditorNameFilter from "../RefineSearchComponents/AuditorNameFilter";
import FinancialBasedFilter from "../RefineSearchComponents/FinancialFilter";
import RatiosBasedFilter from "../RefineSearchComponents/RatiosBasedFilter";
import GrowthBasedFilter from "../RefineSearchComponents/GrowthBasedFilter";
import ChargesBasedFilter from "../RefineSearchComponents/ChargeFilter";
import SectorFilter from "../RefineSearchComponents/CFSSectorFilter";
import CompanyTypeFilter from "../RefineSearchComponents/CompanyTypeFilter";
import FinancialsResultTypeFilter from "../RefineSearchComponents/FinancialsResultTypeFilter";
import YearFoundedFilter from "../RefineSearchComponents/YearFoundedFilter";
import RegionFilter from "../RefineSearchComponents/RegionFilter";
import StateFilter from "../RefineSearchComponents/StateFilter";
import CityFilter from "../RefineSearchComponents/CityFilter";
import Switch from "../RefineSearchComponents/AndOrToggleSwtich";
import Tags from "../RefineSearchComponents/Tags";
import { useLocation, useMatch, useNavigate } from "react-router";
import { TableDataContext } from '../../Contexts/TableDataContext'
import { useEffect } from "react";
import axios from "axios";
import { filterLocationUrl, filterSectorByIndutry,  tagList  } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import {  Tooltip } from "antd";
import TransactionStatusFilter from "../RefineSearchComponents/TransactionStatus";
import ToggleButton from "../RefineSearchComponents/ToggleSwitch";
import moment from 'moment';
import { toast } from "react-toastify";
import Constants from "../../Constants";
import DefinitionModal from "../TagDefinitionModal";
import { FaInfoCircle } from "react-icons/fa";
import IOCAvailable from "../RefineSearchComponents/IOCAvailable";


/**  The above code is a React component. It is a form component for refining
search filters for investment data. It includes various filter options such as industries, sectors,
transaction status, company type, region, state, city, financials, growth, ratios, and charges. The
component handles the selection and deselection of filter options, applies the selected filters to
the refine search filters state, and submits the form to perform the search. It also includes
functions for resetting the filters and handling changes in the selected options. */
export default function InvestmentRefineSearch({ customPathname }) {
    const {
        setSectors,
        onErrorHandler,dropdown,isMobile,open, setOpen,
        refineSearchFilters,
        Iocavailable, SetIocavailable,
        setrefineSearchFilters, TransactionStatus, setTransactionStatus, setMonthPickerValue,iocPayload } = useContext(PeFilterContext);
    const { currency, setCurrentPage } = useContext(TableDataContext);
    const [select, setSelected] = useState(0);
    const [isFinancialOpen, setIsFinancialOpen] = useState(false)
    const [isGrowthOpen, setIsGrowthOpen] = useState(false)
    const [isRatioOpen, setIsRatioOpen] = useState(false)
    const [isChargeOpen, setIsChargeOpen] = useState(false)
    var CFSRefine;

    const navigate = useNavigate();
    // const [savedFilters, setSavedFilters] = useState(state?.filters)
    const { getToken } = useContext(UserContext);
    const location = useLocation();
    const { pathname } = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[customPathname]?.globalSearch?.length > 0 ? true : false

    // const initialTransactionStatus =[{ id:1, name: "PE-Backed", isChecked:true},{id:2, name: "Non-PE-Backed", isChecked:true}] 
    //local updated options
    const [SwitchState, setSwitchState] = useState(true)
    const [TagsOptions, setTagsOptions] = useState([])
    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [CompanyTypeOptions, setCompanyTypeOptions] = useState([])
    // const [TransactionStatus, setTransactionStatus]       = useState(initialTransactionStatus)
    const [CountryOptions, setCountryOptions] = useState([])
    const [RegionOptions, setRegionOptions] = useState([])
    const [StateOptions, setStateOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [FinancialTypeOptions, setFinancialTypeOptions] = useState([])
    const [StartYearOptions, setStartYearOptions] = useState([])
    const [EndYearOptions, setEndYearOptions] = useState([]);
    const [AuditiorNameOptions, setAuditiorNameOptions] = useState([])
    const [ChargeStateOptions, setChargeStateOptions] = useState([])
    const [ChargeCityOptions, setChargeCityOptions] = useState([])
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [minChargeAmount, setMinChargeAmount] = useState("")
    const [maxChargeAmount, setMaxChargeAmount] = useState("")
    const [ChargeBasedSelected, setChargeBasedSelected] = useState([])
    const [radioValue, setRadioValue] = useState(null);

    //Growth variables
    const [GrowthYear, setGrowthYear] = useState("any")
    const [GrowthOptions, setGrowthOptions] = useState([])
    const [IsCAGR, setIsCAGR] = useState(null);
    const [AndOr, setAndOr] = useState(false);

    //Ratios variables
    const [RatioYear, setRatioYear] = useState("any")
    const [RatioYearOptions, setRatioYearOptions] = useState([])
    const [RatioAndOr, setRatioAndOr] = useState('and');

    //Financials variables
    const [FinancialYear, setFinancialYear] = useState("any")
    const [FinancialOptions, setFinancialOptions] = useState([])
    const [FinancialAndOr, setFinancialAndOr] = useState('and');
    const [currencyType, setCurrencyType] = useState(true)
    // console.log(currencyType,";FinancialOptions-12")
    
    const [TagSwitchState, setTagSwitchState] = useState(true)
    useEffect(() => {
        setTransactionStatus(TransactionStatus)
    }, [])
    /**
     * The `applyFilter` function takes selected filter options and updates the refine search filters
     * based on those options.
     */
    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedSectors = SectorOptions.filter(option => option.isChecked);
        const selectedTransaction = TransactionStatus.filter(option => option?.isChecked);
        const selectedCompanyType = CompanyTypeOptions?.filter(option => option.isChecked);
        const selectedFinancialType = FinancialTypeOptions?.filter(option => option.isChecked);
        const selectedStartYear = StartYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedEndYear = EndYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedRegion = RegionOptions?.filter(option => option.isChecked);
        const selectedState = StateOptions?.filter(option => option.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedSynd = Iocavailable?.filter(option => option.isChecked);
        const selectedChargeState = ChargeStateOptions?.filter(option => option.isChecked);
        const selectedChargeCity = ChargeCityOptions?.filter(option => option.isChecked);

        const selectedGrowths = GrowthOptions.filter(option => option.moreThan !== "").map(option => ({ id: option.value, name: `${option.label}(${option.moreThan}%)`, value: option }));
        const selectedRatios = RatioYearOptions.filter(option => option.min !== "" && option.max !== "").map(option => ({ id: option.value, name: `${option.label} greater than ${option.min} and less than ${option.max}`, value: option }));
        const selectedFinancials = FinancialOptions.filter(option => option.min !== "" && option.max !== "").map(option => ({ id: option.value, name: `${option.label} greater than ${option.min} and less than ${option.max}`, value: option }));


        const isChargeEmpty = minChargeAmount === "" && maxChargeAmount === ""
            && startDate == null && endDate == null
            && radioValue == null
            && selectedChargeState.length === 0
            && selectedChargeCity.length === 0
        const isGrowthEmpty = selectedGrowths == null || selectedGrowths?.length <= 0
        const isRatioEmpty = selectedRatios == null || selectedRatios?.length <= 0
        const isFinancialEmpty = selectedFinancials == null || selectedFinancials?.length <= 0

        //not close financial filter if filter applied
        if (isFinancialEmpty) {
            setIsFinancialOpen(false);
        }
        if (isRatioEmpty) {
            setIsRatioOpen(false);
        }
        if (isGrowthEmpty) {
            setIsGrowthOpen(false);
        }
        if (isChargeEmpty) {
            setIsChargeOpen(false);
        }


        console.log(ChargeStateOptions?.filter(option => option.isChecked),"jhgj")

        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                operator: TagSwitchState ? 1 : 0,
                tags: TagsOptions,
                industry: selectedIndustries,
                sector: selectedSectors,
                companyType: selectedCompanyType,
                transactionStatus: selectedTransaction ?? [],
                region: selectedRegion,
                state: selectedState,
                city: selectedCity,
                yearFounded: selectedEndYear != null && selectedStartYear != null ? [selectedStartYear, selectedEndYear] : [],
                auditorName: AuditiorNameOptions,
                financialType: selectedFinancialType,
                syndication: selectedSynd,

                //charge based filters
                chargeState: selectedChargeState,
                chargeCity: selectedChargeCity,
                chargeCompanies: radioValue === 'company' ? ChargeBasedSelected : [],
                chargeHolders: radioValue === 'charge_holder' ? ChargeBasedSelected : [],
                chargeDate: startDate != null && endDate != null ? [{
                    id: 0,
                    name: `${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`,
                    value: {
                        'from_date': startDate != null ? moment(startDate).format('YYYY-MM-DD') : null,
                        'to_date': endDate != null ? moment(endDate).format('YYYY-MM-DD') : null,
                    }
                }] : [],
                chargeAmount: minChargeAmount !== "" && maxChargeAmount !== "" ? [{
                    id: 0,
                    name: `${minChargeAmount} Cr - ${maxChargeAmount} Cr`,
                    value: {
                        'from_amt': parseInt(minChargeAmount),
                        'to_amt': parseInt(maxChargeAmount)
                    }
                }] : [],

                //growth based filters
                growthYear: isGrowthEmpty ? null : GrowthYear !== "" ? [{ id: 0, name: GrowthYear === "any" ? "Any Of" : "Across All", value: GrowthYear, title: filterLabels.growthFilterRequest }] : [],
                growthPercentage: isGrowthEmpty ? null : selectedGrowths,
                growthCAGR: isGrowthEmpty ? null : IsCAGR != null && IsCAGR ? [{ id: 0, name: "CAGR", value: IsCAGR }] : [],
                growthOperator: isGrowthEmpty ? null : [{ id: 0, name: `Growth operator - ${AndOr ? "OR" : "AND"}`, value: AndOr, hide: true }],

                //ratio based filters
                ratioYear: isRatioEmpty ? null : RatioYear !== "" ? [{ id: 0, name: RatioYear === "any" ? "Any Of" : "Across All", value: RatioYear }] : [],
                ratioPercentage: isRatioEmpty ? null : RatioYearOptions.filter(option => option.min !== "" && option.max !== "").map(option => ({ id: option.value, name: `${option.label}(${option.min}-${option.max})`, value: option })),
                ratioOperator: isRatioEmpty ? null : [{ id: 0, name: `Ratio operator - ${RatioAndOr ? "OR" : "AND"}`, value: RatioAndOr, hide: true }],


                //financial based filters
                financialYear: isFinancialEmpty ? null : FinancialYear !== "" ? [{ id: 0, name: FinancialYear === "any" ? "Any Of" : "Across All", value: FinancialYear, title: filterLabels.financialFilterRequest }] : [],
                financialPercentage: isFinancialEmpty ? null : FinancialOptions.filter(option => option.min !== "" && option.max !== "").map(option => ({ id: option.value, name: `${option.filterLable}(${option.min}-${option.max})`, value: option })),
                financialOperator: isFinancialEmpty ? null : [{ id: 0, name: `Financial operator - ${FinancialAndOr ? "OR" : "AND"}`, value: FinancialAndOr, hide: true }],
                financialCurrencyType: isFinancialEmpty ? null : currencyType ? [{ id: 0, name: currency === "USD" ? "In Million" : "In Crores", value: currency, title: "Financial based" }] : [],
            },
        }))
    }

   /**
    * The `resetFilter` function resets a specific filter in the `refineSearchFilters` state object.
    */
    const resetFilter = () => {
        
         setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
    }



    // Submitting Refine Search Form 

    const refineSearchFilter = (event) => { 
        if(isMobile){
            setOpen(!open);
        }
        // alert('Form Submitted!');
        event.preventDefault();
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[]}
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
        setMonthPickerValue(Constants.initialMonthRange)
        }
        const selectedCharge = ChargeBasedSelected?.map(option => option.name)
        if (radioValue != null && selectedCharge?.length === 0) {
            toast.warning(`Please choose ${radioValue === "company" ? "Company name" : "Charge holder"}`)
            return []
        }

        if (minChargeAmount !== "" && maxChargeAmount !== "") {
            const minCharge = Number(minChargeAmount)
            const maxCharge = Number(maxChargeAmount)
            if (minCharge <= 0) {
                toast.warning(`Minimum charge amount must be greater than 0`)
                return;
            }
            // if (maxCharge <= 0) {
            //     toast.warning(`Maximum charge amount must be greater than 0`)
            //     return;
            // }
            if (minCharge > maxCharge) {
                toast.warning(`Maximum charge amount must be greater than or equal to ${minCharge}`)
                return;
            }
        }
        setCurrentPage(1);
        applyFilter();
        setSelected(false)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }


    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        // if (isMatch == null || isCompanyMatch != null) {
        //     navigate(routeNames.pe_companies_pe_vc_investments);
        // }
        resetFilter()
        setSelected(0)
        setIsFinancialOpen(false)
        setIsGrowthOpen(false)
        setIsRatioOpen(false)
        setIsChargeOpen(false)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    const onRegionChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: ids,
            state: [],
            city: [],
            type: 5

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setStateOptions(response.data.state)
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

    const onStateChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked)?.map(option => option.id);

        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: selectedRegion,
            state: ids,
            city: [],
            type: 5

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

    const onChargeStateChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);

        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: ids,
            city: [],
            type: 5

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                
                setChargeCityOptions(response.data?.iocCities)
            }
        }).catch(onErrorHandler)
    }


    useEffect(() => {
        const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

        if (ids.length > 0) {
            axios.post(filterSectorByIndutry, {
                "industry": ids,
                category: "investments",
                type: 5
            }, {
                headers: {
                    "Authorization": getToken()
                }
            }).then(response => {
                if (response.status === 200) {
                    setSectors(response.data?.data ?? [])
                } else {
                    setSectors([])
                }
            }).catch(onErrorHandler)
        } else {
            setSectors([])
        }

    }, [IndustriesOptions])


    useEffect(() => {
        const countryIds = refineSearchFilters[customPathname]?.countries?.map(value => value.id) ?? [];
        const ids = refineSearchFilters[customPathname]?.region?.map(value => value.id) ?? [];
        const stateIds = refineSearchFilters[customPathname]?.state?.map(value => value.id) ?? [];
        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];

        const chargeStateIds = refineSearchFilters[customPathname]?.chargeState?.map(value => value.id) ?? [];
        const chargeCityIds = refineSearchFilters[customPathname]?.chargeCity?.map(value => value.id) ?? [];

        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],
            type: 5

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCountryOptions(response.data?.countries?.map(option => ({ ...option, isChecked: countryIds.includes(option.id) })))
                setRegionOptions(response.data?.region?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
                setStateOptions(response.data.state?.map(option => ({ ...option, isChecked: stateIds.includes(option.id) })))
                setCityOptions(response.data.city?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))

                setChargeStateOptions(response.data.iocStates?.map(option => ({ ...option, isChecked: chargeStateIds.includes(option.id) })))
                setChargeCityOptions(response.data.iocCities?.map(option => ({ ...option, isChecked: chargeCityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters])

    const handleToggle = (isOn) => {
        setSwitchState(isOn == 'and' ? true : false)
    };

    console.log('Charge based values', radioValue, ChargeBasedSelected, startDate, endDate, ChargeStateOptions, ChargeCityOptions);
    const getSearchTags = async (searchText, type) => {
        try {
            const response = await axios.post(tagList, {
                "tag": searchText,
                "type"    : type

            }, {
                headers: {
                "Authorization": getToken()
                }
            });

            if (response.status === 200) {
                return response.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    }

    const fetchData = async () => {
        const tags = await getSearchTags("ALL", "noncompetitor");
        const competitorTags = await getSearchTags("ALL", "competitor")
        openTagModal(tags, competitorTags);
    };
    
    const  [showDefinitionModal, setShowDefinitionModal] = useState(false);
    const [defValues, setDefValues] = useState({})
    console.log(defValues, 'showValues');

    const openTagModal = ( non_comp, comp) => {
        setShowDefinitionModal(true);
        setDefValues({
            non_comp: non_comp,
            comp: comp
        })
    }

    const defCloseHandler = () =>{
        setShowDefinitionModal(false);
        setDefValues({})
    }


    return (
        <div className="h-screen">
            <div className={isFromGlobalSearch() ?"flex flex-col overflow-y-auto no-scrollbar h-[80%]" : "flex flex-col overflow-y-auto no-scrollbar lg:h-[80%] xl:h-[80%] 2xl:h-[80%] "} >
                
                <form onSubmit={refineSearchFilter} onReset={onResetClick} className="bg-[#FAF5EA] w-full">
                    <main className="bg-[#FAF5EA] w-full ">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden border-t-[3px] border-[#E8E6E1]'} text-[#333333]  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>
                            <div className="flex flex-col">
                                <div className="flex justify-between px-3 items-center pt-0 pb-2">
                                    <div className="flex items-end gap-2">
                                            <p className="text-[#333333] text-[15px] leading-[17px] tracking-[-0.3px]">Tag</p>                        
                                    <span className="cursor-pointer" onClick={() => {fetchData()}
                                    }>
                                    <Tooltip title='Tag List'>
                                        <FaInfoCircle size={12} color="grey"/>
                                    </Tooltip>
                                    </span>
                                    </div>
                                    <Switch
                                        SwitchState={TagSwitchState}
                                        setSwitchState={setTagSwitchState}
                                        customPathname={customPathname} />
                                </div>
                                <div className="border-b-[3px] border-[#E8E6E1] bg-white">
                                    <div className="bg-white m-3 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
                                        <label className=" flex  rounded ">
                                            <Tags
                                                TagsOptions={TagsOptions}
                                                customPathname={customPathname}
                                                setTagsOptions={setTagsOptions} 
                                                getSearchTags={getSearchTags}/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* RefineSearch   */}
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                open={select === 1} />
                            <SectorFilter
                                SectorOptions={SectorOptions}
                                setSectorOptions={setSectorOptions}
                                accordionClick={() => accordionClick(2)}
                                customPathname={customPathname}
                                open={select === 2} />
                            <CompanyTypeFilter
                                CompanyTypeOptions={CompanyTypeOptions}
                                setCompanyTypeOptions={setCompanyTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(5)}
                                isMulti={true}
                                open={select === 5} />

                            <TransactionStatusFilter
                                TransactionStatus={TransactionStatus}
                                setTransactionStatus={setTransactionStatus}
                                customPathname={customPathname} />
                            <RegionFilter
                                RegionOptions={RegionOptions}
                                setRegionOptions={setRegionOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(11)}
                                onChange={onRegionChange}
                                open={select === 11} />
                            <StateFilter
                                StateOptions={StateOptions}
                                setStateOptions={setStateOptions}
                                customPathname={customPathname}
                                onChange={onStateChange}
                                accordionClick={() => accordionClick(12)}
                                open={select === 12} />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(13)}
                                open={select === 13} />
                            <YearFoundedFilter
                                EndYearOptions={EndYearOptions}
                                customPathname={customPathname}
                                setEndYearOptions={setEndYearOptions}
                                StartYearOptions={StartYearOptions}
                                setStartYearOptions={setStartYearOptions} />
                            <IOCAvailable
                                Iocavailable={Iocavailable}
                                SetIocavailable={SetIocavailable}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(8)}
                                open={select === 8} />
                            <AuditorNameFilter
                                AuditiorNameOptions={AuditiorNameOptions}
                                customPathname={customPathname}
                                setAuditiorNameOptions={setAuditiorNameOptions}

                            />


                            <FinancialsResultTypeFilter
                                FinancialsResultTypeOptions={FinancialTypeOptions}
                                setFinancialsResultTypeOptions={setFinancialTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(34)}
                                open={select === 34} />

                            
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out max-h-[1000px] text-[#333333] font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>
                            <FinancialBasedFilter
                                FinancialYear={FinancialYear}
                                setFinancialYear={setFinancialYear}
                                FinancialOptions={FinancialOptions}
                                setFinancialOptions={setFinancialOptions}
                                FinancialAndOr={FinancialAndOr}
                                setFinancialAndOr={setFinancialAndOr}
                                currencyType={currencyType}
                                setCurrencyType={setCurrencyType}
                                customPathname={customPathname}
                                currency={currency}
                                accordionClick={() => setIsFinancialOpen(prev => !prev)}
                                open={isFinancialOpen}
                            />
                            
                            <RatiosBasedFilter
                                RatioAndOr={RatioAndOr}
                                setRatioAndOr={setRatioAndOr}
                                RatioYear={RatioYear}
                                setRatioYear={setRatioYear}
                                RatioYearOptions={RatioYearOptions}
                                setRatioYearOptions={setRatioYearOptions}
                                customPathname={customPathname}
                                accordionClick={() => setIsRatioOpen(prev => !prev)}
                                open={isRatioOpen}
                            />
                            
                            <GrowthBasedFilter
                                IsCAGR={IsCAGR}
                                setIsCAGR={setIsCAGR}
                                GrowthOptions={GrowthOptions}
                                setGrowthOptions={setGrowthOptions}
                                GrowthYear={GrowthYear}
                                setGrowthYear={setGrowthYear}
                                AndOr={AndOr}
                                setAndOr={setAndOr}
                                customPathname={customPathname}
                                accordionClick={() => setIsGrowthOpen(prev => !prev)}
                                open={isGrowthOpen}
                            />

                            {Iocavailable?.filter(option => option.isChecked)[0]?.name !== 'No' && (
                                <ChargesBasedFilter
                                    customPathname={customPathname}
                                    accordionClick={() => setIsChargeOpen(prev => !prev)}
                                    open={isChargeOpen}
                                    ChargeStateOptions={ChargeStateOptions}
                                    setChargeStateOptions={setChargeStateOptions}
                                    ChargeCityOptions={ChargeCityOptions}
                                    setChargeCityOptions={setChargeCityOptions}
                                    ChargeBasedSelected={ChargeBasedSelected}
                                    setChargeBasedSelected={setChargeBasedSelected}
                                    minChargeAmount={minChargeAmount}
                                    setMinChargeAmount={setMinChargeAmount}
                                    maxChargeAmount={maxChargeAmount}
                                    setMaxChargeAmount={setMaxChargeAmount}
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                    radioValue={radioValue}
                                    setRadioValue={setRadioValue}
                                    onChargeStateChange={onChargeStateChange}
                                />
                            )}
                        </ul>
                    </main>
                    <div className="px-3  py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                        <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                            Reset
                        </button>
                        <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[13px] text-[#FFFFFF] rounded-[3px] font-sans_medium_body leading-[17px] ">
                            Filter
                        </button>
                    </div>
                    {/* reset button */}
                </form>
            </div>
            {showDefinitionModal && <DefinitionModal values={defValues} onClose={defCloseHandler} CFSRefine={true}/>}
        </div>
    );
}

