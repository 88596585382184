import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';
import useScrollIntoView from './useScrollIntoView';


/**
 * The function `DealsInvolvingFilter` is a React component that renders a filter for deals involving
 * certain options, allowing the user to select multiple options.
 * @returns a JSX element.
 */
function DealsInvolvingFilter({ open, accordionClick, DealsInvolvingOptions, setDealsInvolvingOptions, customPathname }) {

    const { DealsInvolving, refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.dealsInvolving?.map(value => value.id) ?? [];
        setDealsInvolvingOptions(DealsInvolving?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [DealsInvolving, customPathname, refineSearchFilters, setDealsInvolvingOptions])



    const handleDealsInvolvingChange = (e) => {
        const { name, checked, id, filteredIds } = e.target;
    
        if (name === "allSelect") {
            let currentDealInvolving = DealsInvolvingOptions.map((deal) =>
                ({ ...deal, isChecked: checked })
            );
            setDealsInvolvingOptions(currentDealInvolving);
        } else if (name === "filteredSelect" && filteredIds) {
            let currentDealInvolving = DealsInvolvingOptions.map((deal) => {
                if (filteredIds.includes(deal.id)) {
                    return { ...deal, isChecked: checked };
                }
                return deal;
            });
            setDealsInvolvingOptions(currentDealInvolving);
        } else {
            let currentDealInvolving = DealsInvolvingOptions.map((deal) =>
                deal.id === parseInt(id) ? { ...deal, isChecked: checked } : deal
            );
            setDealsInvolvingOptions(currentDealInvolving);
        }
    };
    

    const len = DealsInvolvingOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
                ref={listItemRef}
            >
                <FilterWrapper
                    isSelect={false}
                    label={"Deals Involving"}
                    count={len}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleDealsInvolvingChange}
                        primaryEntity={DealsInvolvingOptions}
                    /> : <></>}

                </div>

            </li>

        </div>
    )
}

export default DealsInvolvingFilter