import React, { useState, useEffect } from 'react';
import investor1 from './Images/investor1.png';
import investor2 from './Images/investor2.png';
import investor3 from './Images/investor3.png';
import investor4 from './Images/investor4.png';

const Investors = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [investor1, investor2, investor3, investor4];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 5000);

    return () => clearInterval(slideInterval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const investorPoints = [
    'PE Investor Profile',
    'Portfolio Cos - Investments',
    'Portfolio Cos – Exits',
    'Portfolio Cos - IPO',
    'Funds – raised by the investor',
    'Co-Investors analysis'
  ];

  return (
    <section className="bg-slate-100  min-h-[85vh]  flex items-center" id="investors">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto text-center">
          <h3 className="text-3xl font-bold mt-4 md:mt-10 mb-6 md:mb-10 text-gray-800">
            Investors
          </h3>


          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-12 items-center">
            {/* Content Section */}
            <div className="p-2 flex flex-col items-center lg:items-start">
              <ul className="space-y-4 w-full max-w-md">
                {investorPoints.map((point, index) => (
                  <li key={index} className="flex items-center space-x-3 group">
                    <span className="h-2 w-2 bg-[#bc8b20] rounded-full flex-shrink-0 transition-transform transform group-hover:scale-125" />
                    <p className="text-gray-700 text-base  group-hover:text-[#bc8b20] transition-all">
                      {point}
                    </p>
                  </li>
                ))}
              </ul>
            </div>

            {/* Image Slider Section */}
            <div className="flex items-center justify-center">
              <div className="relative w-full max-w-xl h-[280px] md:h-[400px] flex items-center justify-center">
                <img
                  src={images[currentSlide]}
                  alt={`Investors ${currentSlide + 1}`}
                  className="max-w-full max-h-full object-contain transition-opacity duration-700 ease-in-out"
                />

                {/* Dot Indicators */}
                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-3 pb-4">
                  {images.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => handleDotClick(index)}
                      className={`h-3 w-3 rounded-full transition-all duration-300 cursor-pointer ${
                        index === currentSlide ? 'bg-[#bc8b20] w-6' : 'bg-gray-300 hover:bg-gray-400'
                      }`}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Investors;
