import { React, useState, createContext, useRef, useContext } from 'react'
import moment from "moment";
import { filterExitStatusUrl,filterLocationUrl,refineSearchFundSector, refineSearchDealTypeUrl, refineSearchTypeUrl, filterSubsectorUrl, getRefineSearchDataAPI, getAllCities } from '../Config/config'
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { UserContext } from './UserContext';
import Constants from '../Constants';
import { TableDataContext } from './TableDataContext'
import useModule from "../hooks/useModule";


export const PeFilterContext = createContext('');
/* The above code is exporting an object named `filterLabels` which contains key-value pairs. Each key
represents a filter category and the corresponding value represents the label or display name for
that filter category. This object is likely used in a larger codebase to provide user-friendly
labels for various filter options in a web application or software. */
export const filterLabels = {
  duration: "Duration",
  industry: "Industry",
  sector: "Sector",
  stage: "Stage",
  round: "Round",
  companyType: "Company type",
  financialResultType: "Financial Results Type",
  yearFounded: "Year founded",
  dateFounded: "Date founded",
  dealType: "Deal type",
  type: "Type",
  targetType: "Target type",
  syndication: "Syndication",
  dealsInvolving: "Deals involving",
  capitalSource: "Capital source",
  fundSector: "Sector",
  exitType: "Exit type",
  fundStatus: "Fund status",
  countries: "Countries",
  acquirercountries: "Acquirer Countries",
  region: "Region",
  state: "State",
  city: "City",
  fundtype: "Fund type",
  fundsecondtype: "Fund second type",
  investorType: "Investor type",
  investorHeadquarters: "Investor headquarters",
  targetCompany:"Target Company",
  acquirers:"Acquirers",
  ladvisors:"Legal Advisors",
  tadvisors:"Transaction Advisors",
  investor_city : "Investor City",
  investor_headquaters_city:"Investor Headquarters City",
  dealRange: "Deal range",
  dealSize:"Deal size ",
  dealInvestorRange: "Investor deal range",
  fundRange: "Fund range",
  exitStatus: "Exit status",
  incubationStatus: "Incubation Status",
  followExitStatus: "Follow exit status",
  valuations: "Valuations",
  operator: "Operator",
  deals_operator : "Deals Operator",
  investorSaleInipo: "Investor sale in IPO",
  returnMultiple: "Return multiple",
  vcFunded: "VC funded",
  exited: "Exited",
  excludeDefunctCos: "Exclude defunct COS",
  firmType: "Firm type",
  exitDealTypes: "Exit deal type",
  fundSize: "Fund Size",
  tags: "Tags",
  acquirerCity: "Acquirer City",
  acquirerCountries: "Acquirer Country",
  acquirerType: "Acquirer Company Type",
  acquirerRegion: "Acquirer Region",
  acquirerState: "Acquirer State",
  subSector: "Sub Sector",
  financialFilterRequest: "Financial Based",
  ratiosFilterRequest: "Ratio Based",
  growthFilterRequest: "Growth Based",
  chargeFilterRequest: "Charge Based",
  chargeState: "Charge State",
  chargeCity: "Charge City",
  chargeCompanies: "Charge based companies",
  chargeHolders: "Charge holder name",
  chargeDate: "Charge date",
  chargeAmount: "Charge amount",
  growthYear: "Growth Year",
  growthCAGR: "Growth CAGR",
  growthOperator: "Growth operator",
  followexitStatus:"Follow on Funding Status",
}


/* The above code is defining an initial state object for a refine search feature. It contains various
arrays that will be used to store selected options for different search criteria. Each array
represents a different search filter, such as duration, industry, sector, etc. The initial state
values for all the arrays are empty. */
const refineSearchInitialState = {
  duration: [],//{start:"Jan 98"},{end:"Aug 22"}
  industry: [],//{ id: 1, name: 'Andy', isChecked: true }
  sector: [],
  subSector: [],
  stage: [],
  round: [],
  companyType: [],
  financialResultType: [],
  yearFounded: [],
  dateFounded: [],
  dealType: [],
  type: [],
  targetType: [],
  syndication: [],
  dealsInvolving: [],
  capitalSource: [],
  fundSector: [],
  exitType: [],
  fundStatus: [],
  countries: [],
  acquirercountries: [],
  region: [],
  state: [],
  city: [],
  fundtype: [],
  fundsecondtype: [],
  investorType: [],
  investorHeadquarters: [],
  targetCompany:[] ,
  acquirers:[],
  ladvisors:[],
  tadvisors:[],
  investor_headquaters_city:[],
  investor_city:[],
  dealRange: [],
  investorDealRange: [],
  fundRange: [],
  exitStatus: [],
  incubationStatus: [],
  followExitStatus: [],
  valuations: [],
  operator:[],
  deals_operator:[] ,
  investorSaleInipo: [],
  returnMultiple: [],
  vcFunded: [],
  exited: [],
  excludeDefunctCos: [],
  firmType: [],
  exitDealTypes: [],
  tags: [],
  operator: 0,
};


/**
 * The function `getRange` calculates the range of dates between a start date and an end date, based on
 * a specified type (e.g., days, months, years).
 * @param startDate - The `startDate` parameter is the starting date of the range. It should be a valid
 * date string that can be parsed by the `moment` library.
 * @param endDate - The `endDate` parameter represents the end date of the range you want to generate.
 * @param type - The "type" parameter in the "getRange" function is used to specify the unit of time
 * for which you want to calculate the range. It can be one of the following values:
 * @returns an array of moment objects representing the range of dates between the startDate and
 * endDate, incremented by the specified type.
 */
function getRange(startDate, endDate, type) {
  let fromDate = moment(startDate)
  let toDate = moment(endDate)
  let diff = toDate.diff(fromDate, type)
  let range = []
  for (let i = 0; i <= diff; i++) {
    range.push(moment(startDate).add(i, type))
  }
  return range
}

const initialDealInvolving = [
  // { id: 0, name: "All", isChecked: false, value: "A" },
  { id: 1, name: 'New Investor', isChecked: false, value: "N" },
  { id: 2, name: 'Existing Investor', isChecked: false, value: "E" },

]
const initialCapitalSource = [
  { id: 1, name: 'Domestic', isChecked: false, value: "D" },
  { id: 2, name: 'Foreign', isChecked: false, value: "F" },
  // { id: 3, name: 'Domestic and Foreign', isChecked: false, value: "DF" },

]

const initialFundSector = [
  { id: 1, name: 'Residential', isChecked: false },
  { id: 2, name: 'Commercial', isChecked: false },

]
// const initialExitType = [
//   { id: 1, name: 'Ipo', isChecked: false, value: "I" },
//   { id: 2, name: 'Open Market Transaction', isChecked: false, value: "O" },
//   { id: 3, name: 'Reverse Merger', isChecked: false, value: "R" },

// ]
const initialFundStatus = [

  { id: 1, name: 'Raising', isChecked: false, value: "R" },
  { id: 2, name: 'Closed', isChecked: false, value: "C" },
  // { id: 3, name: 'Domestic and Foreign', isChecked: false, value: "DF" },

]
const initialFollowExitStatus = [

  { id: 1, name: 'Obtained', value: '1' },
  { id: 2, name: 'None', value: '2' },
];
// const initialIncubationStatus = [

//   { id: 1, name: 'Assisted', value: 'A' },
//   { id: 2, name: 'Graduated', value: 'G' },
//   { id: 3, name: 'Incubated', value: 'I' },

// ];
const initialFundType = [
  { id: 1, name: 'PE', isChecked: false, value: "P" },
  { id: 2, name: 'VC', isChecked: false, value: "V" },
  { id: 3, name: 'VC (Seed)', isChecked: false, value: "VS" },
  { id: 3, name: 'PIPE', isChecked: false, value: "PI" },

  { id: 3, name: 'Incubation', isChecked: false, value: "I" },


]
const initialFundSecondType = [
  { id: 1, name: 'Infrastructure', isChecked: false, value: "I" },
  { id: 2, name: 'Tech', isChecked: false, value: "T" },
  { id: 3, name: 'Social', isChecked: false, value: "S" },
  { id: 4, name: 'Internet', isChecked: false, value: "IN" },

  { id: 5, name: 'Healthcare', isChecked: false, value: "H" },

  { id: 6, name: 'Cleantech', isChecked: false, value: "C" },


]
const initialSubSector = [
  { id: 1, name: 'All', value: 'All' },
  { id: 2, name: 'Mail', value: 'Mail' },
  { id: 3, name: 'Developer', value: 'Developer' },
  { id: 4, name: 'Construction', value: 'Construction' }



]

const initialValuation = [
  { id: 1, name: 'Company Valuation', isChecked: false, value: "C" },
  { id: 2, name: 'Revenue Multiple', isChecked: false, value: "R" },
  { id: 3, name: 'EBITDA Multiple', isChecked: false, value: "E" },
  { id: 4, name: 'PAT Multiple', isChecked: false, value: "P" }
]

// const initialType = [
//   { id: 1, name: 'All', value: 'All' },
//   { id: 2, name: 'Entity', value: 'Entity' },
//   { id: 3, name: 'Products', value: 'Products' }
// ];

const initialExitDealType = [
  { id: 1, name: 'Buy Back', isChecked: false },
  { id: 2, name: 'Secondary Sale', isChecked: false },
  { id: 3, name: 'Strategic Sale', isChecked: false },
  { id: 4, name: 'Other', isChecked: false }
];

const initialSyndication = [
  { id: 1, name: 'Both', value: '', isChecked: false },
  { id: 2, name: 'Yes', value: '0', isChecked: false },
  { id: 3, name: 'No', value: '1', isChecked: false }
];

const initialFinancialResultType = [
  { id: 'Both', name: 'Both', value: 'Both' },
  { id: 'Standalone', name: 'Standalone', value: 'Standalone', isChecked: false },
  { id: 'Consolidated', name: 'Consolidated', value: 'Consolidated', isChecked: false }
];

const initialInvestorSale = [
  { id: 0, name: 'Select', value: '' },
  { id: 1, name: 'Yes', value: '1' },
  { id: 0, name: 'No', value: '0' },
];

const initialVcFunded = [
  { id: 0, name: "All", value: "" },
  { id: 1, name: 'Obtained', value: '1' },
  { id: 0, name: 'None', value: '0' },
];

const initialExited = [
  { id: 1, name: "All", value: "" },
  { id: 1, name: 'Exited', value: '1' },
  { id: 0, name: 'Not Exited', value: '0' },
];

// const initialFirmTypes = [
//   { id: 1, name: "All", value: "All" },
//   { id: 2, name: 'Educational Institution', value: 'Educational Institution' },
//   { id: 3, name: 'Private Incubator', value: 'Private Incubator' },
//   { id: 4, name: 'Accelerator', value: 'Accelerator' },
// ];

const initialTransactionStatus = [
  { id: 2, name: "PE-Backed", isChecked: true, value: "pe-backed" },
  { id: 1, name: "Non-PE-Backed", isChecked: true, value: "non-pe-backed" }
]


// const useDidMountEffect = (func, deps) => {
//   const didMount = useRef(false);

//   useEffect(() => {
//     if (didMount.current) func();
//     else didMount.current = true;
//   }, deps);
// }
/**
 * The function generates an array of objects with ids, names, and isChecked properties based on a
 * given range.
 * @param range - The `range` parameter represents the number of options you want to generate. It
 * determines the length of the array that will be created.
 * @returns The function `generateOptions` returns an array of objects. Each object has three
 * properties: `id`, `name`, and `isChecked`. The `id` property is set to the index of the object in
 * the array, the `name` property is set to a string representation of the index, and the `isChecked`
 * property is set to `false`.
 */

const generateOptions = (range) => {
  return [...Array(range).keys()].map(num => ({ id: num, name: `${num}`, isChecked: false }))
}

/* The above code is defining a generator function called "range" in JavaScript. This function takes
three parameters: "start", "end", and "step". */
function* range(start, end, step) {
  let state = start;
  while (state < end) {
    yield state;
    state += step;
  }
  return;
};

/**
 * The function generates an array of objects representing fund size options, with each object
 * containing an id, name, and isChecked property.
 * @param from - The starting value of the range for generating fund size options.
 * @param to - The `to` parameter represents the upper limit of the range of fund sizes.
 * @param step - The `step` parameter is the increment value between each option in the generated
 * array.
 * @returns The function `generateFundSizeOptions` returns an array of objects. Each object has three
 * properties: `id`, `name`, and `isChecked`. The `id` property is set to the value of the `option`
 * parameter, the `name` property is set to a string representation of the `option` value, and the
 * `isChecked` property is set to `false`.
 */
const generateFundSizeOptions = (from, to, step) => {
  return Array.from(range(from, to, step))?.map(option => ({ id: option, name: `${option}`, isChecked: false }))
}

export const ReCompaniesMinDealOptions = generateFundSizeOptions(0, 1050, 50)
export const ReCompaniesMaxDealOptions = generateFundSizeOptions(50, 5050, 50)


const PeFilterProvider = ({ children, props }) => {

  const { primaryMasterId } = useModule();
  const { activeTabDeal, setCurrentPage } = useContext(TableDataContext);
  const loadPreviousStateRef = useRef(false);

  const { logout, getToken, isDirectoryUser } = useContext(UserContext);

  const [refineSearchValues, setRefineSearchValues] = useState(refineSearchInitialState);

  const [refineSearchFilters, setrefineSearchFilters] = useState({})

  const [StartYearFounded, setStartYearFounded] = useState('')

  const [EndYearFounded, setEndYearFounded] = useState('')

  const [StartDateFounded, setStartDateFounded] = useState('')

  const [EndDateFounded, setEndDateFounded] = useState('')

  const [MinDealRange, setMinDealRange] = useState('')

  const [MinInvestortDealRange, setMinInvestortDealRange] = useState('')

  const [MinFundRange, setMinFundRange] = useState('')


  const [MaxDealRange, setMaxDealRange] = useState('')

  const [MaxInvestorDealRange, setMaxInvestorDealRange] = useState('')

  const [MaxFundRange, setMaxFundRange] = useState('')

  const [selectedDealType, setSelectedDealType] = useState()
  const [selectedType, setSelectedType] = useState()
  const [selectedTargetType, setSelectedTargetType] = useState()
  const [selectedSyndication, setSelectedSyndication] = useState()
  const [selectedCompanyType, setSelectedCompanyType] = useState()
  const [selectedFinancialResultType, setSelectedFinancialResultType] = useState()

  const [selectedInvestorType, setSelectedInvestorType] = useState()

  const [selectedInvestorHeadquarters, setSelectedInvestorHeadquarters] = useState()
  const [selectedInvestorHeadquartersCity, setSelectedInvestorHeadquartersCity] = useState()
  const [HeadquarterCityOptions , setHeadquarterCityOptions] = useState([]);
  const [TargetCompanyOptions , setTargetCompanyOptions] = useState([]);
  const [SubSectorOptions, setSubSectorOptions] = useState([]);
  const [InvestorOptions , setInvestorOptions] = useState([]);
  const [AcquirersOptions , setAcquirersOptions] = useState([]);
  const [lAdvisorsOptions,setlAdvisorsOptions] = useState([]);
  const [tAdvisorsOptions,settAdvisorsOptions] = useState([]);
  const [fundsOptions,setFundsOptions] = useState([]);
  const [fundInvestorsOptions,setFundInvestorsOptions] = useState([]);



  const [industryFilterResponseData, setIndustryFilterResponseData] = useState({})
  const [industryFilterList, setIndustryFilterList] = useState([]);
  const [Industries, setIndustries] = useState([]);

  const [sectorFilterList, setSectorFilterList] = useState([]);
  const [Sectors, setSectors] = useState([])
  const [targetGroup, setTargetGroup] = useState([]);
  const [acquirerGroup, setAcquirerGroup] = useState([]);

  // const [subSectorFilterList, setSubSectorFilterList] = useState([])
  const [SubSectors, setSubSectors] = useState([])
  const [selectedTargetCompany , setSelectedTargetCompany] = useState()

  const [selectedAcquirers , setSelectedAcquirers] = useState()
  const [selectedlAdvisors , setSelectedlAdvisors] = useState()
  const [selectedtAdvisors , setSelectedtAdvisors] = useState()


  const [stageFilterList, setStageFilterList] = useState([]);
  const [Stages, setStages] = useState([])

  const [roundsFilterList, setRoundsFilterList] = useState([]);
  const [Rounds, setRounds] = useState([])

  const [YearFounded, setYearFounded] = useState([])
  const [DateFounded, setDateFounded] = useState([])

  const [dealTypeFilterResponse, setDealTypeFilterResponse] = useState({})
  const [typeFilterResponse, setTypeFilterResponse] = useState({})
  const [targetTypeFilterResponse, setTargetTypeFilterResponse] = useState({})

  const [dealTypeFilter, setDealTypeFilter] = useState([])
  const [typeFilter, setTypeFilter] = useState([])
  const [targetTypeFilter, settargetTypeFilter] = useState([])
  const [DealTypes, setDealTypes] = useState([])
  const [Types, setTypes] = useState([])
  const [TargetTypes, setTargetTypes] = useState([])

  const [Syndications, setSyndications] = useState(initialSyndication)

  const [companyTypeFilterList, setCompanyTypeFilterList] = useState();
  const [companyType, setCompanyType] = useState([])
  const [FinancialResultType, setFinancialResultType] = useState(initialFinancialResultType)

  const [DealsInvolving, setDealsInvolving] = useState(initialDealInvolving)
  const [CapitalSource, setCapitalSource] = useState([])
  const [FundSector, setFundSector] = useState([])

  const [exitTypeFilterList, setExitTypeFilterList] = useState([]);
  const [ExitType, setExitType] = useState([])
  const [FundStatus, setFundStatus] = useState([])

  const [incubationStatusList, setIncubationStatusList] = useState([])
  const [IncubationStatus, setIncubationStatus] = useState([])

  const [FundType, setFundType] = useState(initialFundType)
  const [FundSecondType, setFundSecondType] = useState(initialFundSecondType)
  const [FollowExitStatus, setFollowExitStatus] = useState(initialFollowExitStatus)

  const [countries, setCountries] = useState([]);
  const [acquirercountries, setAcquirerCountries] = useState([]);
  const [Regions, setRegions] = useState([])
  const [States, setStates] = useState([])
  const [Cities, setCities] = useState([])
  const [iocPayload, setIocPayload] = useState({})

  // const [investortypeFilterList, setInvestortypeFilterList] = useState([]);
  const [InvestorType, setInvestorType] = useState([])

  // const [investorHeadQuarterFilterList, setInvestorHeadQuarterFilterList] = useState([]);
  const [InvestorHeadquarters, SetInvestorHeadquarters] = useState();

  const [exitSatusFilterList, setExitSatusFilterList] = useState([]);
  const [exitStatusResponseData, setExitStatusResponseData] = useState({});
  const [ExitStatus, setExitStatus] = useState([])
  const [Valuations, setValuations] = useState(initialValuation)

  //Investor sale IPO filter values
  const [InvestorSaleIPO, setInvestorSaleIPO] = useState(initialInvestorSale);
  const [selectedInvestorSaleIPO, setSelectedInvestorSaleIPO] = useState(null);

  //Return multiple varaibles
  const [MinReturn, setMinReturn] = useState('')
  const [MaxReturn, setMaxReturn] = useState('')

  //VC funded filter varaiables
  const [VcFunded, setVcFunded] = useState(initialVcFunded);
  const [selectedVcFunded, setSelectedVcFunded] = useState(null);

  //Exited filter varaiables
  const [Exited, setExited] = useState(initialExited);
  const [selectedExited, setSelectedExited] = useState(null);

  //Firm Type filter varaiables
  const [firmTypeResponseData, setFirmTypeResponseData] = useState({})
  const [FirmType, setFirmType] = useState([]);
  const [FirmTypeFilterList, setFirmTypeFilterList] = useState([])
  const [selectedFirmType, setSelectedFirmType] = useState(null);

  //Exclude Defunct variables
  const [ExcludeDefunctCos, setExcludeDefunctCos] = useState(false);

  //Exit Deal type variables
  const [ExitDealType, setExitDealType] = useState(initialExitDealType);
  const [ReExitType, setReExitType] = useState(initialExitDealType);

  const [tags, setTags] = useState([]);

  const [operator, setOperator] = useState(true);

  const [InvestoDealRange, setInvestoDealRange] = useState([])
  const [InvestoMaxDealRange, setInvestoMaxDealRange] = useState([])

  const [subSectorResponseData, setsubSectorResponseData] = useState({})

  const [open, setOpen] = useState(true);

  // console.log(activeTabDeal, "tabbb")

  const [monthPickerValue, setMonthPickerValue] = useState([moment().subtract(12, 'months'), moment().startOf('second')])
  const [advSearchmonthPickerValue, setAdvSearchMonthPickerValue] = useState([moment().subtract(12, 'months'), moment().startOf('second')]);
  const [isMonthPickerChanged, setIsMonthPickerChanged] = useState(0)
  const [isTagClosed, setIsTagClosed] = useState(0)
  const [showMonthPicker, setShowMontnPicker] = useState(0)
  const [refreshFilterCount, incrementRefreshCount] = useState(0);
  const [applyFilterCount, setApplyFilterCount] = useState(0);
  const [FundsStage, setFundsStage] = useState([]);
  const [FundsIndustry, setFundsIndustry] = useState([])
  const [MinFundSize, setMinFundSize] = useState([])
  const [MaxFundSize, setMaxFundSize] = useState([])
  const [MinAcquirerDealRange, setMinAcquirerDealRange] = useState([])
  const [MaxAcquirerDealRange, setMaxAcquirerDealRange] = useState([])
  const [FinancialBasedSelected, setFinancialBasedSelected] = useState([]);
  const [RatioBasedSelected, setRatioBasedSelected] = useState([]);
  const [GrowthBasedSelected, setGrowthBasedSelected] = useState([]);
  const [ChargeBasedSelected, setChargeBasedSelected] = useState([]);
  const [TransactionStatus, setTransactionStatus] = useState(initialTransactionStatus)
  const [groupId, setGroupId] = useState()
  const [isNotification, setIsNotification] = useState()
  const [dealsView, setDealsView] = useState(isDirectoryUser);
  const [switchValue, setSwitchValue] = useState(false);
  const [SwitchState, setSwitchState] = useState("and")
  const [AllCities,setAllCities]=useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [ dbDropdown , setDbDropdown] = useState(true);
  const [ isMobile , setIsMobile] = useState(false);

  const [toggleDealsBtn , setToggleDealsBtn] = useState(true);

  const [Iocavailable, SetIocavailable] = useState([])
  
  // FAQ 



  const location = useLocation();

  const onErrorHandler = (error) => {
    if (error?.response?.status === Constants.sessionTerminatedStatus) {
      logout()
    }
  }




  const resetFilter = (showToast = true) => {
    setRefineSearchValues(refineSearchInitialState)
    setIndustries(industryFilterList)
    setSectors([])
    setSubSectors(initialSubSector)
    setStages(stageFilterList);
    setRounds(roundsFilterList);
    setDealsInvolving(initialDealInvolving);
    setCapitalSource(initialCapitalSource);
    setFundSector(initialFundSector)
    setIncubationStatus(incubationStatusList);
    setExitType(exitTypeFilterList);
    setFundStatus(initialFundStatus);
    setFundType(initialFundType);
    setFundSecondType(initialFundSecondType)
    setExitStatus(exitSatusFilterList);
    setFollowExitStatus(initialFollowExitStatus);
    setValuations(initialValuation);
    setMaxDealRange("");
    setMinDealRange("");
    setStartYearFounded("");
    setEndYearFounded("");
    setMaxInvestorDealRange("");
    setMinInvestortDealRange("");
    setMaxFundRange("");
    setMinFundRange("");
    setDealTypes(dealTypeFilter)
    setTypes(typeFilter)
    setTargetTypes(targetTypeFilter)
    setSyndications(initialSyndication);
    setFinancialResultType(initialFinancialResultType);
    setCountries(prev => prev.map(data => ({ ...data, isChecked: false })))
    setAcquirerCountries(prev => prev.map(data => ({ ...data, isChecked: false })))
    setRegions(prev => prev.map(data => ({ ...data, isChecked: false })))
    setStates(prev => prev.map(data => ({ ...data, isChecked: false })))
    setCities(prev => prev.map(data => ({ ...data, isChecked: false })))
    setSelectedDealType(null);
    setSelectedType(null)
    setSelectedTargetType(null);
    setSelectedSyndication(null)
    setSelectedCompanyType(null);
    setSelectedTargetCompany(null);
    setSelectedAcquirers(null);
    setAcquirersOptions([]);
    setSelectedFinancialResultType(null);
    setSelectedInvestorHeadquarters(null);
    setSelectedInvestorHeadquartersCity(null);
    setSelectedInvestorType(null);
    setSelectedInvestorSaleIPO(null);
    setHeadquarterCityOptions([]);
    setTargetCompanyOptions([]);
    setSubSectorOptions([]);
    settAdvisorsOptions([]);
    setlAdvisorsOptions([]);
    setFundsOptions([]);
    setFundInvestorsOptions([]);
    setInvestorOptions([]);
    setSectors([]);
    setSelectedVcFunded(null);
    setSelectedExited(null);
    setSelectedFirmType(null);
    setExitDealType(prev => prev.map(data => ({ ...data, isChecked: false })))
    setMinReturn("");
    setMaxReturn("");
    setExcludeDefunctCos(false);
    setTags([]);
    if (showToast) {
      toast.success("Filter has been removed!")
    }
  }

  const resetDealsByFilter = ()=>{
    setTargetCompanyOptions([]);
    setSubSectorOptions([]);
    settAdvisorsOptions([]);
    setlAdvisorsOptions([]);
    setFundsOptions([]);
    setFundInvestorsOptions([]);
    setInvestorOptions([]);
    setSectors([]);
    setAcquirersOptions([]);
  }

  const applyFilter = () => {
    const selectedIndustries = Industries.filter(industry => industry.isChecked);
    const selectedFirmtypes = FirmType.filter(industry => industry.isChecked);
    const selectdSectors = Sectors.filter(sector => sector.isChecked);
    // const selectedSubSectors = SubSectors.filter(subSector => subSector.isChecked);
    const selectedStages = Stages.filter(stage => stage.isChecked);
    const selectedRounds = Rounds.filter(round => round.isChecked);
    const selectedDealInvolving = DealsInvolving.filter(deal => deal.isChecked);
    const selectedCapitalSource = CapitalSource.filter(capital => capital.isChecked);
    const selectedFundSector = FundSector.filter(fund => fund.isChecked);
    const selectedExitType = ExitType.filter(exitstype => exitstype.isChecked);

    const selectedFollowExitStatus = FollowExitStatus.filter(followstatus => followstatus.isChecked);
    const selectedFundStatus = FundStatus.filter(fundstatus => fundstatus.isChecked);
    const selectedIncubationStatus = IncubationStatus.filter(incubationstatus => incubationstatus.isChecked);

    const selectedFundType = FundType.filter(fundtype => fundtype.isChecked);
    const selectedFundSecondType = FundSecondType.filter(fundsecondtype => fundsecondtype.isChecked);

    const selectedExitStatus = ExitStatus.filter(status => status.isChecked);

    const selectedValuations = Valuations.filter(valuation => valuation.isChecked);
    const selectedCountries = countries.filter(country => country.isChecked);
    const selectedAcquirerCountries = acquirercountries.filter(acquirercountry => acquirercountry.isChecked);
    const selectedRegion = Regions.filter(region => region.isChecked);
    const selectedStates = States.filter(state => state.isChecked);
    const selectedCities = Cities.filter(city => city.isChecked);
    const selectedExitDealTypes = ExitDealType.filter(type => type.isChecked);
    setRefineSearchValues(prev => ({
      ...prev,
      industry: selectedIndustries,
      sector: selectdSectors,
      stage: selectedStages,
      // subSector: selectedSubSectors,
      round: selectedRounds,
      yearFounded: StartYearFounded !== "" && EndYearFounded !== "" ? [{ id: 1, name: StartYearFounded }, { id: 1, name: EndYearFounded }] : [],
      dateFounded: StartDateFounded !== "" && EndDateFounded !== "" ? [{ id: 1, name: StartDateFounded }, { id: 1, name: EndDateFounded }] : [],

      dealType: selectedDealType !== null && selectedDealType !== undefined ? [selectedDealType] : [],
      type: selectedType !== null && selectedType !== undefined ? [selectedType] : [],
      targetType: selectedTargetType !== null && selectedTargetType !== undefined ? [selectedTargetType] : [],
      syndication: selectedSyndication !== null && selectedSyndication !== undefined ? [selectedSyndication] : [],
      companyType: selectedCompanyType !== null && selectedCompanyType !== undefined ? [selectedCompanyType] : [],
      financialResultType: selectedFinancialResultType !== null && selectedFinancialResultType !== undefined ? [selectedFinancialResultType] : [],
      dealsInvolving: selectedDealInvolving,
      capitalSource: selectedCapitalSource,
      fundSector: selectedFundSector,
      exitType: selectedExitType,
      incubationStatus: selectedIncubationStatus,
      fundStatus: selectedFundStatus,
      fundsecondtype: selectedFundSecondType,
      fundType: selectedFundType,
      exitStatus: selectedExitStatus,
      followexitStatus: selectedFollowExitStatus,
      investorHeadquarters: selectedInvestorHeadquarters !== null && selectedInvestorHeadquarters !== undefined ? [selectedInvestorHeadquarters] : [],
      investor_city: HeadquarterCityOptions.filter(city => city.isChecked),
      investor_headquaters_city :HeadquarterCityOptions.filter(city => city.isChecked) ,
      investorType: selectedInvestorType !== null && selectedInvestorType !== undefined ? [selectedInvestorType] : [],
      valuations: selectedValuations,
      operator : SwitchState,
      deals_operator : SwitchState ,
      target_company:TargetCompanyOptions.filter(company => company.isChecked),
      subSector:SubSectorOptions.filter(company => company.isChecked),
      investor_id : InvestorOptions.filter(investor => investor.isChecked),
      acquirers:AcquirersOptions.filter(acquirer=> acquirer.isChecked),
      ladvisors:lAdvisorsOptions.filter(advisor=> advisor.isChecked) ,
      tadvisors:tAdvisorsOptions.filter(advisor=> advisor.isChecked) ,
      funds:fundsOptions.filter(fund=>fund.isChecked),
      investors:fundInvestorsOptions.filter(investor=>investor.isChecked),
      dealRange: MinDealRange !== '' && MaxDealRange !== '' ? [{ id: 1, name: MinDealRange }, { id: 2, name: MaxDealRange }] : [],
      investorDealRange: MinInvestortDealRange !== '' && MaxInvestorDealRange !== '' ? [{ id: 1, name: MinInvestortDealRange }, { id: 2, name: MaxInvestorDealRange }] : [],
      fundRange: MinFundRange !== '' && MaxFundRange !== '' ? [{ id: 1, name: MinFundRange }, { id: 2, name: MaxFundRange }] : [],
      city: selectedCities,
      region: selectedRegion,
      state: selectedStates,
      acquirercountries: selectedAcquirerCountries,
      countries: selectedCountries,
      investorSaleInipo: selectedInvestorSaleIPO !== null ? [selectedInvestorSaleIPO] : [],
      returnMultiple: MinReturn !== '' && MaxReturn !== '' ? [{ id: 1, name: MinReturn }, { id: 2, name: MaxReturn }] : [],
      vcFunded: selectedVcFunded !== null ? [selectedVcFunded] : [],
      exited: selectedExited !== null ? [selectedExited] : [],
      excludeDefunctCos: ExcludeDefunctCos ? [{ id: 1, name: "Exclude Defunct Cos", value: ExcludeDefunctCos }] : [],
      firmType: selectedFirmtypes,
      exitDealTypes: selectedExitDealTypes,
      tags: tags,
      operator: operator ? 1 : 0
    }))
    toast.success("Filter applied")
  }

  /**
   * The function `removeFilter` is used to remove a filter from a set of search filters in a
   * JavaScript application.
   * @param key - The key parameter represents the filter category that needs to be removed. It can be
   * any string value that identifies the specific filter category.
   * @param id - The id parameter represents the unique identifier of the filter that needs to be
   * removed.
   * @param removeAll - A boolean value indicating whether to remove all filters or just the filter
   * with the specified id.
   * @param option - The `option` parameter is not used in the `removeFilter` function. It is not
   * necessary for the function's functionality.
   * @returns nothing (undefined).
   */
  const removeFilter = (key, id, removeAll, option) => {
    setrefineSearchFilters(prev => ({
      ...prev,
      [location.pathname]: {
          ...prev[location.pathname],
          [key]: removeAll ? [] : prev[location.pathname][key].filter(data => data.id !== id)
      }
    }));
if(location.pathname.includes('/ioc')) {

  setIocPayload(prevPayload => {
    const updatedPayload = { ...prevPayload };

    switch (key) {
      case "chargeCompanies":
        updatedPayload.company = updatedPayload.company.filter(company => company.id !== id);
        break;
      
      case "chargeHolders":
        updatedPayload.charge_holder_name = updatedPayload.charge_holder_name.filter(holder => holder.id !== id);
        break;
      
      case "chargeState":
        updatedPayload.state = updatedPayload.state.filter(state => state.id !== id);
        break;

      case "chargeCity":
        updatedPayload.city = updatedPayload.city.filter(city => city.id !== id);
        break;
      
      case "chargeDate":
        if (id === 0) {
          updatedPayload.charge_date = {};
        }
        break;

      case "chargeAmount":
        if (id === 0) {
          updatedPayload.charge_amount = {};
        }
        break;

      default:
        break;
    }

    // Check if all fields are empty to reset the payload to an empty object
    const isEmptyArray = arr => !arr || arr.length === 0;
    const isEmptyObject = obj => !obj || Object.keys(obj).length === 0;

    if (
      isEmptyArray(updatedPayload.company) &&
      isEmptyArray(updatedPayload.charge_holder_name) &&
      isEmptyObject(updatedPayload.charge_date) &&
      isEmptyObject(updatedPayload.charge_amount) &&
      isEmptyArray(updatedPayload.state) &&
      isEmptyArray(updatedPayload.city)
    ) {
      return {};
    }

    return updatedPayload;
  });
}
    // console.log(HeadquarterCityOptions ,"ghg" , "wooo")

    // console.log(key, id,"uykuy",removeAll, option,iocPayload)

    setTargetCompanyOptions(
      prevTargetGroup => {
        return prevTargetGroup.map(tag => {
          if (tag.id === id) {
            return { ...tag, isChecked: false }; 
          }
          return tag;
        });
      }
    )
    
    setSubSectorOptions(
      prevTargetGroup => {
        return prevTargetGroup.map(tag => {
          if (tag.id === id) {
            return { ...tag, isChecked: false }; 
          }
          return tag;
        });
      }
    )

    setInvestorOptions(
      prevTargetGroup => {
        return prevTargetGroup.map(tag => {
          if (tag.id === id) {
            return { ...tag, isChecked: false }; 
          }
          return tag;
        });
      }
    )

    setAcquirersOptions(
      prevTargetGroup => {
        return prevTargetGroup.map(tag => {
          if (tag.id === id) {
            return { ...tag, isChecked: false }; 
          }
          return tag;
        });
      }
    )

    setlAdvisorsOptions(
      prevTargetGroup => {
        return prevTargetGroup.map(tag => {
          if (tag.id === id) {
            return { ...tag, isChecked: false }; 
          }
          return tag;
        });
      }
    )

    setFundsOptions(
      prevTargetGroup => {
        return prevTargetGroup.map(tag => {
          if (tag.id === id) {
            return { ...tag, isChecked: false }; 
          }
          return tag;
        });
      }
    )

    setFundInvestorsOptions(
      prevTargetGroup => {
        return prevTargetGroup.map(tag => {
          if (tag.id === id) {
            return { ...tag, isChecked: false }; 
          }
          return tag;
        });
      }
    )

    settAdvisorsOptions(
      prevTargetGroup => {
        return prevTargetGroup.map(tag => {
          if (tag.id === id) {
            return { ...tag, isChecked: false }; 
          }
          return tag;
        });
      }
    )

    setHeadquarterCityOptions(
      prevTargetGroup => {
        return prevTargetGroup.map(tag => {
          if (tag.id === id) {
            return { ...tag, isChecked: false }; 
          }
          return tag;
        });
      }
    )

    setAcquirerGroup(
      prevTargetGroup => {
        return prevTargetGroup.map(tag => {
          if (tag.id === id) {
            return { ...tag, isChecked: false }; 
          }
          return tag;
        });
      }
    )
    setToggleDealsBtn(true);

    setTargetGroup(prevTargetGroup => {
      return prevTargetGroup.map(tag => {
        if (tag.id === id) {
          return { ...tag, isChecked: false }; 
        }
        return tag;
      });
    });
    if (key === "globalSearch") {
      setMonthPickerValue(Constants.initialMonthRange)
    }
    setCurrentPage(1);
    return;
    

  }
  const checkToggleBtn = () => {
    const lAdvisorsChecked = lAdvisorsOptions?.some(option => option.isChecked);
    const tAdvisorsChecked = tAdvisorsOptions?.some(option => option.isChecked);
    const targetCompanyChecked = TargetCompanyOptions?.some(option => option.isChecked);
    const investorChecked = InvestorOptions?.some(option => option.isChecked);
    console.log("working in checkToggleBtn")
    if (lAdvisorsChecked || tAdvisorsChecked || targetCompanyChecked || investorChecked) {
        setToggleDealsBtn(false);
    } else {
        setToggleDealsBtn(true);
    }
};

  /**
   * The function `cfsRemoveFilter` removes a selected filter from the refineSearchFilters object based
   * on the provided key and selectedKey values.
   * @param key - The `key` parameter is a string that represents the type of filter being removed. It
   * can have one of the following values: 'growthFilterRequest', 'financialFilterRequest', or
   * 'ratiosFilterRequest'.
   * @param selectedKey - The `selectedKey` parameter is a string that represents the key of the filter
   * that needs to be removed.
   * @returns Nothing is being returned. The function ends with a return statement that does not
   * specify a value to return.
   */
  const cfsRemoveFilter = (key, selectedKey) => {
    if (key !== 'growthFilterRequest') {
      setrefineSearchFilters(prev => ({
        ...prev,
        [location.pathname]: {
          ...prev[location.pathname],
          [key]: ({
            ...prev[location.pathname][key],
            [(selectedKey !== 'year_match_type' && selectedKey !== 'unit') ? selectedKey + '_min' : selectedKey]: null,
            [(selectedKey !== 'year_match_type' && selectedKey !== 'unit') ? selectedKey + '_max' : selectedKey]: null
          })

        }
      }
      ))
    }
    else {
      setrefineSearchFilters(prev => ({
        ...prev,
        [location.pathname]: {
          ...prev[location.pathname],
          [key]: ({
            ...prev[location.pathname][key],
            [selectedKey]: (selectedKey === 'cagr' ? false : '')
          })

        }
      }
      ))
    }
    if (key === 'financialFilterRequest') {
      setFinancialBasedSelected(FinancialBasedSelected.filter((item) => item !== selectedKey))
    }
    if (key === 'ratiosFilterRequest') {
      setRatioBasedSelected(RatioBasedSelected.filter((item) => item !== selectedKey))
    }
    if (key === 'growthFilterRequest') {
      setGrowthBasedSelected(GrowthBasedSelected.filter((item) => item !== selectedKey))
    }

    return;

  }

  /**
   * The function `applyFilterFrom` takes an object as input and applies various filters based on the
   * keys and values of the object.
   * @param obj - The `obj` parameter is an object that contains various filter options. Each key in
   * the object represents a specific filter option, and the corresponding value represents the
   * selected value for that filter option. The function `applyFilterFrom` applies these filter options
   * to update the state of different variables in the code.
   */
  const applyFilterFrom = async (obj) => {
    // console.log("applyFilterFromapplyFilterFrom", obj);
    const keys = Object.keys(obj);
    for (const key of keys) {
      if (key === "industry") {
        setIndustries(prev => prev.map(data => ({ ...data, isChecked: data.id === obj[key] })))
      } else if (key === "sector") {
        // await getSectorsByIndustries(obj['industry'], obj[key])
      } else if (key === "stage") {
        setStages(prev => prev.map(data => ({ ...data, isChecked: data.id === obj[key] })))
      } else if (key === "round") {
        setRounds(prev => prev.map(data => ({ ...data, isChecked: data.id === obj[key] })))
      } else if (key === "year_founded") {
        setStartYearFounded(obj[key].from);
        setEndYearFounded(obj[key].to);
      } else if (key === "date_founded") {
        setStartDateFounded(obj[key].from);
        setEndDateFounded(obj[key].to);
      }
      else if (key === "companyType") {
        setSelectedCompanyType(companyType.find(data => data.value === obj[key]));
      }
      else if (key === "financialType") {
        setSelectedFinancialResultType(FinancialResultType.find(data => data.value === obj[key]));
      }
      else if (key === "deals_involving") {
        setDealsInvolving(prev => prev.map(data => ({ ...data, isChecked: obj[key].includes(data.value) })))
      } else if (key === "capital_source") {
        setCapitalSource(prev => prev.map(data => ({ ...data, isChecked: obj[key].includes(data.value) })))

      }
      else if (key === "sector_type") {
        setFundSector(prev => prev.map(data => ({ ...data, isChecked: obj[key].includes(data.value) })))

      }
      else if (key === "sub_sector") {
        setSubSectors(prev => prev.map(data => ({ ...data, isChecked: obj[key].includes(data.value) })))

      }
      else if (key === "exit_type") {
        setExitType(prev => prev.map(data => ({ ...data, isChecked: obj[key].includes(data.value) })))

      }
      else if (key === "incubation_status") {
        setIncubationStatus(prev => prev.map(data => ({ ...data, isChecked: obj[key].includes(data.value) })))

      }
      else if (key === "fund_status") {
        setFundStatus(prev => prev.map(data => ({ ...data, isChecked: obj[key].includes(data.value) })))

      } else if (key === "fundsecond_type") {
        setFundSecondType(prev => prev.map(data => ({ ...data, isChecked: obj[key].includes(data.value) })))
      }
      else if (key === "fund_type") {
        setFundType(prev => prev.map(data => ({ ...data, isChecked: obj[key].includes(data.value) })))
      }
      else if (key === "syndication") {
        setSelectedSyndication(Syndications.find(data => obj[key].includes(data.value)))
      } else if (key === "investor_type") {
        setSelectedInvestorType(InvestorType.find(data => obj[key].includes(data.id)))
      } else if (key === "exit_status") {
        setExitStatus(prev => prev.map(data => ({ ...data, isChecked: data.id === obj[key] })))
      }
      else if (key === "followexit_status") {
        setFollowExitStatus(prev => prev.map(data => ({ ...data, isChecked: data.id === obj[key] })))
      } else if (key === "deal_range") {
        setMaxDealRange(obj[key]?.from ?? "")
        setMinDealRange(obj[key]?.to ?? "")
      } else if (key === "investordeal_range") {
        setMaxInvestorDealRange(obj[key]?.from ?? "")
        setMinInvestortDealRange(obj[key]?.to ?? "")
      } else if (key === "fund_range") {
        setMaxFundRange(obj[key]?.from ?? "")
        setMinFundRange(obj[key]?.to ?? "")
      }
    }
    await getCountryFilters({
      initialSelectedAcquirerCountries: obj.acquirercountry,
      initialSelectedCountries: obj.country,
      initialSelectedRegion: obj.region,
      initialSelectedStates: obj.state,
      initialSelectedCities: obj.city
      // initialSelectedType: obj.ftype
    })
    setApplyFilterCount(prev => prev + 1);
  }

  /* The above code is using the useEffect hook in React to execute the applyFilter function whenever
  the applyFilterCount variable changes. The applyFilter function is only called if the
  applyFilterCount is not equal to 0. */
  useEffect(() => {
    if (applyFilterCount !== 0) {
      applyFilter();
    }
  }, [applyFilterCount])


  // const getSectorsByIndustries = async (selectedIndustriesIds, initialSelectedSectors) => {
  //   const response = await axios.post(filterSectorByIndutry, {
  //     "industry": selectedIndustriesIds
  //   }, {
  //     headers: {
  //       "Authorization": getToken()
  //     }
  //   })
  //   if (response.status === 200) {
  //     let sectors = [];
  //     response?.data?.data?.forEach(industry => {
  //       sectors = [...sectors, ...industry.sectors]
  //     });
  //     setSectorFilterList(sectors)
  //     if (initialSelectedSectors != null) {
  //       sectors = sectors?.map(data => ({ ...data, isChecked: initialSelectedSectors.includes(data.id) }))
  //       // setRefineSearchValues(prev => ({ ...prev, sector: sectors.filter(data => data.isChecked) }))
  //     }
  //     setSectors(sectors)

  //   } else {
  //     setSectorFilterList([])
  //     setSectors([])
  //   }
  // }
/**
 * The function `getCountryFilters` retrieves country, region, state, and city filters from an API and
 * sets the initial selected values for each filter.
 */

  const getCountryFilters = async ({
    // selectedCountries,
    // selectedRegion,
    // selectedStates,
    // selectedCities,
    // initialSelectedAcquirercountries,
    initialSelectedCountries,
    initialSelectedRegion,
    initialSelectedStates,
    initialSelectedCities,

  }) => {
    //   const selectedCountries = countries.filter(country => country.isChecked).map(country => country.id);
    //   const selectedRegion = Regions.filter(region => region.isChecked).map(region => region.id);
    //   const selectedStates = States.filter(state => state.isChecked).map(state => state.id);

    const response = await axios.post(filterLocationUrl, {
      country: [],
      region: [],
      state: [],
      city: [],
    }, {
      headers: {
        "Authorization": getToken()
      }
    });

    if (response.status === 200) {
      const countries = response.data?.countries ?? [];
      const regions = response.data?.region ?? [];
      const states = response.data?.state ?? [];
      const cities = response.data?.city ?? [];

      if (initialSelectedCountries != null) {
        setCountries(countries.map(data => ({ ...data, isChecked: initialSelectedCountries.includes(data.id) })))
        setRegions(regions.map(data => ({ ...data, isChecked: initialSelectedRegion.includes(data.id) })))
        setStates(states.map(data => ({ ...data, isChecked: initialSelectedStates.includes(data.id) })))
        setCities(cities.map(data => ({ ...data, isChecked: initialSelectedCities.includes(data.id) })))

      }
    }
  }




 /* The above code is using the useEffect hook in React to perform various API calls and set the
 response data in state variables. It is fetching data for different filters like industry, rounds,
 status, stage, location, investor type, investor headquarters, firm type, exit type, stage type,
 industry type, capital source, deal type, company type, incubation status, and more. It is also
 generating options for different dropdowns based on the fetched data. */
  useEffect(() => {

    let dealRangeOptions = generateOptions(11)
    let maxDealrangeOptions = generateOptions(2001)?.slice(5);
    setInvestoDealRange(dealRangeOptions)
    setInvestoMaxDealRange(maxDealrangeOptions)

    let fundMinOptions = generateFundSizeOptions(0, 1005, 5)
    let fundMaxOptions = generateFundSizeOptions(5, 2005, 5)
    setMinFundSize(fundMinOptions)
    setMaxFundSize(fundMaxOptions)

    let acquirerMinDealRange1 = generateFundSizeOptions(5, 105, 5)
    let acquirerMinDealRange2 = generateFundSizeOptions(150, 1050, 50)
    let acquirerMinDealRange3 = generateFundSizeOptions(2000, 11000, 1000)
    setMinAcquirerDealRange([
      {
        id: 0,
        name: "0",
        isChecked: false,
      },
      {
      id: 1,
      name: "1",
      isChecked: false,
    },
    ...acquirerMinDealRange1,
    ...acquirerMinDealRange2,
    ...acquirerMinDealRange3])

    let acquirerMaxDealRange1 = generateFundSizeOptions(20000, 60000, 10000)
    setMaxAcquirerDealRange([...acquirerMinDealRange1, ...acquirerMinDealRange2, ...acquirerMinDealRange3, ...acquirerMaxDealRange1])

    let range = getRange('1920-01-01', moment().format('YYYY-MM-DD'), 'years')
    const formatedYeadFounded = range.map((date, index) => ({ id: index + 1, name: date.year(), isChecked: false }))
    setYearFounded(formatedYeadFounded)

    //Set induty filter list
    axios.post(getRefineSearchDataAPI, {
      "keys": [
        "industry",
        "rounds",
        "status",
        "stage",
        // "sector",
        "location",
        "investorType",
        "investorHeadquarter",
        "investorHeadquarterCity",
        "firmType",
        "exitType",
        "stageType",
        "industryType",
        "capitalSource",
        "exitstatus",
        "dealType",
        "companyType",
        "incubationStatus"
      ]
    },
      {
        headers: {
          "Authorization": getToken()
        }
      }).then((response) => {
        console.log(response.data, 'resss');
        setIndustryFilterResponseData(response.data?.industry)
        setStages(response.data?.stage)
        setRounds(response.data?.rounds)
        setInvestorType(response.data?.investorType)
        SetInvestorHeadquarters(response.data?.investorHeadquarter)
        setFirmTypeResponseData(response.data?.firmType)
        setExitType(response.data?.exitType)
        setFundStatus(response.data?.status?.statusList ?? [])
        setFundsStage(response.data?.stageType)
        setFundsIndustry(response.data?.industryType)
        setCapitalSource(response.data?.capitalSource)
        // setFundSector(response.data?.sector)
        // setExitStatusResponseData(response.data);
        // setDealTypeFilterResponse(response.data?.dealType)
        setCompanyTypeFilterList(response.data?.companyType ?? [])
        setIncubationStatus(response.data?.incubationStatus ?? [])
        setCountries(response.data?.location?.countries)
        setRegions(response.data?.location?.region)
        setStates(response.data?.location?.state)
        setHeadquarterCityOptions(response.data?.location?.city)
        setCities(response.data?.location?.city)
      }).catch(onErrorHandler)

    axios.post(getAllCities).then((response)=>{
      console.log(response?.data?.city,"hgmhgj")
      setAllCities(response?.data?.city);
    }).catch(onErrorHandler)

    // //Set induty filter list
    // axios.post(filterIndustryUrl, {
    //   "industry": "All",
    //   "type": primaryMasterId
    // },
    //   {
    //     headers: {
    //       "Authorization": getToken()
    //     }
    //   }).then((response) => {
    //     if (response.status === 200) {
    //       setIndustryFilterList(response.data)
    //       setIndustries(response.data)
    //     }
    //   }).catch(onErrorHandler)

    // Set fund status filter list
    // axios.post(refineSearchFundStatus, {
    //   "statusId": "All",
    //   "closeStatusId": ""
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then((response) => {
    //   if (response.status === 200) {
    //     setFundStatus(response.data?.statusList ?? [])
    //   }
    // }).catch(onErrorHandler)

    //Set first type in funds filter 
    // axios.post(refineSearchStageType, {
    //   "stageId": "All"
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then((response) => {
    //   if (response.status === 200) {
    //     setFundsStage(response.data?.stageType)
    //   }
    // }).catch(onErrorHandler)

    //Set second type in funds filter 
    // axios.post(refineSearchIndustryType, {
    //   "industryId": "All"
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then((response) => {
    //   if (response.status === 200) {
    //     setFundsIndustry(response.data?.industryList)
    //   }
    // }).catch(onErrorHandler)

    //Set captial source in funds filter 
    // axios.post(refineSearchCapitalSource, {
    //   "capitalId": "All"
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then((response) => {
    //   if (response.status === 200) {
    //     setCapitalSource(response.data?.capitalSource)
    //   }
    // }).catch(onErrorHandler)

    //set sector in funds filter
    axios.post(refineSearchFundSector, {
      "sectorId": "All"
    }, {
      headers: {
        "Authorization": getToken()
      }
    }).then((response) => {
      if (response.status === 200) {
        setFundSector(response.data?.sector)
      }
    }).catch(onErrorHandler)

    // //Set Stage filter list
    // axios.post(filterStageUrl, {
    //   "stage": "All"
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then(response => {
    //   if (response.status === 200) {
    //     setStageFilterList(response.data);
    //     setStages(response.data)
    //   }
    // }).catch(onErrorHandler)

    //Set Rounds filter list
    // axios.post(filterRoundsUrl, {
    //   "round": "All"
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then(response => {
    //   if (response.status === 200) {
    //     setRoundsFilterList(response.data);
    //     setRounds(response.data)
    //   }
    // }).catch(onErrorHandler)

    //Set Investor type filter list
    // axios.post(filterInvestorTypeUrl, {
    //   "investorType": "All"
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then(response => {
    //   if (response.status === 200) {
    //     setInvestortypeFilterList(response.data);
    //     setInvestorType(response.data)
    //     // setSelectedInvestorType(response.data[0])
    //   }
    // }).catch(onErrorHandler)

    //Set Investor Headquarter filter list
    // axios.post(filterInvestorHeadquarterUrl, {
    //   "investorHeadquarter": "All"
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then(response => {
    //   if (response.status === 200) {
    //     setInvestorHeadQuarterFilterList(response.data);
    //     SetInvestorHeadquarters(response.data)
    //     // setSelectedInvestorHeadquarters(response.data[0])
    //   }
    // }).catch(onErrorHandler)

    //Set Investor Headquarter filter list
    axios.post(filterExitStatusUrl, {
      "exitstatus": "All"
    }, {
      headers: {
        "Authorization": getToken()
      }
    }).then(response => {
      console.log(activeTabDeal, "active")
      if (response.status === 200) {
        setExitStatusResponseData(response.data);
        setExitSatusFilterList(response.data?.investments);
        setExitStatus(response.data?.investments)
      }
    }).catch(onErrorHandler)

    //Set country,region,state,city
    // axios.post(filterLocationUrl, {
    //   country: [],
    //   region: [],
    //   state: [],
    //   city: [],
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then(response => {
    //   if (response.status === 200) {
    //     setCountries(response.data?.countries ?? [])
    //     setRegions(response.data?.region ?? [])
    //     setStates(response.data?.state ?? [])
    //     setCities(response.data?.city ?? [])
    //   }
    // })

    //Set firm type filter list
    // axios.post(refineSearchFirmTypeUrl, {
    //   "firmId": "All"
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then((response) => {
    //   if (response.status === 200) {
    //     setFirmTypeResponseData(response.data?.firmType)
    //     setFirmType(response.data?.firmType?.investments ?? [])
    //     setFirmTypeFilterList(response.data?.firmType?.investments ?? [])
    //   }
    // }).catch(onErrorHandler)


    //Set exit type filter list
    // axios.post(refineSearchExitTypeUrl, {
    //   "exitTypeId": "All"
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then((response) => {
    //   if (response.status === 200) {
    //     setExitTypeFilterList(response.data?.exitType ?? [])
    //     setExitType(response.data?.exitType ?? [])
    //   }
    // }).catch(onErrorHandler)

    //Set deal type filter list
    axios.post(refineSearchDealTypeUrl, {
      "dealTypeId": "All"
    }, {
      headers: {
        "Authorization": getToken()
      }
    }).then((response) => {
      if (response.status === 200) {
        setDealTypeFilterResponse(response.data?.dealType)
        setExitDealType(response.data?.dealType?.others)
      }
    }).catch(onErrorHandler)

    //Set  Re Type filter list
    axios.post(refineSearchTypeUrl, {
      "type": "All"
    }, {
      headers: {
        "Authorization": getToken()
      }
    }).then((response) => {
      if (response?.data?.success === true) {
        // console.log(response?.data?.re_investments,'log TypeFilterResponse');
        setTypeFilterResponse(response?.data)
        // setReExitType(response?.data?.re_exits)
      }
    }).catch(onErrorHandler)
    // console.log(typeFilterResponse,'TypeFilterResponse');

    //Set company type filter list
    // axios.post(refineSearchCompanyTypeUrl, {
    //   "companyType": "All",
    //   "type": primaryMasterId
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then((response) => {
    //   if (response.status === 200) {
    //     setCompanyTypeFilterList(response.data?.companyType ?? [])
    //     setCompanyType(response.data?.companyType ?? [])
    //   }
    // }).catch(onErrorHandler)

    //Set incubation status filter list
    // axios.post(refineSearchIncubationStatusUrl, {
    //   "incStatusId": "All"
    // }, {
    //   headers: {
    //     "Authorization": getToken()
    //   }
    // }).then((response) => {
    //   if (response.status === 200) {
    //     setIncubationStatusList(response.data?.incubationStatus ?? [])
    //     setIncubationStatus(response.data?.incubationStatus ?? [])
    //   }
    // }).catch(onErrorHandler)


    // set Sub sector
    axios.post(filterSubsectorUrl, {
      "sub_sector": "All"
    }, {
      headers: {
        "Authorization": getToken()
      }
    }).then(response => {
      if (response.status === 200) {
        setsubSectorResponseData(response?.data?.sub_sectors)
        setSubSectors(response?.data?.sub_sectors)
      }
    }).catch(onErrorHandler)

  }, [])

  // const selectedAcquirerCountries = acquirercountries.filter(acquirercountry => acquirercountry.isChecked).map(acquirercountry => acquirercountry.id);
  // const selectedCountries = countries.filter(country => country.isChecked).map(country => country.id);
  // const selectedRegion = Regions.filter(region => region.isChecked).map(region => region.id);
  // const selectedStates = States.filter(state => state.isChecked).map(state => state.id);

  // const selectedCountriesDeps = selectedCountries.join();
  // const selectedRegionDeps = selectedRegion.join();
  // const selectedStatesDeps = selectedStates.join();

  // useEffect(() => {
  //   const ids = Industries?.filter(industry => industry?.isChecked)?.map(industry => industry.id);
  //   getSectorsByIndustries(ids)
  // }, [Industries])


  // useDidMountEffect(() => {
  //   axios.post(filterLocationUrl, {
  //     country: selectedCountries,
  //     region: selectedRegion,
  //     state: [],
  //     city: [],
  //   }, {
  //     headers: {
  //       "Authorization": getToken()
  //     }
  //   }).then(response => {
  //     if (response.status === 200) {
  //       setStates(response.data?.state ?? [])
  //       setCities(response.data?.city ?? [])
  //     }
  //   }).catch(onErrorHandler)
  // }, [selectedRegionDeps])


  // useDidMountEffect(() => {

  //   axios.post(filterLocationUrl, {
  //     country: selectedCountries,
  //     region: [],
  //     state: [],
  //     city: [],

  //   }, {
  //     headers: {
  //       "Authorization": getToken()
  //     }
  //   }).then(response => {
  //     if (response.status === 200) {
  //       setRegions(response.data?.region ?? [])
  //       setStates(response.data?.state ?? [])
  //       setCities(response.data?.city ?? [])

  //     }
  //   }).catch(onErrorHandler)
  // }, [selectedCountriesDeps])

  // useDidMountEffect(() => {

  //   axios.post(filterLocationUrl, {
  //     country: selectedCountries,
  //     region: selectedRegion,
  //     state: selectedStates,
  //     city: [],
  //   }, {
  //     headers: {
  //       "Authorization": getToken()
  //     }
  //   }).then(response => {
  //     if (response.status === 200) {
  //       setCities(response.data?.city ?? [])
  //     }
  //   }).catch(onErrorHandler)
  // }, [selectedStatesDeps])

  /* The above code is a useEffect hook in JavaScript. It is used to update the state variables
  `industries` and `companyType` based on the value of `primaryMasterId` and the data received from
  `industryFilterResponseData` and `companyTypeFilterList`. */
  useEffect(() => {
    if (primaryMasterId === Constants.primaryMasterIds.cfsId) {
      setIndustries(industryFilterResponseData?.cfs ?? [])
      setCompanyType(companyTypeFilterList?.cfs ?? [])
    } else {
      setIndustries(industryFilterResponseData?.pe ?? [])
      setCompanyType(companyTypeFilterList?.pe ?? [])
    }
  }, [companyTypeFilterList?.cfs, companyTypeFilterList?.pe, industryFilterResponseData, primaryMasterId])

  //Set company type filter list
  // useDidMountEffect(() => {
  //   axios.post(refineSearchCompanyTypeUrl, {
  //     "companyType": "All",
  //     "type": primaryMasterId
  //   }, {
  //     headers: {
  //       "Authorization": getToken()
  //     }
  //   }).then((response) => {
  //     if (response.status === 200) {
  //       setCompanyTypeFilterList(response.data?.companyType ?? [])
  //       setCompanyType(response.data?.companyType ?? [])
  //     }
  //   }).catch(onErrorHandler)
  // }, [primaryMasterId])





  const selectedIndustryLength = Industries.filter(industry => industry.isChecked)?.length ?? 0
  const selectedFirmTypeLength = FirmType.filter(firmType => firmType.isChecked)?.length ?? 0
  const selectedSectorLength = Sectors.filter(sector => sector.isChecked)?.length ?? 0
  // const selectedSubSectorLength = SubSectors.filter(subSector => subSector.isChecked)?.length ?? 0
  const selectedStageLength = Stages.filter(stage => stage.isChecked)?.length ?? 0
  const selectedRoundLength = Rounds.filter(round => round.isChecked)?.length ?? 0
  const selectedDealsInvolvingLength = DealsInvolving.filter(deal => deal.isChecked)?.length ?? 0
  const selectedCapitalSourceLength = CapitalSource.filter(capital => capital.isChecked)?.length ?? 0
  const selectedFundSectorLength = FundSector.filter(fund => fund.isChecked)?.length ?? 0
  const selectedExitTypeLength = ExitType.filter(exitstype => exitstype.isChecked)?.length ?? 0

  const selectedFollowExitStatusLength = FollowExitStatus.filter(capital => capital.isChecked)?.length ?? 0
  const selectedFundStatusLength = FundStatus.filter(fundstatus => fundstatus.isChecked)?.length ?? 0
  const selectedIncubationStatusLength = IncubationStatus.filter(incubationstatus => incubationstatus.isChecked)?.length ?? 0

  const selectedFundSecondTypeLength = FundSecondType.filter(fundsecondtype => fundsecondtype.isChecked)?.length ?? 0
  const selectedFundTypeLength = FundType.filter(capital => capital.isChecked)?.length ?? 0
  const selectedExitStatusLength = ExitStatus.filter(status => status.isChecked)?.length ?? 0
  const selectedValuationsLength = Valuations.filter(valuation => valuation.isChecked)?.length ?? 0
  const selectedAcquirerCountriesLength = acquirercountries?.filter(acquirercountry => acquirercountry.isChecked)?.length ?? 0
  const selectedCountriesLength = countries?.filter(country => country.isChecked)?.length ?? 0
  const selectedRegionLength = Regions?.filter(region => region.isChecked)?.length ?? 0
  const selectedStateLength = States?.filter(state => state.isChecked)?.length ?? 0
  const selectedCityLength = Cities?.filter(city => city.isChecked)?.length ?? 0
  const selectedExitDealTypeLength = ExitDealType?.filter(type => type.isChecked)?.length ?? 0;

  const [save, setSave] = useState(false);


  const filterDetail = {
    refineSearchValues, setRefineSearchValues,
    Industries, setIndustries, selectedIndustryLength,
    Sectors, setSectors, selectedSectorLength,
    SubSectors, setSubSectors,
    Stages, setStages, selectedStageLength,
    Rounds, setRounds, selectedRoundLength,
    Iocavailable, SetIocavailable,
    YearFounded, StartYearFounded, setStartYearFounded, EndYearFounded, setEndYearFounded,
    DateFounded, StartDateFounded, setStartDateFounded, EndDateFounded, setEndDateFounded,

    DealTypes, setDealTypes, selectedDealType, setSelectedDealType,
    Types, setTypes, selectedType, setSelectedType,
    TargetTypes, setTargetTypes, selectedTargetType, setSelectedTargetType,
    Syndications, setSyndications, selectedSyndication, setSelectedSyndication,
    DealsInvolving, setDealsInvolving, selectedDealsInvolvingLength,
    CapitalSource, setCapitalSource, selectedCapitalSourceLength,
    FundSector, setFundSector, selectedFundSectorLength,
    ExitType, setExitType, selectedExitTypeLength,

    FollowExitStatus, setFollowExitStatus, selectedFollowExitStatusLength,
    FundStatus, setFundStatus, selectedFundStatusLength,
    IncubationStatus, setIncubationStatus, selectedIncubationStatusLength,

    FundType, setFundType, selectedFundTypeLength,
    FundSecondType, setFundSecondType, selectedFundSecondTypeLength,
    Regions, setRegions, selectedRegionLength,
    States, setStates, selectedStateLength,
    Cities, setCities, selectedCityLength,
    InvestorType, setInvestorType, selectedInvestorType, setSelectedInvestorType,
    InvestorHeadquarters, SetInvestorHeadquarters, selectedInvestorHeadquarters,selectedInvestorHeadquartersCity,selectedAcquirers,setSelectedAcquirers,AcquirersOptions,setAcquirersOptions ,lAdvisorsOptions ,tAdvisorsOptions ,setFundsOptions,fundsOptions,fundInvestorsOptions,setFundInvestorsOptions, setlAdvisorsOptions , settAdvisorsOptions ,selectedlAdvisors , selectedtAdvisors,setSelectedlAdvisors , setSelectedtAdvisors, selectedTargetCompany,TargetCompanyOptions , setTargetCompanyOptions ,SubSectorOptions, setSubSectorOptions ,InvestorOptions ,setInvestorOptions , setSelectedTargetCompany , HeadquarterCityOptions ,setHeadquarterCityOptions,  setSelectedInvestorHeadquarters,setSelectedInvestorHeadquartersCity,
    MinDealRange, setMinDealRange, MaxDealRange, setMaxDealRange,
    MinFundRange, setMinFundRange, MaxFundRange, setMaxFundRange,
    MinInvestortDealRange, setMinInvestortDealRange, MaxInvestorDealRange, setMaxInvestorDealRange,
    ExitStatus, setExitStatus, selectedExitStatusLength,
    Valuations, setValuations, selectedValuationsLength,
    monthPickerValue, setMonthPickerValue, advSearchmonthPickerValue, setAdvSearchMonthPickerValue, isMonthPickerChanged, setIsMonthPickerChanged, isTagClosed, setIsTagClosed, showMonthPicker, setShowMontnPicker,
    refreshFilterCount, incrementRefreshCount, countries, setCountries, selectedCountriesLength, acquirercountries, setAcquirerCountries, selectedAcquirerCountriesLength,
    resetFilter, applyFilter, resetDealsByFilter,applyFilterFrom, removeFilter, cfsRemoveFilter, companyType, setCompanyType, selectedCompanyType, setSelectedCompanyType, FinancialResultType, setFinancialResultType, selectedFinancialResultType, setSelectedFinancialResultType,
    InvestorSaleIPO, setInvestorSaleIPO, selectedInvestorSaleIPO, setSelectedInvestorSaleIPO,
    MinReturn, setMinReturn, MaxReturn, setMaxReturn,
    VcFunded, setVcFunded, selectedVcFunded, setSelectedVcFunded,
    Exited, setExited, selectedExited, setSelectedExited,
    ExcludeDefunctCos, setExcludeDefunctCos,setToggleDealsBtn,toggleDealsBtn,checkToggleBtn,
    FirmType, setFirmType, selectedFirmType, setSelectedFirmType, FirmTypeFilterList, selectedFirmTypeLength,
    ExitDealType, setExitDealType, selectedExitDealTypeLength,
    tags, setTags, operator, setOperator ,iocPayload, setIocPayload,
    firmTypeResponseData, exitStatusResponseData, setSectorFilterList, onErrorHandler,
    dealTypeFilterResponse, typeFilterResponse, ReExitType, refineSearchFilters, setrefineSearchFilters, targetTypeFilterResponse,
    InvestoDealRange, InvestoMaxDealRange, FundsStage, FundsIndustry, MinFundSize, MaxFundSize,setIsMobile,isMobile,
    MinAcquirerDealRange, setMinAcquirerDealRange, MaxAcquirerDealRange, setMaxAcquirerDealRange, save, setSave,
    loadPreviousStateRef, subSectorResponseData, setsubSectorResponseData,setOpen,open,AllCities,setAllCities,
    FinancialBasedSelected, setFinancialBasedSelected, RatioBasedSelected, setRatioBasedSelected, GrowthBasedSelected, setGrowthBasedSelected, TransactionStatus, setTransactionStatus, ChargeBasedSelected, setChargeBasedSelected,
    groupId, setGroupId,isNotification, setIsNotification, dealsView, setDealsView, switchValue,SwitchState, setSwitchValue,setSwitchState, targetGroup, setTargetGroup, acquirerGroup, setAcquirerGroup , dropdown ,setDropdown , setDbDropdown , dbDropdown,
  }

  // console.log("refineSearchValuesrefineSearchValues", refineSearchFilters);


  return (
    <PeFilterContext.Provider value={filterDetail}>
      {children}
    </PeFilterContext.Provider>
  )
}

export default PeFilterProvider


