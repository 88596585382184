import React from "react";
import bg from "../../assets/assets/bgimage.jpg";
import { Database, BarChart, Briefcase, Users, Layers } from "lucide-react";

const FinancialDatabaseSection = () => {
  const financialData = {
    mainTitle: "Private Equity - Venture Capital Deals Database",
    type: "differentiators",
    differentiators: [
      { icon: <Database size={18} className="md:w-6 md:h-6" />, title: "PE-VC Investments & Exits" }, 
      { icon: <BarChart size={18} className="md:w-6 md:h-6" />, title: "Pre / Post / Enterprise Valuation" }, 
      { icon: <Briefcase size={18} className="md:w-6 md:h-6" />, title: "PE-VC Investor and LP Directory" }, 
      { icon: <Users size={18} className="md:w-6 md:h-6" />, title: "Post-Deal Cap Table" }, 
      { icon: <Layers size={18} className="md:w-6 md:h-6" />, title: "Industry best TAT on updates", centered: true } 
    ]
  };

  return (
    <div className="relative min-h-[90vh] ] flex items-center justify-center overflow-hidden py-12 md:py-20">
      <div 
        className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="absolute inset-0 bg-black/50"></div>
      </div>

      <div className="relative z-10 container mx-auto px-5 md:px-7 flex flex-col">
        <div className="max-w-5xl mx-auto space-y-10 md:space-y-14 text-center">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-10 md:mb-14">
            <span className="text-[#efefef]">{financialData.mainTitle}</span>
          </h1>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 max-w-4xl mx-auto px-3">
            {financialData.differentiators.slice(0, 4).map((item, index) => (
              <div
                key={index}
                className="bg-white/10 backdrop-blur-sm border border-white/10 rounded-xl p-3 md:p-5
                         hover:bg-white/15 transition-all duration-300 flex items-center gap-3 md:gap-5
                         shadow-md hover:shadow-lg transform hover:-translate-y-1"
              >
                <div className="bg-[#c59c40] rounded-lg p-1.5 md:p-2.5 text-white">
                  {item.icon}
                </div>
                <h3 className="text-sm md:text-base text-white font-semibold">
                  {item.title}
                </h3>
              </div>
            ))}

            <div
              className="col-span-1 md:col-span-2 bg-white/10 backdrop-blur-sm border border-white/10 
                       rounded-xl p-3 md:p-5 hover:bg-white/15 transition-all duration-300 
                       flex items-center justify-center gap-3 md:gap-5 max-w-md mx-auto mt-3 md:mt-4
                       shadow-md hover:shadow-lg transform hover:-translate-y-1"
            >
              <div className="bg-[#c59c40] rounded-lg p-1.5 md:p-2.5 text-white">
                {financialData.differentiators[4].icon}
              </div>
              <h3 className="text-sm md:text-base text-white font-semibold">
                {financialData.differentiators[4].title}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialDatabaseSection;
