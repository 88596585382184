import React from 'react';
import Layout from '../../components/layout';
import { motion } from "framer-motion";
import impact from "../unicorn/impact.png";

const Directories = () => {
    return(
        <Layout>
            <div className="min-h-screen bg-gray-50">
                {/* Hero section with image - UNCHANGED */}
                <div className="relative h-48 mb-12">
                    <div 
                        className="absolute inset-0 bg-cover bg-center"
                        style={{ 
                        backgroundImage: `url(${impact})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                        }}
                    >
                        <div className="absolute inset-0 bg-black/20"></div>
                    </div>
                    
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="relative z-10 h-full flex flex-col items-center justify-center text-center px-4"
                    >
                        <h1 className="text-5xl font-bold text-[#efefef] mb-6 relative group">
                        <span className="relative">
                            DIRECTORY
                        <span className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-[#c59c40] to-[#a07924] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                        </span>
                        </h1>
                    </motion.div>
                </div>

                {/* Content Container with 4 Cards in 2 rows */}
                <div className="max-w-6xl mx-auto px-4 pb-16">
                    {/* First Row - 2 Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                        {/* Card 1 */}
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className="bg-white rounded-lg shadow-md p-6 h-full flex flex-col"
                        >
                            <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                                <span className="text-[#c59c40]">The India Private Equity / Venture Capital Directory</span> 
                            </h2>
                            <ul className="space-y-2 text-gray-700 mb-6 flex-grow">
                                <li className="flex items-start">
                                    <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                    <div>A guide to choosing Private Equity / Venture Capital Partners</div>
                                </li>
                                <li className="flex items-start">
                                    <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                    <div>Directory of all Active Private Equity / Venture Capital investors investing in India</div>
                                </li>
                                <li className="flex items-start">
                                    <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                    <div>User-friendly Spreadsheet Format</div>
                                </li>
                            </ul>
                            <div className="mt-auto">
                                <a 
                                    href="/pedirectory" 
                                    target='_blank'
                                    className="inline-block bg-white border-2 border-[#c59c40] text-[#c59c40] font-semibold py-2 px-4 rounded transition-colors duration-300 hover:bg-[#c59c40] hover:text-white"
                                >
                                    More Information
                                </a>
                            </div>
                        </motion.div>

                        {/* Card 2 */}
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                            className="bg-white rounded-lg shadow-md p-6 h-full flex flex-col"
                        >
                            <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                                <span className="text-[#c59c40]">The India Private Equity-Real Estate Directory</span> 
                            </h2>
                            <ul className="space-y-2 text-gray-700 mb-6 flex-grow">
                                <li className="flex items-start">
                                    <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                    <div>A guide to choosing Private Equity Partners</div>
                                </li>
                                <li className="flex items-start">
                                    <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                    <div>Directory of active institutional investors in Indian Real Estate</div>
                                </li>
                                <li className="flex items-start">
                                    <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                    <div>User-friendly Spreadsheet Format</div>
                                </li>
                            </ul>
                            <div className="mt-auto">
                                <a 
                                    href="/peredirectory"
                                    target='_blank'
                                    className="inline-block bg-white border-2 border-[#c59c40] text-[#c59c40] font-semibold py-2 px-4 rounded transition-colors duration-300 hover:bg-[#c59c40] hover:text-white"
                                >
                                    More Information
                                </a>
                            </div>
                        </motion.div>
                    </div>

                    {/* Second Row - 2 Cards */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Card 3 */}
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="bg-white rounded-lg shadow-md p-6 h-full flex flex-col"
                        >
                            <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                                <span className="text-[#c59c40]">Limited Partners Directory</span> 
                            </h2>
                            <ul className="space-y-2 text-gray-700 mb-6 flex-grow">
                                <li className="flex items-start">
                                    <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                    <div>Listing of more than 200 International Limited Partners actively investing / looking to invest in Indian funds.</div>
                                </li>
                            </ul>
                            <div className="mt-auto">
                                <a 
                                    href="/lpdirectory" 
                                    target="_blank"
                                    className="inline-block bg-white border-2 border-[#c59c40] text-[#c59c40] font-semibold py-2 px-4 rounded transition-colors duration-300 hover:bg-[#c59c40] hover:text-white"
                                >
                                    More Information
                                </a>
                            </div>
                        </motion.div>

                        {/* Card 4 */}
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="bg-white rounded-lg shadow-md p-6 h-full flex flex-col"
                        >
                            <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                                <span className="text-[#c59c40]">Family Office Directory</span> 
                            </h2>
                            <ul className="space-y-2 text-gray-700 mb-6 flex-grow">
                                <li className="flex items-start">
                                    <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                    <div>Details on about 50+ active Family Offices investing in Indian Startups and PE-VC Funds</div>
                                </li>
                                <li className="flex items-start">
                                    <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                    <div>User-friendly Spreadsheet Format</div>
                                </li>
                            </ul>
                            <div className="mt-auto">
                                <a 
                                    href="mailto:bizdev@ventureintelligence.com?subject=Family%20Office%20Directory%20-%20Enquiry" 
                                    className="inline-block bg-white border-2 border-[#c59c40] text-[#c59c40] font-semibold py-2 px-4 rounded transition-colors duration-300 hover:bg-[#c59c40] hover:text-white"
                                >
                                    Mail Us
                                </a>
                            </div>

                        </motion.div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default Directories;