import React, { useEffect } from 'react'
import { useContext } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';

/**
 * The RoundFilter function is a component in JavaScript that renders a filter for selecting rounds in
 * a search feature, with options that can be checked or unchecked.
 * @returns The RoundFilter component is returning a JSX element.
 */
function RoundFilter({ open, accordionClick, RoundOptions, setRoundOptions, customPathname, menu }) {
    const { Rounds, refineSearchFilters } = useContext(PeFilterContext);
    const vcRound = Rounds.filter(round =>  round.name === "Bridge" || round.name === "Seed" ||round.name ===  "1" ||round.name === "2" ||round.name === "3" ||round.name === "4" );

    useEffect(() => {
        const roundsToDisplay = menu === "vc" ? vcRound : Rounds ;
        const industryIds = refineSearchFilters[customPathname]?.round?.map(value => value.id) ?? [];
        setRoundOptions(roundsToDisplay?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [Rounds, customPathname, refineSearchFilters, setRoundOptions])

    const handleRoundChange = (e) => {
        const { name, checked, id, selectedEntities, filteredIds } = e.target;
        let updatedRounds;
    
        if (name === "allSelect") {
            updatedRounds = RoundOptions.map(round => ({ ...round, isChecked: checked }));
        } else if (name === "filteredSelect" && filteredIds) {
            updatedRounds = RoundOptions.map(round => ({
                ...round,
                isChecked: filteredIds.includes(parseInt(round.id)) ? checked : round.isChecked
            }));
        } else if (name === "selectedEntities" && selectedEntities) {
            const selectedEntityIds = selectedEntities.map(entity => parseInt(entity.id));
            updatedRounds = RoundOptions.map(round => ({
                ...round,
                isChecked: selectedEntityIds.includes(round.id) ? !round.isChecked : round.isChecked
            }));
        } else {
            updatedRounds = RoundOptions.map(round =>
                round.id === parseInt(id) ? { ...round, isChecked: checked } : round
            );
        }
    
        console.log('Updated Rounds:', updatedRounds);
        setRoundOptions(updatedRounds);
    };
    

    const len = RoundOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    count={len}
                    isSelect={false}
                    label="Round"
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleRoundChange}
                        primaryEntity={RoundOptions}
                    /> : <></>}

                </div>

            </li>

        </div>
    )
}

export default RoundFilter