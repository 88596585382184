import React, { useContext } from 'react'
import { useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';

/**
 * The `FirmTypeMultiSelect` function is a React component that renders a multi-select dropdown for
 * selecting firm types.
 * @returns a JSX element.
 */
function FirmTypeMultiSelect({ open, accordionClick, FirmTypeOptions, setFirmTypeOptions, customPathname, dataPath }) {

    const { firmTypeResponseData, refineSearchFilters } = useContext(PeFilterContext);

    /* The `useEffect` hook is used to perform side effects in a React functional component. In this
    case, the `useEffect` hook is used to update the `FirmTypeOptions` state based on the values in
    `refineSearchFilters`, `customPathname`, `dataPath`, `firmTypeResponseData`, and
    `setFirmTypeOptions`. */
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.firmType?.map(value => value.id) ?? [];
        setFirmTypeOptions(firmTypeResponseData[dataPath]?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [customPathname, dataPath, firmTypeResponseData, refineSearchFilters, setFirmTypeOptions])


    /* The `handleFirmTypeChange` function is an event handler that is called when the firm type
    selection changes in the multi-select dropdown. It takes an event object `e` as a parameter. */
    const handleFirmTypeChange = (e) => {
        const { name, checked, id, filteredIds } = e.target;
        let currentFirmType = [];
    
        if (name === "allSelect") {
            currentFirmType = FirmTypeOptions.map((type) =>
                ({ ...type, isChecked: checked })
            );
        } else if (name === "filteredSelect" && filteredIds) {
            currentFirmType = FirmTypeOptions.map((type) => {
                if (filteredIds.includes(type.id)) {
                    return { ...type, isChecked: checked };
                }
                return type;
            });
        } else {
            currentFirmType = FirmTypeOptions.map((type) =>
                type.id === parseInt(id) ? { ...type, isChecked: checked } : type
            );
        }
    
        setFirmTypeOptions(currentFirmType);
    };
    
    const len = FirmTypeOptions?.filter(option => option.isChecked)?.length ?? 0


    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"}>
                <FilterWrapper
                    count={len}
                    isSelect={false}
                    label="Firm Type"
                    onClick={accordionClick}
                    open={open}

                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleFirmTypeChange}
                        primaryEntity={FirmTypeOptions}
                    /> : <></>}
                </div>
            </li>
        </div>
    )
}

export default FirmTypeMultiSelect