import React from "react";
import Layout from "../../components/layout/index";

import Hero from "../../components/pe/Hero";
import Products from "../../components/pe/Product1";
import Products2 from "../../components/pe/Product2";
import SubscriptionOptions from "../../components/pe/PricingCard";

const PeProduct = () => {
  return (
    <Layout>
      <Hero />
      <Products />
      <Products2 />
      <SubscriptionOptions />
    </Layout>
  );
};

export default PeProduct;
