import React from 'react';
import Layout from '../../components/layout';
import { motion } from "framer-motion";
import impact from "../unicorn/impact.png";

const NewsLetter = () => {
    return(
        <Layout>
            <div className="min-h-screen bg-gray-50">
                {/* Hero section with image */}
                <div className="relative h-48 mb-12">
                    <div 
                        className="absolute inset-0 bg-cover bg-center"
                        style={{ 
                        backgroundImage: `url(${impact})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                        }}
                    >
                        <div className="absolute inset-0 bg-black/20"></div>
                    </div>
                    
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="relative z-10 h-full flex flex-col items-center justify-center text-center px-4"
                    >
                        <h1 className="text-5xl font-bold text-[#efefef] mb-6 relative group">
                        <span className="relative">
                            NEWSLETTER
                        <span className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-[#c59c40] to-[#a07924] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                        </span>
                        </h1>
                    </motion.div>
                </div>

                {/* Content Container */}
                <div className="max-w-6xl mx-auto px-4 pb-16">
                    {/* Deal Digest Daily Newsletter */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-8"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                            <span className="text-[#c59c40]">Deal Digest Daily Newsletter</span> 
                        </h2>
                        <p className="text-gray-700 mb-6">
                            Delivered by email on all working weekdays, the Deal Digest Daily newsletter summarizes Private Equity / Venture Capital Investment & Exits, IPO, M&A activity in India. Our coverage includes not just completed deals, but "Deals in the Making" as well: i.e., companies planning to raise PE/VC funding or on the IPO path, looking for acquisition etc. The Deal Digest also includes news on new funds being raised, executive movements and much more – making it "start the day with" update for executives in the Indian deal ecosystem
                        </p>
                        
                        <div className="flex flex-wrap gap-4">
                            <a 
                                href="/Dealdigest.html" 
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-6 py-3 bg-[#c59c40] text-white font-medium rounded-md hover:bg-[#a07924] transition-colors duration-300 inline-flex items-center"
                            >
                                Sample Issue
                            </a>
                            <a 
                                href="/trial.html" 
                                target="_blank"
                                rel="noopener noreferrer"
                                className="px-6 py-3 bg-gray-700 text-white font-medium rounded-md hover:bg-gray-800 transition-colors duration-300 inline-flex items-center"
                            >
                                Request Trial
                            </a>
                        </div>
                    </motion.div>

                    {/* Deal Digest Weekly Newsletter */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-8"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                            <span className="text-[#c59c40]">Deal Digest Weekly Newsletter</span> 
                        </h2>
                        <p className="text-gray-700 mb-4">
                            Free to Subscribe for Qualified Investors (PE/VC and Strategic), Advisory Firms and Founders of Entrepreneurial Companies. 
                            <a 
                                href="/weekly-newsletter" 
                                className="text-[#c59c40] hover:text-[#a07924] hover:underline ml-1"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Click here
                            </a> to sign up.
                        </p>
                    </motion.div>
                </div>
            </div>
        </Layout>
    )
};

export default NewsLetter;
