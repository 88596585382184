import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { TableDataContext } from "../../Contexts/TableDataContext";
import { FaFilePdf } from "react-icons/fa";
import Layout from "../../Components/Layout";
import Table from "../../Components/CompanySeekingFunding/CSFTable";
import FilterTag from "../../Components/Tag";
import RefineSearchBar from "../../Components/RemovedArrowRefineSearch";
import SavedFilterContent from "../../Components/SavedFilter";
import Constants from "../../Constants";
import { csfListApi } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import Pagination from "../../Components/Pagination";

function CompanySeekingFunding() {
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [openTab, setOpenTab] = useState(1);
  const { save, setSave, setOpen, setIsMobile } = useContext(PeFilterContext);
  const { currentPage, setCurrentPage } = useContext(TableDataContext);
  const { getToken } = useContext(UserContext);

  // Add sorting state
  const [sortConfig, setSortConfig] = useState({
    sort_by: "industry",
    order: "desc",
  });

  useEffect(() => {
    if (window.innerWidth < 800) {
      setOpen(true);
      setIsMobile(true);
    }
    fetchData();
  }, [sortConfig, openTab, currentPage]); // Add sortConfig as dependency

  // Add function to handle sort
  const handleSort = (column) => {
    setSortConfig((prevConfig) => ({
      sort_by: column,
      order:
        prevConfig.sort_by === column && prevConfig.order === "desc"
          ? "asc"
          : "desc",
    }));
  };

  const fetchData = async () => {
    setLoading(true);
    const payload = {
      per_page: 10,
      order_by: sortConfig, 
      page: currentPage ,
      my_list: openTab === 2 ? true : false,
    };

    try {
      const response = await fetch(csfListApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      setData(result);
      const transformedData = result.data.map((item) => ({
        registered_name: item.registered_name,
        fund_req_id: item.fund_req_id,
        logo_url:item.logo_url,
        company_id:item.company_id,
        industry: item.industry,
        amount_sought: Number(item.amount_sought).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        revenue: Number(item.revenue).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        previously_funded: Number(item.previously_funded || 0).toLocaleString(
          "en-US",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        ),
        pitchdeck: (
          <a
            href={item.pitch_doc_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFilePdf className="cursor-pointer" size={20} color="#d6b15f" />
          </a>
        ),
        created_at: new Date(item.created_at).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      }));

      setTableData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderTabContent = () => {
    return (
      <>
        <div id="overFlow" className="">
          <div className="pl-3 pr-2 lg:pr-2 md:pr-3 overflow-y-auto">
            <div className="App lg:relative">
              <Table
                tableData={tableData}
                loading={loading}
                onSort={handleSort} // Pass sort handler to Table
                sortConfig={sortConfig} // Pass current sort config to Table
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Layout>
      <div className="flex min-h-screen h-screen flex-col">
        <div className="flex min-h-0 flex-1 overflow-hidden">
          <main className="min-w-0 flex-1 border-t border-gray-200 flex mt-12">
            <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
              <div className="mr-[5rem] flex justify-between lg:items-center xl:items-center md:items-center items-baseline">
                <FilterTag />
              </div>
              <div className="pl-3 pr-2">
                <div className="px-2 md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between">
                  <div className="flex flex-row px-3 md:px-3 overflow-x-auto space-x-5 scrollbar-remove">
                    <div className="scrollbar">
                      <div className="rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between">
                        <div className="flex flex-row space-x-5 overflow-x-auto lg:overflow-hidden">
                          <div className="py-4" id="link1">
                            <NavLink
                              className={
                                "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                                (openTab === 1
                                  ? " font-bold text-[#2B2A29] border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                  : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100")
                              }
                              to=""
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(1);
                                setCurrentPage(1);
                              }}
                            >
                              <div className="group inline-block">
                                <button className="outline-none focus:outline-none py-1 rounded-sm flex items-center">
                                  <span className="flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                    Companies Seeking Funding
                                  </span>
                                </button>
                              </div>
                            </NavLink>
                          </div>
                          <div className="py-4 pl-2 lg:pl-0" id="link2">
                            <NavLink
                              className={
                                "whitespace-nowrap lg:text-[14px] lg:leading-[17px] text-[12pt] leading-[17pt]" +
                                (openTab === 2
                                  ? " font-bold text-[#2B2A29] border-b-[3px] border-[#F1CA75] py-4 pt-[1.1rem]"
                                  : "whitespace-nowrap font-sans_medium_body text-[#2B2A29] opacity-[.8] hover:opacity-100")
                              }
                              to=""
                              onClick={(e) => {
                                e.preventDefault();
                                setOpenTab(2);
                                setCurrentPage(2);
                              }}
                            >
                              <div className="group inline-block">
                                <button className="outline-none focus:outline-none py-1 rounded-sm flex items-center">
                                  <span className="flex lg:text-[14px] lg:leading-[15px] text-[12pt] leading-[15pt]">
                                    My Shortlisted Companies
                                  </span>
                                </button>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {save && (
                  <SavedFilterContent
                    primaryTypeMasterId={Constants.primaryMasterIds.peId}
                    subTypeMasterId={
                      Constants.primaryMasterSubIds.pe_vc_investments_id
                    }
                    typeId={Constants.moduleIds.companiesId}
                    show={save}
                  />
                )}
              </div>
              <div className="overflow-y-auto">
                {renderTabContent()}
                {data?.total > 0 ? (
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={data?.total}
                    pageSize={10}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                ) : (
                  <></>
                )}
              </div>
            </section>
            {/* <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
              <div className="relative flex overflow-y-auto flex-col border-r border-gray-200 bg-[#FAF5EA]">
                <RefineSearchBar />
              </div>
            </aside> */}
          </main>
        </div>
      </div>
    </Layout>
  );
}

export default CompanySeekingFunding;