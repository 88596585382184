import React, { useContext } from 'react'
import { useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity';
import FilterWrapper from './FilterWrapper';

/**
 * The `FirmTypeFilter` function is a React component that renders a filter for selecting firm types,
 * with options that can be expanded or collapsed.
 * @returns The `FirmTypeFilter` component is returning a JSX fragment containing a `div` element with
 * a class of "border-b-[2px] border-[#E8E6E1]". Inside the `div`, there is a `li` element with a
 * conditional class name based on the value of the `open` prop. Inside the `li` element, there is a `
 */
function FirmTypeFilter({ open, accordionClick, FundsIndustryOptions, setFundsIndustryOptions, customPathname }) {
    const { FundsIndustry, refineSearchFilters } = useContext(PeFilterContext);

    /* The `useEffect` hook in this code is used to perform side effects in a React functional
    component. In this case, it is used to update the `FundsIndustryOptions` state based on the
    values of `FundsIndustry`, `customPathname`, `refineSearchFilters`, and
    `setFundsIndustryOptions`. */
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.fundIndustry?.map(value => value.id) ?? [];
        setFundsIndustryOptions(FundsIndustry?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [FundsIndustry, customPathname, refineSearchFilters, setFundsIndustryOptions])

    /* The `handleFundTypeChange` function is an event handler that is triggered when the user selects
    or deselects a firm type option in the filter. It receives the event object `e` as a parameter. */
    const handleFundTypeChange = (e) => {
        const { name, checked, id, filteredIds } = e.target;
        let currentFundType = [];
    
        if (name === "allSelect") {
            currentFundType = FundsIndustryOptions.map((fundtype) =>
                ({ ...fundtype, isChecked: checked })
            );
        } else if (name === "filteredSelect" && filteredIds) {
            currentFundType = FundsIndustryOptions.map((fundtype) => {
                if (filteredIds.includes(fundtype.id)) {
                    return { ...fundtype, isChecked: checked };
                }
                return fundtype;
            });
        } else {
            currentFundType = FundsIndustryOptions.map((fundtype) =>
                fundtype.id === parseInt(id) ? { ...fundtype, isChecked: checked } : fundtype
            );
        }
    
        setFundsIndustryOptions(currentFundType);
    };
    

    const len = FundsIndustryOptions?.filter(option => option.isChecked)?.length ?? 0
    return (
        <>




            <div className="border-b-[2px] border-[#E8E6E1]">
                <div >
                    <li
                        className={
                            open
                                ? "bg-white relative px-3 py-1"
                                : "relative px-3 py-1"
                        }
                    >
                        <FilterWrapper
                            count={len}
                            isSelect={false}
                            label="Sector"
                            onClick={accordionClick}
                            open={open}
                        />

                        <div
                            className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                            style={{ maxHeight: open ? "10rem" : "" }}
                        >

                            {open === true ? <MultiSelectEntity
                                handleEntityChange={handleFundTypeChange}
                                primaryEntity={FundsIndustryOptions}
                            /> : <></>}
                        </div>

                    </li>

                </div>

            </div>
        </>
    )
}

export default FirmTypeFilter