import React, { useContext, useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import FilterWrapper from './FilterWrapper';
import useScrollIntoView from './useScrollIntoView';

/**
 * It was the component taken from syndication, so the values named syndication actually refer to the Iocavailable values.
 * The `IOCAvailable` function is a React component that renders a dropdown menu for selecting
 * Iocavailable options.
 * @returns a JSX element.
 */
function IOCAvailable({ open, accordionClick, Iocavailable, SetIocavailable, customPathname }) {
    const { Syndications, refineSearchFilters } = useContext(PeFilterContext);
    const listItemRef = useScrollIntoView(open);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.syndication?.map(value => value.value) ?? [];
        SetIocavailable(Syndications?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.value) })));
    }, [Syndications, customPathname, refineSearchFilters, SetIocavailable]);

    const handleIocavailableChange = (e) => {
        const value = e.target.value;
        SetIocavailable(Iocavailable?.map(type => ({ ...type, isChecked: value === "" ? false : type.value === value })));
    };

    const selected = Iocavailable?.filter(option => option.isChecked)?.at(0);

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"}
                ref={listItemRef}
            >
                <FilterWrapper
                    isSelect={true}
                    label={"Index of Charges available?"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700 overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    <select name="" id="" value={selected?.value ?? ""} onChange={handleIocavailableChange}
                        className="my-2 p-2 border border-[#D9BD7F] mr-3 rounded focus:outline-none bg-white w-full">
                        {Iocavailable && Iocavailable?.length > 0 ? (
                            Iocavailable?.map((ioc, index) => (
                                <option value={ioc.value} key={index}>{ioc.name}</option>
                            ))
                        ) : (
                            <h1>No results found!</h1>
                        )}
                    </select>
                </div>
            </li>
        </div>
    );
}

export default IOCAvailable;
