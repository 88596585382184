import React, { useContext, useState } from 'react'
import NewLogo from '../NewLogo'
import { NavLink } from 'react-router-dom'
import { HeaderMenuContext } from '../../Contexts/HeaderMenuContext';
import { UserContext } from '../../Contexts/UserContext';
import { routeNames } from '../../routeSegments';
import Constants from '../../Constants';
import { Tooltip } from 'antd';
import useModule from '../../hooks/useModule';
import ConfirmPopup from '../../Utils/ConfirmPopup';

const TopResultColumn = ({ result, SearchResult }) => {

    const { setSearchText } = useContext(HeaderMenuContext);
    const { getAllowedModules } = useContext(UserContext);
    const allowedModules = getAllowedModules();
    const [alert, setalert] = useState({ message: "", show: false });
    const { isVC, isMA } = useModule();

    const getCompanyDetails = [
        { collection_name: "pe_company", accessor: "name", id: "company_id"},
        { collection_name: "cfs_company", accessor: "full_name", id: "company_id" },
        { collection_name: "cfs_company", accessor: "full_name", id: "company_id" },
        { collection_name: "pe_investor", accessor: "name", id: "investor_id" },
        { collection_name: "limited_partner", accessor: "name", id: "company_id" },
        { collection_name: "angel_investor", accessor: "name", id: "investor_id" },
        { collection_name: "incubator", accessor: "name", id: "company_id" },
        { collection_name: "pe_advisor", accessor: "name", id: "company_id" },
        { collection_name: "re_company", accessor: "name", id: "company_id"},
        { collection_name: "re_investor", accessor: "name", id: "investor_id"},
        { collection_name: "pe_fund", accessor: "fund__name", id: "investor_id", deal_id: "deal_id"},
        { collection_name: "re_fund", accessor: "fund__name", id: "investor_id", deal_id: "deal_id"},
        { collection_name: "re_advisor", accessor: "name", id: "company_id"},
        { collection_name: "pe_acquirer", accessor: "name", id: "company_id" },
    ]
    console.log(result, "top results")

    const getCompanyName = (hit) => {
        // console.log("calling")
        const collectionName = hit.document.collection_name;
        const companyDetail = getCompanyDetails.find(detail => detail.collection_name === collectionName);
        console.log("fft", getCompanyDetails, companyDetail)
    
        if (companyDetail) {
            console.log(hit.document[companyDetail.accessor], "company name")
            return hit.document[companyDetail.accessor];
        }
        return null;
    };

    const asHumanDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short' };
        return date.toLocaleDateString('en-US', options);
    }

    const pevcSubscriber = allowedModules.includes("pe") || allowedModules.includes("vc")
    const cfsSubscriber = allowedModules.includes("cfs");
    const reSubscriber = allowedModules.includes("re");
    const maSubscriber = allowedModules.includes("ma");

    const generalUrlMappings = [
        { accessor: 'pe_company', link: (cmpId) => (pevcSubscriber) ? (isVC) ? routeNames.vcCompanyFundById(cmpId) : (isMA) ? routeNames.maCompanyById(cmpId) : routeNames.peCompanyFundById(cmpId) : routeNames.maCompanyById(cmpId), isAllowed: pevcSubscriber || maSubscriber },
        { accessor: 'pe_investment', link: (cmpId, dealId) => (isVC) ? routeNames.vcCompanyInvestmentDealById(cmpId, dealId) : routeNames.peCompanyInvestmentDealById(cmpId, dealId), isAllowed: pevcSubscriber },
        { accessor: 'pe_exit', link: (cmpId, dealId) => (isVC) ? routeNames.vcCompanyExitsDealById(cmpId, dealId) : routeNames.peCompanyExitsDealById(cmpId, dealId), isAllowed: pevcSubscriber },
        { accessor: 'pe_ipo', link: (cmpId, dealId) => (isVC) ? routeNames.vcCompanyBackedIPOSDealById(cmpId, dealId) : routeNames.peCompanyBackedIPOSDealById(cmpId, dealId), isAllowed: pevcSubscriber },
        { accessor: 'angel', link: (cmpId, dealId) => (isVC) ? routeNames.vcCompanyAngelDealById(cmpId, dealId) : routeNames.peCompanyAngelDealById(cmpId, dealId), isAllowed: pevcSubscriber },
        { accessor: 'incubation', link: (cmpId, dealId) => (isVC) ? routeNames.vcCompanyIncubationDealById(cmpId, dealId) : routeNames.peCompanyIncubationDealById(cmpId, dealId), isAllowed: pevcSubscriber },
        { accessor: 'pe_investor', link: (cmpId) => (isVC) ? routeNames.vcInvestorById(cmpId) : routeNames.peInvestorById(cmpId), isAllowed: pevcSubscriber },
        { accessor: 'angel_investor', link: (cmpId) => (isVC) ? routeNames.vcAngelInvestorById(cmpId) : routeNames.peAngelInvestorById(cmpId), isAllowed: pevcSubscriber },
        { accessor: 'incubator', link: (cmpId) => (isVC) ? routeNames.vcIncubationInvestorById(cmpId) : routeNames.peIncubationInvestorById(cmpId), isAllowed: pevcSubscriber },
        { accessor: 'pe_fund', link: (cmpId, dealId) => routeNames.pe_investors_funds_detail_page(cmpId, dealId), isAllowed: pevcSubscriber },
        { accessor: 'pe_advisor', 
            link: (cmpId, dealId, advisor_type) => {
                // Helper function to choose a route based on type and flags
                const chooseRoute = (routes) => {
                    if (isMA) return routes.ma;
                    if (isVC) return routes.vc;
                    return routes.pe;
                };
                
                switch (advisor_type) {
                    case 'L':
                    // Legal Advisor routes
                    return chooseRoute({
                        ma: routeNames.maAdvisorsById(cmpId),
                        vc: routeNames.vcAdvisorById(cmpId),
                        pe: routeNames.peAdvisorById(cmpId),
                    });
                    case 'T':
                    // Transaction Advisor routes
                    return chooseRoute({
                        ma: routeNames.maTransactionAdvisorsById(cmpId),
                        vc: routeNames.vcTransactionAdvisorById(cmpId),
                        pe: routeNames.peTransactionAdvisorById(cmpId),
                    });
                    default:
                    // Fallback: if no valid advisor_type is provided, default to Transaction Advisor routes.
                    return chooseRoute({
                        ma: routeNames.maAdvisorsById(cmpId),
                        vc: routeNames.vcAdvisorById(cmpId),
                        pe: routeNames.peAdvisorById(cmpId),
                    });
                }
            }, 
            isAllowed: pevcSubscriber 
        },
        { accessor: 'pe_acquirer', link: (cmpId) => routeNames.maAcquiresById(cmpId), isAllowed: maSubscriber },
        { accessor: 'merger_acquisition', link: (cmpId, dealId) => routeNames.maCompanyDealById(cmpId, dealId), isAllowed: maSubscriber },
        { accessor: 're_company', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber },
        { accessor: 're_investment', link: (cmpId, dealId) => routeNames.reCompanyInvestmentDealById(cmpId, dealId), isAllowed: reSubscriber },
        { accessor: 're_exit', link: (cmpId, dealId) => routeNames.reCompanyExitsDealById(cmpId, dealId), isAllowed: reSubscriber },
        { accessor: 're_ipo', link: (cmpId, dealId) => routeNames.reCompanyBackedIPOSDealById(cmpId, dealId), isAllowed: reSubscriber },
        { accessor: 're_investor', link: (cmpId) => routeNames.reInvestorById(cmpId), isAllowed: reSubscriber },
        { accessor: 're_advisor', 
        link: (cmpId, dealId, advisor_type) => {
            if(advisor_type === 'L'){
            return routeNames.reAdvisorById(cmpId)
            }else{
            return routeNames.reTransactionAdvisorById(cmpId)
            }
        }, 
        isAllowed: reSubscriber 
        },
        { accessor: 'cfs_company', link: (cmpId) => routeNames.cfsCompanyPageById(cmpId), isAllowed: cfsSubscriber },
        { accessor: 'limited_partner', link: (cmpId) => (isVC) ? routeNames.vcInvestorsLimitedPartnerById(cmpId) : routeNames.investorsLimitedPartnerById(cmpId), isAllowed: pevcSubscriber },
        { accessor: 're_fund', link: (invId, fundId) => routeNames.re_investors_funds_detail(invId, fundId), isAllowed: reSubscriber},
        { accessor: 're_merger_acquisition', link: (cmpId, dealId) => routeNames.reCompanyOtherMaDealById(cmpId, dealId), isAllowed: reSubscriber}
    ];

    const getCompanyId = (hit) => {
        const collectionName = hit.document.collection_name;
        const companyDetail = getCompanyDetails.find(detail => detail.collection_name === collectionName);
    
        if (companyDetail) {
            return hit.document[companyDetail.id];
        }
        return null;
    };

    const getDealId = (hit) =>{
        const collectionName = hit.document.collection_name;
        const companyDetail = getCompanyDetails.find(detail => detail.collection_name === collectionName);
    
        if (companyDetail) {
            return hit.document[companyDetail.deal_id] || null;
        }
        return null;
    }

    const companyUrlMappings = [
        { accessor: 'pe_investment', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) : routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
        { accessor: 'pe_exit', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) : routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
        { accessor: 'pe_ipo', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) : routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
        { accessor: 'angel', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) : routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
        { accessor: 'incubation', link: (cmpId) => (isVC) ? routeNames.vcCompanyFundById(cmpId) : routeNames.peCompanyFundById(cmpId), isAllowed: pevcSubscriber },
        { accessor: 'merger_acquisition', link: (cmpId) => routeNames.maCompanyById(cmpId), isAllowed: maSubscriber },
        { accessor: 're_investment', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber },
        { accessor: 're_exit', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber },
        { accessor: 're_ipo', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber },
        { accessor: 're_merger_acquisition', link: (cmpId) => routeNames.reCompanyById(cmpId), isAllowed: reSubscriber},
        { accessor: 'pe_fund', link: (cmpId, dealId) => routeNames.pe_investors_funds_detail_page(cmpId, dealId), isAllowed: pevcSubscriber },
        { accessor: 're_fund', link: (cmpId, dealId) => routeNames.re_investors_funds_detail(cmpId, dealId), isAllowed: pevcSubscriber }
    ];


    const generateUrl = (collectionName, cmpId, dealId, advisor_type) => {
        const urlMapping = generalUrlMappings.find(mapping => mapping.accessor === collectionName);
        return urlMapping ? urlMapping.link(cmpId, dealId, advisor_type) : '/';
    };

    const generateCompanyUrl = (collectionName, cmpId, dealId) =>{
        const urlMapping = companyUrlMappings.find(mapping => mapping.accessor === collectionName);
        return urlMapping ? urlMapping.link(cmpId, dealId) : '/';
    };

    const fieldNameMapHuman = {
       pe_company: pevcSubscriber && !(isMA) ? "Transacted Company" : "Company (MA)",
       pe_investor: "PE-VC Investor",
       angel_investor: "Angel Investor",
       incubator:"Incubator",
       pe_fund: "PE-VC Fund",
       limited_partner: "Limited Partner",
       pe_advisor: "Advisor (PE-VC)",
       pe_acquirer: "Acquirer",
       re_company: "PE-RE Backed Company",
       re_investor: "PE-RE Investor",
       re_fund: "PE-RE Fund",
       re_advisor: "Advisor (PE-RE)",
       cfs_company: "Company",
    }

    const onConfirmation = () => {
        setalert({ show: false, message: "" })
    }

    const onBlurColumnClick = () => {
        setalert({ show: true, message: Constants.subscribeProductMsg, isAlertOnly: true, closeBtnName: "OK" })
    }


    const skipDescriptionIfPossible = (highlight, index) => {
        return (
        <span className="whitespace-nowrap overflow-hidden overflow-ellipsis mr-3">
            <Tooltip 
            placement="top"
            color="#ffffff"
            key="#ffffff"
            overlayStyle={{ maxWidth: '30%' }}
            title={
                <span
                key={index}
                dangerouslySetInnerHTML={{ __html: highlight.snippet }}
                className="mark-custom text-black font-sans_book_body flex-grow text-[13px] lg:text-[14px] break-words"
                />
            }>
            <span className="bg-gradient-to-br from-amber-100 to-rose-100 px-1 py-1 flex rounded text-black text-xs hover:cursor-help">
                {fieldNameMapHuman[highlight.field] || highlight.field}
            </span>
            </Tooltip>

        </span>
        )
    }

    const checkMarkUptext = (snippet, coName) => {
        return snippet?.toLowerCase()?.replace(/<\/?mark>/g, '') == coName?.toLowerCase()
    }

    const resultTypeIdentifier = (hit) => {
        return (
            <span className="bg-gradient-to-br from-amber-100 to-rose-100 px-1 py-1 rounded text-black text-xs ml-2">
                {fieldNameMapHuman[hit.document.collection_name]}
            </span>
        )
    };

    const getLogoMenu = (collection) => {
        const logoMap = new Map([
            ["cfs_company", "cfs"],
            ["re_advisor", "re_advisor"],
            ["pe_advisor", "advisor"],
            ["incubator", "incubator"],
            ["limited_partner", "lp"],
            ["pe_acquirer", "ma_acquirer"],
            ["re_fund", "re_investor"],
            ["re_investor", "re_investor"]
        ]);
    
        if (["re_company", "re_investment"].includes(collection)) return "re";
        if (["pe_investor", "angel_investor", "pe_fund"].includes(collection)) return "investor";
    
        return logoMap.get(collection) || "pe";
    };

    const renderHitRow = () => {
        return result?.hits?.map((hit, index) => {  // Added return statement
            const collectionName = hit?.document?.collection_name;
            return (
                <div 
                    key={index} 
                    className="py-1 border-b border-slate-300 align-middle gap-4 px-4 bg-gray-50"
                >
                    <div className="flex items-center my-2 text-[#c48a09]">
                        <div className="flex items-center gap-1 min-w-max">
                            <NewLogo 
                                id={getCompanyId(hit)}
                                menu={getLogoMenu(collectionName)}
                                name={getCompanyName(hit)}
                            />
                            <div className="flex items-center">
                                <NavLink
                                    className="overflow-hidden overflow-ellipsis"
                                    onClick={(e) => {
                                        setSearchText("");
                                        const urlMapping = hit.document.date
                                            ? companyUrlMappings.find(mapping => mapping.accessor === collectionName)
                                            : generalUrlMappings.find(mapping => mapping.accessor === collectionName);
    
                                        if (!urlMapping?.isAllowed) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onBlurColumnClick();
                                        }
                                    }}
                                    to={hit.document.date 
                                        ? generateCompanyUrl(collectionName, getCompanyId(hit), getDealId(hit))
                                        : generateUrl(collectionName, getCompanyId(hit), getDealId(hit), hit.document.advisor_type ? hit.document.advisor_type : null)
                                    }
                                >
                                    {getCompanyName(hit)}
                                    {hit.document.date && (
                                        <NavLink 
                                            className="bg-gradient-to-br from-sky-100 to-red-100 py-0.5 px-2 rounded text-gray-500 text-xs ml-1 "
                                            onClick={(e) => {
                                                setSearchText("");
                                                const urlMapping = generalUrlMappings.find(mapping => mapping.accessor === collectionName);
                                                if (!urlMapping?.isAllowed) {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onBlurColumnClick();
                                                }
                                            }}
                                            to={generateUrl(collectionName, getCompanyId(hit), getDealId(hit), hit.document.advisor_type ? hit.document.advisor_type : null)}
                                        >
                                            {asHumanDate(hit.document.date)}
                                        </NavLink>
                                    )}
                                    {hit.document.advisor_type && (
                                        <span 
                                            className="bg-gradient-to-br from-sky-100 to-red-100 py-1 px-1 rounded text-gray-500 text-xs ml-1"
                                        >
                                            {hit.document.advisor_type === "L" ? "Legal" : hit.document.advisor_type === "T" ? "Transaction" : ""}
                                        </span>
                                    )}
                                </NavLink>
                                <div>
                                    {resultTypeIdentifier(hit)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };    

  return (
    <div className={`${SearchResult?.length - 1 === 0 ? "border-b" : ""}`}>
        <div className="border border-slate-200">
            <div className="w-full text-left">
                <div className="border-y border-slate-300 bg-white text-gray-700 w-full font-semibold px-4 py-1">
                <div className="hidden md:block col-span-1">Entity Name</div>
            </div>
            <div>
                {renderHitRow()}
            </div>
        </div>
        </div>
        {<ConfirmPopup {...alert} onChange={onConfirmation} />}
    </div>
  )
}

export default TopResultColumn