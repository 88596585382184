import React, { useEffect } from "react";
import { Radio } from "antd";
import { useLocation } from "react-router-dom";

const CategoryRadioButtons = ({ category, toggleTableHandler, setCategory }) => {
  const location = useLocation();

  useEffect(() => {
    // Get the 'category' query param from the URL
    const queryParams = new URLSearchParams(location.search);
    const categoryParam = queryParams.get("category");

    // If the param is valid ('t' or 'l'), update the category state
    if (categoryParam === "t" || categoryParam === "l") {
      setCategory(categoryParam);
    }
  }, [location.search, setCategory]);

  return (
    <div className="mt-7">
      <Radio.Group
        value={category}
        onChange={(e) => {
          setCategory(e.target.value);
          toggleTableHandler(e.target.value);
        }}
        buttonStyle="solid"
        size="middle"
        className="custom-radio-group"
      >
        <Radio.Button value="t">Transaction</Radio.Button>
        <Radio.Button value="l">Legal</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default CategoryRadioButtons;
