import React, { useContext, useEffect } from 'react'
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';

/**
 * The `IncubationStatusFilter` function is a React component that renders a filter for selecting
 * incubation status options.
 * @returns a JSX element.
 */
function IncubationStatusFilter({ open, accordionClick, IncubationStatusOptions, setIncubationStatusOptions, customPathname }) {
    const { IncubationStatus, refineSearchFilters } = useContext(PeFilterContext);

    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.incubationStatus?.map(value => value.id) ?? [];
        setIncubationStatusOptions(IncubationStatus?.map(industry => ({ ...industry, isChecked: industryIds.includes(parseInt(industry.id)) })))
    }, [IncubationStatus, customPathname, refineSearchFilters, setIncubationStatusOptions])


    const handleIncubationStatusChange = (e) => {
        const { name, checked, id, filteredIds } = e.target;
        let currentIncubationStatus = [];
    
        if (name === "allSelect") {
            currentIncubationStatus = IncubationStatusOptions.map((status) =>
                ({ ...status, isChecked: checked })
            );
        } else if (name === "filteredSelect" && filteredIds) {
            currentIncubationStatus = IncubationStatusOptions.map((status) => {
                if (filteredIds.includes(status.id)) {
                    return { ...status, isChecked: checked };
                }
                return status;
            });
        } else {
            currentIncubationStatus = IncubationStatusOptions.map((status) =>
                status.id === parseInt(id) ? { ...status, isChecked: checked } : status
            );
        }
    
        setIncubationStatusOptions(currentIncubationStatus);
    };
    

    const len = IncubationStatusOptions?.filter(option => option.isChecked)?.length ?? 0

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    isSelect={false}
                    count={len}
                    label={"Status"}
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleIncubationStatusChange}
                        primaryEntity={IncubationStatusOptions}
                    /> : <></>}
                </div>
            </li>
        </div>
    )
}

export default IncubationStatusFilter