import React from "react";
import Layout from "../../components/layout/index";

import Hero from "../../components/cfs/Hero";
import Products from "../../components/cfs/Product1";
import Products2 from "../../components/cfs/Product2";
import SubscriptionOptions from "../../components/cfs/PricingCard";

const CfsProduct = () => {
  return (
    <Layout>
      <Hero />
      <Products />
      <Products2 />
      <SubscriptionOptions />
    </Layout>
  );
};

export default CfsProduct;
