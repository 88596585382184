import React, { useState, useEffect } from 'react';
import companies1 from './Images/companies1.png';
import companies2 from './Images/companies2.png';
import companies3 from './Images/companies3.png';
import companies4 from './Images/companies4.png';

const Products = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [companies1, companies2, companies3, companies4];

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 5000);

    return () => clearInterval(slideInterval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <section className="bg-white min-h-[90vh] flex items-center justify-center py-6 md:py-12" id="products">
      <div className="container mx-auto px-4">
        {/* Central Title Header */}
        <div className="max-w-6xl mx-auto text-center mb-6 md:mb-10">
          <h2 className="text-3xl font-bold text-gray-800 mt-4 md:mt-10 mb-4 md:mb-8">Companies</h2>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-12 items-center">
          {/* Image Slider Section */}
          <div className="relative w-full flex items-center justify-center">
            <div className="w-full max-w-xl h-[250px] md:h-[400px] flex items-center justify-center overflow-hidden relative">
              <img
                src={images[currentSlide]}
                alt={`Companies ${currentSlide + 1}`}
                className="object-contain max-h-full transition-opacity duration-700 ease-in-out"
              />
            </div>

            {/* Dot Indicators */}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
              {images.map((_, index) => (
                <button
                  key={index}
                  onClick={() => handleDotClick(index)}
                  className={`h-2.5 w-2.5 md:h-3 md:w-3 rounded-full transition-all duration-300 cursor-pointer ${
                    index === currentSlide ? 'bg-[#bc8b20] w-5 md:w-6' : 'bg-gray-300 hover:bg-gray-400'
                  }`}
                />
              ))}
            </div>
          </div>

          {/* Content Section */}
          <div className="space-y-4 md:space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
              {/* PE-VC Investments */}
              <div className="w-[80%]">
                <h3 className="text-lg md:text-xl font-bold mb-3 md:mb-4 text-gray-800 border-b border-[#bc8b20] pb-1 md:pb-2">
                  PE-VC Investments
                </h3>
                <ul className="space-y-1 md:space-y-2">
                  {['Pre / Post Money Valuation', 'Enterprise Valuation', 'Valuation Multiples', 'Cap Table'].map(
                    (item, index) => (
                      <li key={index} className="flex items-center space-x-2">
                        <span className="h-2 w-2 bg-[#bc8b20] rounded-full" />
                        <p className="text-gray-700 text-sm">{item}</p>
                      </li>
                    )
                  )}
                </ul>
              </div>

              {/* PE-VC Exits */}
              <div className="w-[80%]">
                <h3 className="text-lg md:text-xl font-bold mb-3 md:mb-4 text-gray-800 border-b border-[#bc8b20] pb-1 md:pb-2">
                  PE-VC Exits
                </h3>
                <ul className="space-y-1 md:space-y-2">
                  {['Post Money Valuation', 'Return Multiple for exiting investors', 'IRR (for complete exits)'].map(
                    (item, index) => (
                      <li key={index} className="flex items-center space-x-2">
                        <span className="h-2 w-2 bg-[#bc8b20] rounded-full" />
                        <p className="text-gray-700 text-sm">{item}</p>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
              {/* PE-VC Backed Cos – IPOs */}
              <div className="w-[80%]">
                <h3 className="text-lg md:text-xl font-bold mb-3 md:mb-4 text-gray-800 border-b border-[#bc8b20] pb-1 md:pb-2">
                  PE-VC Backed Cos – IPOs
                </h3>
                <ul className="space-y-1 md:space-y-2">
                  {['IPO details', 'Exiting investor details'].map((item, index) => (
                    <li key={index} className="flex items-center space-x-2">
                      <span className="h-2 w-2 bg-[#bc8b20] rounded-full" />
                      <p className="text-gray-700 text-sm">{item}</p>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Cap Tables */}
              <div className="w-[80%]">
                <h3 className="text-lg md:text-xl font-bold mb-3 md:mb-4 text-gray-800 border-b border-[#bc8b20] pb-1 md:pb-2">
                  Cap Tables
                </h3>
                <ul className="space-y-1 md:space-y-2">
                  {['After every deal in the company', 'Detailed Captable post recent deal'].map((item, index) => (
                    <li key={index} className="flex items-center space-x-2">
                      <span className="h-2 w-2 bg-[#bc8b20] rounded-full" />
                      <p className="text-gray-700 text-sm">{item}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </section>
  );
};

export default Products;
