import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { UserContext } from '../Contexts/UserContext'
import Logo from '../images/vi-logo.png';
import Laptop from '../images/laptop.png';
import RingTop from '../images/ring1.png';
import RingBottom from '../images/ring3.png';
import { routeNames } from '../routeSegments';
import LogoutPopup from '../Components/Login/LogoutPopup';
import Loader from '../Components/Loader';
import { createDeviceAuthUrl, ipLoginApi } from '../Config/config';
import Constants from '../Constants';

const Login = () => {
    let redirectTo = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState({ name: 'aa', isAuth: true });
    const { login, isLogging } = useContext(UserContext);
    const [selectedProduct, setSelectedProduct] = useState("1");
    const [logoutPopupData, setLogoutPopupData] = useState({ show: false, browserList: [], onClick: () => { } })
    const [isLoading, setIsLoading] = useState(false);
    const [errorModal, setErrorModal] = useState({
        visible: false,
        ipAddress: '',
        message: ''
    });

    /* The `useEffect` hook in the code snippet is used to perform side effects in a functional
    component. In this case, it is used to make an API call to the `ipLoginApi` endpoint when the
    component mounts. */
    useEffect(() => {
        if (isLogging) {
            return;
        }
        localStorage.clear();
        // Extract IP from URL query parameter
        const searchParams = new URLSearchParams(location.search);
        const ipFromUrl = searchParams.get('ip');
        
        setIsLoading(true);
        axios(`${ipLoginApi}`, {
            method: "POST",
            data: {
                ipaddress: ipFromUrl || undefined // Send IP only if it exists
            }
        })
        .then((data) => {
            setIsLoading(false);

            if (data?.data?.success === true) {
                if (data?.data?.data?.show === true) {
                    setLogoutPopupData({
                        show: true,
                        browserList: data?.data?.data?.deviceDetails ?? [],
                        onClick: (selected) => {
                            if (selected == null) {
                                setLogoutPopupData({ show: false })
                            } else {
                                createDeviceAuth(data.data?.data, selected)
                            }
                        }
                    });
                }else if(data?.data?.data?.showAuthorize === true){
                    redirectTo(routeNames.device_authorization, {
                        state: {
                            loginFrom: selectedProduct,
                            authCode: data?.data?.data?.authCode,
                            email: data?.data?.data?.emailId,
                        }
                    })
                }else if(data?.data?.data?.showDefaultLogin === true){
                    redirectTo(routeNames.login);
                } else {

                    localStorage.setItem(Constants.localStorageKeys.loggedInEmail, data.data?.data?.emailId)
                    localStorage.setItem(Constants.localStorageKeys.accessToken, data.data.data?.access_token)
                    localStorage.setItem(Constants.localStorageKeys.userId, data.data.data?.user_id)
                    localStorage.setItem('csrf_token', data.data.data?.csrf_token)
                    localStorage.setItem(Constants.localStorageKeys.allowedModules, JSON.stringify(data.data.data?.allowed_modules))
                    setUser((user) => ({
                        name: data.data?.data?.emailId,
                        isAuth: true,
                    }));
                    redirectTo(data?.data?.data?.redirectUrl)
                }
            } else {
                // Show error modal when access is denied
                setErrorModal({
                    visible: true,
                    ipAddress: ipFromUrl || (data?.data?.ipAddress || data?.data?.ipaddress || 'Unknown'),
                    message: data?.data?.message || 'Access denied'
                });
            }
        })
        .catch((error) => {
            setIsLoading(false);
            // Handle network or other errors
            setErrorModal({
                visible: true,
                ipAddress: ipFromUrl || (error.response?.data?.ipAddress || error.response?.data?.ipaddress || 'Unknown'),
                message: error.response?.data?.message || 'An unexpected error occurred. Please try again.'
            });
        })
    }, []);

    const handleErrorModalClose = () => {
        // Redirect to the root path of the current domain
        redirectTo('/logout');
    };

    /**
     * The function `createDeviceAuth` takes in `data` and `id` as parameters, logs `data` to the
     * console, and makes a POST request to `createDeviceAuthUrl` with the `loginFrom` and
     * `auth_device_id` properties. If the response status is 200, it redirects to a specific route
     * with the `deviceId`, `loginFrom`, `authCode`, and `email` properties.
     * @param data - The `data` parameter is not used in the `createDeviceAuth` function. It is only
     * logged to the console for debugging purposes.
     * @param id - The `id` parameter is the unique identifier of the device for which you want to
     * create device authorization. It could be a string or a number, depending on how you are
     * generating device IDs in your system.
     */
    const createDeviceAuth = (data, id) => {
        console.log(data,"datadata-");
        axios.post(createDeviceAuthUrl, {
            // "email": "Test2@gmail1.com",
            // "password": "pra12",
            "loginFrom": selectedProduct,
            "auth_device_id": id
        }).then(response => {
            if (response.status === 200) {
                redirectTo(routeNames.device_authorization, {
                    state: {
                        deviceId: id,
                        // password: "pra12",
                        loginFrom: selectedProduct,
                        authCode: response.data?.data?.authCode,
                        email: response.data?.data?.emailId,
                    }
                })
            }
        }).catch(error => {
            // Handle device auth error
            setErrorModal({
                visible: true,
                ipAddress: error.response?.data?.ipAddress || error.response?.data?.ipaddress || 'Unknown',
                message: 'Failed to authorize device. Please try again.'
            });
        })
    }

    return (
        <div>
            <div className="inset-0 h-full w-full object-cover">
                <div className="h-max ">
                    {/* <!-- left side--> */}
                    <div className="grid grid-cols-2">
                        <div className=" lg:col-span-1 hidden lg:block h-screen  lg:bg-[#DBB25B]">
                            <div className="relative">
                                <img src={RingTop} className="absolute w-[130px] ml-[62%] xl:ml-[65.25%] xl:mt-[10%] mt-[9%]" alt="" />
                                <img src={RingBottom} className="absolute mt-[48%] ml-[12.5%] w-[60px]" alt="" />
                                <img src={Laptop} className="absolute m-[16%] " alt="" />
                            </div>
                        </div>
                        {/* <!--right side--> */}
                        <div className="lg:col-span-1 col-span-2 mt-[3rem] px-6 md:px-12 lg:px-22 xl:px-24 mt-[33%]">
                            <div className="mx-auto w-full max-w-screen-sm lg:w-96 lg:px-8">
                                <div className="">
                                    <img className="w-60 mx-auto" src={Logo} alt="vi" />
                                    {isLoading && 
                                        <div className="text-center mx-auto flex justify-center">
                                            <Loader sizeClassName='w-[3rem] h-[3rem]'/>
                                        </div>
                                    }
                                    <h2 className=" text-[24px] lg:text-[16px] text-center tracking-[-0.31pt] font-sans_book_body font-semibold leading-[26.4px] text-[#333333]">
                                        IP login in Progress ...
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {logoutPopupData.show ? <LogoutPopup {...logoutPopupData} /> : <></>}

            <Modal
                visible={errorModal.visible}
                onCancel={handleErrorModalClose}
                closable={true}
                footer={null}
                centered
                maskStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.45)', 
                }}
                width={400}
            >
                <div className="text-center">
                    <p className="mb-2 text-lg font-semibold">Your IP Address: {errorModal.ipAddress}</p>
                    <p className="text-black">{errorModal.message}</p>
                    <p className="mt-4 text-sm text-gray-600">
                        Please contact your administrator for assistance.
                    </p>
                </div>
            </Modal>
        </div>
    )
}

export default Login
