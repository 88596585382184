import React from 'react';
import Layout from '../../components/layout';
import { motion } from "framer-motion";
import impact from "../unicorn/impact.png";

const PEREDirectory = () => {
    return(
        <Layout>
            <div className="min-h-screen bg-gray-50">
                {/* Hero section with image */}
                <div className="relative h-48 mb-12">
                    <div 
                        className="absolute inset-0 bg-cover bg-center"
                        style={{ 
                        backgroundImage: `url(${impact})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                        }}
                    >
                        <div className="absolute inset-0 bg-black/20"></div>
                    </div>
                    
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="relative z-10 h-full flex flex-col items-center justify-center text-center px-4"
                    >
                        <h1 className="text-5xl font-bold text-[#efefef] mb-6 relative group">
                        <span className="relative">
                            PERE DIRECTORY
                        <span className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-[#c59c40] to-[#a07924] transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></span>
                        </span>
                        </h1>
                    </motion.div>
                </div>

                {/* Content Container */}
                <div className="max-w-6xl mx-auto px-4 pb-16">
                    {/* First Section - The India Private Equity/Venture Capital Directory */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-8"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                            <span className="text-[#c59c40]">The India Private Equity-Real Estate Directory - Latest Edition</span> 
                        </h2>
                        <p className='space-y-2 text-gray-700 mb-4'>A guide to choosing Private Equity Partners</p>
                        <ul className="space-y-2 text-gray-700 mb-4">
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>Directory of active institutional investors in Indian companies</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>User-friendly Spreadsheet Format</div>
                            </li>
                        </ul>
                    </motion.div>

                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-8"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                            <span className="text-[#c59c40]">An essential tool for real estate developers seeking funding as well as Service Providers to PE-RE Firms.</span> 
                        </h2>
                        
                        
                        <p className="text-gray-700 mb-4">
                        The India Private Equity-Real Estate Directory provides exhaustive information on the PE firms actively investing in Indian Real Estate companies and projects. The Directory includes the names of key executives, it also lists contact details including email, phone numbers, fax numbers, postal address and website address. (In most cases, the email IDs are direct IDs of relevant executives and not just generic IDs culled from fund websites.)
                        </p>
                    </motion.div>

                    {/* Second Section - Why Purchase */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className="bg-white rounded-lg shadow-md p-6 mb-8"
                    >
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 border-b border-gray-200 pb-2">
                            <span className="text-[#c59c40]">Why Purchase The India Private Equity-Real Estate Directory?</span> 
                        </h2>
                        <ul className="space-y-2 text-gray-700 mb-4">
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>It comes from Venture Intelligence, the most trusted deal information bank in India.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>Given our database of PERE transactions since 2006, Venture Intelligence is in an unparalleled position vis-à-vis understanding PE firms and their investment preferences.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>The PE in Real Estate landscape in India is changing rapidly leading to information getting outdated faster than ever before. With an edition update every year, you can rest assured that The India Private Equity-Real Estate Directory that you purchase contains the latest list of investors.</div>
                            </li>
                            <li className="flex items-start">
                                <div className="flex-shrink-0 text-[#c59c40] mr-2">•</div>
                                <div>For companies seeking funding, an complimentary advertisement in the Venture Intelligence
                                <a href="/Dealdigest.html" target='_blank' className="text-[#c59c40] hover:text-[#a07924] hover:underline mx-1">
                                    Deal Digest
                                </a> 
                                newsletters is included.</div>
                            </li>
                        </ul>
                        
                        <p className="text-gray-700">
                            For more information on the Directory, email us your contact details and convenient time slots for a call, to 
                            <a href="mailto:bizdev@ventureintelligence.com" className="text-[#c59c40] hover:text-[#a07924] hover:underline ml-1">
                                bizdev@ventureintelligence.com
                            </a>
                        </p>
                    </motion.div>
                </div>
            </div>
        </Layout>
    )
};

export default PEREDirectory;