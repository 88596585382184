import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ArchiveContent = ({ yearType, selectedYear, loading: externalLoading }) => {
  const [archiveData, setArchiveData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArchiveData = async () => {
      if (!selectedYear) return;
      
      setLoading(true);
      setError(null);
      
      try {
        let url;
        if (yearType === 'cy') {
          url = `https://www.ventureintelligence.info/league-table/archive/${selectedYear}`;
        } else {
          // For fiscal year (April to March)
          const fiscalYearEnd = selectedYear + 1;
          url = `https://www.ventureintelligence.info/league-table/archive/${selectedYear}-${fiscalYearEnd}`;
        }
        
        console.log("Fetching archive data from:", url);
        const response = await axios.get(url);
        setArchiveData(response.data);
      } catch (err) {
        console.error('Error fetching archive data:', err);
        setError('Failed to load archive data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchArchiveData();
  }, [yearType, selectedYear]);

  const handleImageClick = () => {
    if (archiveData?.data?.link) {
      window.open(archiveData.data.link, '_blank');
    }
  };

  if (loading || externalLoading) {
    return (
      <div className="flex justify-center items-center p-12">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#bc8b20]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-red-50 rounded-lg text-red-700">
        <p>{error}</p>
      </div>
    );
  }

  if (!archiveData || !archiveData.data || !archiveData.data.attachments || archiveData.data.attachments.length === 0) {
    return (
      <div className="p-6 bg-gray-100 rounded-lg">
        <p className="text-lg text-gray-600">No archive data is available for the selected period.</p>
      </div>
    );
  }

  // Function to create rows with 2 images per row
  const renderImages = () => {
    const attachments = archiveData.data.attachments;
    const totalImages = attachments.length;
    const hasOddNumberOfImages = totalImages % 2 !== 0;
    
    return (
      <div className="space-y-6 flex flex-col items-center">
        {/* Create rows with 2 images per row */}
        {Array.from({ length: Math.ceil(totalImages / 2) }).map((_, rowIndex) => {
          const startIdx = rowIndex * 2;
          const isLastRow = startIdx + 2 >= totalImages;
          const isSingleImageInLastRow = isLastRow && hasOddNumberOfImages;
          
          return (
            <div 
              key={`row-${rowIndex}`} 
              className={`grid gap-12 ${
                isSingleImageInLastRow 
                  ? "grid-cols-1" 
                  : "grid-cols-1 md:grid-cols-2"
              } max-w-4xl w-full mx-auto`}
            >
              {attachments.slice(startIdx, startIdx + (isSingleImageInLastRow ? 1 : 2)).map((item, index) => (
                <div 
                  key={`image-${startIdx + index}`} 
                  className={`rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer ${
                    isSingleImageInLastRow ? "mx-auto md:w-1/2" : "w-full"
                  }`}
                  onClick={handleImageClick}
                >
                  <img 
                    src={item.file_path} 
                    alt={`Archive item ${startIdx + index + 1}`} 
                    className="w-full h-auto object-cover"
                    onError={(e) => {
                      e.target.src = "https://via.placeholder.com/400x300?text=Image+Not+Available";
                    }}
                  />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow w-[75%] mx-auto">
      {/* <h3 className="text-xl font-semibold mb-6 text-[#333] text-center">
        {yearType === 'cy' ? `Calendar Year ${selectedYear}` : `Financial Year ${selectedYear}-${selectedYear + 1}`} Archives
      </h3> */}
      
      {renderImages()}
    </div>
  );
};

export default ArchiveContent;