import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import TableLoader from "./TableLoader";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

// Initialize pdfMake with fonts
if (typeof pdfMake !== 'undefined') {
  pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts;
}

// Format number with commas for numbers >= 1000
const formatAmount = (amount) => {
  if (amount >= 1000) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return amount;
};

const Table = ({ loading, tableData, subCategory, showSearchForm, setShowSearchForm }) => {
  const [sortedData, setSortedData] = useState(null);
  const [sortedDirection, setSortedDirection] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [rankMap, setRankMap] = useState({});

  useEffect(() => {
    if (tableData && Array.isArray(tableData)) {
      // Create a copy of the data
      const data = [...tableData];
      
      // Calculate ranks based on amount_sum (higher amount = lower rank number)
      const sortedByAmount = [...data].sort((a, b) => b.amount_sum - a.amount_sum);
      
      // Create a map of advisor_name to rank
      const ranks = {};
      let currentRank = 1;
      let previousAmount = null;
      
      sortedByAmount.forEach((item, index) => {
        // If this amount is different from the previous one, increment the rank
        if (previousAmount !== item.amount_sum) {
          currentRank = index + 1;
        }
        
        ranks[item.advisor_name] = currentRank;
        previousAmount = item.amount_sum;
      });
      
      setRankMap(ranks);
      setSortedData(data);
    }
  }, [tableData]);

  const sortByDealsHandler = () => {
    if (!sortedData) return;
    
    let sorted = [...sortedData];
    if (sortColumn === "deals" && sortedDirection === "asc") {
      sorted.sort((a, b) => b.deal_count - a.deal_count);
      setSortedDirection("desc");
    } else {
      sorted.sort((a, b) => a.deal_count - b.deal_count);
      setSortedDirection("asc");
    }
    setSortColumn("deals");
    setSortedData(sorted);
  };

  const sortByAmountHandler = () => {
    if (!sortedData) return;
    
    let sorted = [...sortedData];
    if (sortColumn === "amount" && sortedDirection === "asc") {
      sorted.sort((a, b) => b.amount_sum - a.amount_sum);
      setSortedDirection("desc");
    } else {
      sorted.sort((a, b) => a.amount_sum - b.amount_sum);
      setSortedDirection("asc");
    }
    setSortColumn("amount");
    setSortedData(sorted);
  };

  // Safely access the table data
  const renderTableData = sortedData || [];

  return (
    <div>
      {loading ? (
        <TableLoader />
      ) : (
        <div className="text-center overflow-x-hidden md:flex justify-center">
          <table className="md:mx-4 bg-[aliceblue] text-xs font-sans rounded-lg overflow-hidden md:text-xl md:leading-[3rem] lg:w-full">
            <thead className="border-b border-[#A26F35]">
              <tr className="text-[#A26F35]">
                <th className="py-2 px-4">Rank</th>
                <th className="text-left">Company Name</th>
                <th className="px-8 cursor-pointer" onClick={sortByDealsHandler}>
                  #of Deals <FontAwesomeIcon icon={faSort} size="xs" style={{ color: "#a26f35" }} />
                </th>
                <th className="px-8 py-[0.6rem] cursor-pointer" onClick={sortByAmountHandler}>
                  Amount $M <FontAwesomeIcon icon={faSort} size="xs" style={{ color: "#a26f35" }} />
                </th>
              </tr>
            </thead>
            <tbody>
              {renderTableData.length > 0 ? (
                renderTableData.map((company, index) => (
                  <tr className="border-t-2 hover:bg-gray-100" key={index}>
                    <td>{rankMap[company.advisor_name] || index + 1}</td>
                    <td className="text-left">{company.advisor_name}</td>
                    <td>{company.deal_count}</td>
                    <td>{formatAmount(company.amount_sum)}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="py-4 text-center text-gray-500">No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Table;