import React, { useState, useMemo, useContext, useRef, useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import { CompareCompanyContext } from '../../../Contexts/CompareCompanyContext.jsx'
import { NavLink } from 'react-router-dom';
import pdf from '../../../images/pdf.png'
import addNoted from "../../../images/AtomFavourite Copy/png/AtomFavourite Copy.png";
import addNotedActive from '../../../images/AtomFavourite Copy 3/png/AtomFavourite Copy 3.png';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { exportPdf, getAngelInvestorDetails, getIncubationInvestorDetails } from "../../../Config/config.jsx"
import InvestorProfileHighlights from './NewInvestorIncubationProfileHighlight.js'
import AngelInvestorProfileHighlights from './NewAngelInvestorProfileHighlights.js'
import InvestorAngelPortfolioCompanies from "./NewAngelPortfolioCompanies.js";
import InvestorIncubationPortfolioCompanies from "./NewIncubatorPortfolioCompanies.js"
import InvestorCo_Investors from './InvestorCo_Investors.js'
import InvestorNewsActivity from './NewInvNewsActivity.js'
import ComparePopUp from '../../../Pages/ComparePopUp.jsx'
import Warning from '../../../images/warning-btn.png'
import AddNote from "../../../Pages/addNote.jsx";
import CompanyLogo from '../../CompanyLogo.jsx'
import RandomLogo from '../../RandomLogo.jsx'
import AlertPopUp from "../../DetailPage/AlertPopUp.jsx";
import PENotesPopup from '../../NotesPopup/PENotesPopup.js'
import SendDealPopUp from "../../SendDealPopUp.jsx";
import PdfDownloader from "../../shared/PdfDownloader.jsx";
import Constants from "../../../Constants/index.js";
import { Tooltip } from "antd";
import axios from 'axios';
import { UserContext } from "../../../Contexts/UserContext.js";
import useModule from "../../../hooks/useModule.js";
import { routeNames } from "../../../routeSegments.js";
import Loader from "../../Loader.jsx";
import AddNotePopover from "../../shared/AddNotePopover.js";
import Tab from '../../shared/Tab.jsx';
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { TableDataContext } from "../../../Contexts/TableDataContext.js";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import NewLogo from "../../NewLogo.jsx";
import { DataFoundContext } from "../../../Contexts/DataFoundContext.js";


/**
 * The `InvestorCompanyDetail` function is a React component that displays the details of an investor
 * company, including its profile, investments, and news activity. It also provides functionality for
 * comparing the company with others and adding notes.
 * @returns The component is returning a JSX element.
 */
const InverstorCompanyDetail = (props) => {
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' });}
    const investments = () => { document.getElementById("investments").scrollIntoView({ behavior: 'smooth' }); }
    const newsActivity = () => { document.getElementById("news").scrollIntoView(({ behavior: 'smooth' })); }
    const [showModal, setShowModal] = useState(false);
    const [showAdd, setShowAdd] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [isActive, setIsActive] = useState(1);
    const [showNote, setShowNote] = useState(false)
    const [showMyNote, setshowMyNote] = useState(false);
    const location = useLocation();
    const [note, setNote] = useState(false)

    const { companyDetail, setCompanyDetail, setCurrentPage } = useContext(CompareCompanyContext);
    const { newsAndActivityDataFound, setNewsAndActivityDataFound } = useContext(DataFoundContext);


    const [profile, setProfile] = useState();
    const [investmentPortfolio, setInvestmentPortfolio] = useState()
    const [newsletter, setNewsletter] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { primaryMasterId, isPE,isVC } = useModule();
    const { investorId } = useParams();
    const [skipScroll, setSkipScroll] = useState(false)
    const [overviewEl, isOverviewVisible, overviewEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [investmentEl, isInvestmentVisible, investmentVisibleEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, newsEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })

    const getAngelInvestorData = () => {
        setIsLoading(true);
        axios.get(`${getAngelInvestorDetails}${investorId}/`).then(response => {
            if (response.status == 200) {
                setProfile(response.data.profile)
                setInvestmentPortfolio(response.data?.angel_deals);
                if(response.data.related_news.length !== 0){
                    setNewsletter(response.data.related_news)
                    setNewsAndActivityDataFound(true);
                }
                setIsLoading(false);
            }

        }).catch(error => {
            setInvestmentPortfolio([]);
            setIsLoading(false);
            setNewsAndActivityDataFound(false);
        })
    }

    const getIncubatorData = () => {
        setIsLoading(true);
        axios.get(`${getIncubationInvestorDetails}${investorId}`).then((res) => {
            if(res.status == 200){
                setProfile(res.data.profile)
                setInvestmentPortfolio(res.data.incubations);
                if(res.data.related_news.length !== 0){
                    setNewsletter(res.data.related_news)
                    setNewsAndActivityDataFound(true);
                }
                setIsLoading(false);
            }
        }).catch((err) => {
            setInvestmentPortfolio([]);
            setIsLoading(false);
            setNewsAndActivityDataFound(false);
        })
    }

    useEffect(() => {

        if(props.menu === 'incubation'){
            getIncubatorData()
        } else getAngelInvestorData();

    }, [investorId])

    const tagsList = profile?.tags?.split(",").map((tag) => {
        const capital = tag.charAt(0).toUpperCase() + tag.slice(1);
        return (
            <li className="mt-2 lg:mt-0 nav-item flex active " role="presentation">
                {capital ? (
                    <p className=" lg:block text-[10.7pt] mt-2 leading-[17pt] lg:leading-[16px] font-sans_book_body px-3 mr-2 py-1 text-center text-[#333333] bg-[#EFEFEF] lg:text-[12px] rounded-[5px]">
                        {capital}
                    </p>
                ) : null}
            </li>
        );
    });

    const desc = profile?.description?.trim() ?? "";
    const descLen = desc?.length;

    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview()
        } else if (tabIndex === 2) {
            investments()
        }  
        else if (tabIndex === 3) {
            newsActivity();
        }  
        setIsActive(tabIndex);
    }

    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])
    

    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } 
        else if (isInvestmentVisible)  {
           
            setIsActiveOnScroll(2);
        }
         else if (isnewsVisible) {
            setIsActiveOnScroll(3);
        }

    }, [isOverviewVisible,isInvestmentVisible, isnewsVisible, setIsActiveOnScroll])

    const subTypeMasterId =() =>{
        if(isPE){
            
            if(props?.page=='angel'){
                return Constants.subCategoryIds.pe_investor_angel_invesments_id
            }
            else if(props?.page=='incubation'){
                return Constants.subCategoryIds.pe_investor_incubation_id
            }
        }
        else if(isVC){
            
            if(props?.page=='angel'){
                return Constants.subCategoryIds.vc_investor_angel_invesments_id
            }
            else if(props?.page=='incubation'){
                return Constants.subCategoryIds.vc_investor_incubation_id
            }

        }
    }

        const { tableInvestorId: invId } = useContext(TableDataContext);
        
         const currentIndex = invId?.indexOf(Number(investorId));

        function getLeftNavLinkRoute(id, page, inverstorId, index) {
            if (id === Constants.primaryMasterIds.vcId) {
                switch (page) {
                case 'angel':
                    return routeNames.vcNewAngelInvestorById(inverstorId[index - 1]);
                case 'incubation':
                    return routeNames.vcNewIncubationInvestorById(inverstorId[index - 1]);
                default:
                    return null;
                }
            } else {
                switch (page) {
                case 'angel':
                    return routeNames.peNewAngelInvestorById(inverstorId[index - 1]);
                case 'incubation':
                    return routeNames.peNewIncubationInvestorById(inverstorId[index - 1]);
                default:
                    return null;
                }
            }
        }
        function getRightNavLinkRoute(id, page, inverstorId, index) {
            if (id === Constants.primaryMasterIds.vcId) {
                switch (page) {
                case 'angel':
                    return routeNames.vcNewAngelInvestorById(inverstorId[index + 1]);
                case 'incubation':
                    return routeNames.vcNewIncubationInvestorById(inverstorId[index + 1]);
                default:
                    return null;
                }
            } else {
                switch (page) {
                case 'angel':
                    return routeNames.peNewAngelInvestorById(inverstorId[index + 1]);
                case 'incubation':
                    return routeNames.peNewIncubationInvestorById(inverstorId[index + 1]);
                default:
                    return null;
                }
            }
        }


    return (
        <>
            <div className="bg-[#FFFFFF] relative">
                <nav className="flex p-1 lg:px-1 px-3 border-b  " aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-1 overflow-x-auto scrollbar-remove">
                        <li className='flex items-center'>
                            <div className='flex items-center whitespace-nowrap'>
                                <NavLink to={location.state?.prevPath != null && location.state?.prevPath != ''
                                    ? location.state?.prevPath
                                    : props.menu == "vc" ? routeNames.vc_investors_vc_investments
                                        : routeNames.pe_investors_pe_vc_investments} className="ml-2 text-[11pt] lg:text-[12px] text-[#666666] font-sans_book_body">
                                    Investor</NavLink>

                            </div>
                        </li>

                        <li>
                            <div className="flex items-center  whitespace-nowrap">
                                <span className=" text-[12px] text-[#666666] mr-1">/</span>
                                <a className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                                    aria-current="page">{profile?.name}</a>
                            </div>
                        </li>
                    </ol>
                {invId && 
                    <>
                        <Tooltip title="Prev" placement="bottom">
                            <NavLink
                            className="absolute top-2 right-8"
                            to={getLeftNavLinkRoute(primaryMasterId, props.page, invId, currentIndex )}
                            >
                            {invId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next" placement="bottom">
                            <NavLink
                            className="absolute top-2 right-4"
                            to={getRightNavLinkRoute(primaryMasterId, props.page, invId, currentIndex)}
                            >
                            {invId.length - 1 === currentIndex ? null : (
                                <FaChevronRight size={15} color='grey'/>
                            )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
                </nav>
                {/* <!-- BarRaiser--> */}
                {isLoading ?
                    <div className="h-[10rem] w-full bg-[#FFFFFF] flex items-center justify-center ">
                        <Loader />
                    </div>
                    :
                    <div className="px-5 ">
                        <div className="lg:hidden mt-3  grid  md:grid-cols-8 grid-cols-10  items-center mb-4">
                            {/* <div className="md:col-span-6 col-span-5" title="Compare Company">
                                
                            </div> */}
                            <div className="flex md:col-span-2 col-span-5 space-x-3">
                                <div >
                                    <AlertPopUp cid={investorId} type_id={2} page="investor"/>
                                </div>
                                <div >
                                    <span title="Save List">
                                        <img className=" cursor-pointer w-[24px] h-[22px]"
                                            src={showNote ? addNotedActive : addNoted} onClick={() => setShowNote(!showNote)}
                                            alt=""
                                        />
                                        {showNote ? <AddNote module="Investors" page="detailpage" type_id={2} cname={profile?.name ?? ""} cid={investorId} sub_type_master_id={subTypeMasterId()} /> : ""}
                                    </span>
                                </div>


                                <div className="col-span-1">
                                    <PENotesPopup note={note} cid={investorId} type_id={2} />
                                </div>
                                <div className="col-span-1">
                                    <SendDealPopUp data={profile?.name ?? ""} />
                                </div>

                                <div >
                                    <img className=" w-5 h-6" src={pdf} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="pb-4 pt-6 bg-[#FFFFFF]">
                            <ul >
                                <li className="w-full flex ">
                                    <div className=" rounded-md">
                                        <NewLogo 
                                            name={profile?.name}
                                            id={investorId}
                                            menu={props?.page === "angel"? "investor" : "incubator"}
                                            page="detail page"
                                        />
                                    </div>

                                    <div className="ml-3 flex flex-1 flex-col">
                                        <a className="text-[#333333] text-[22px] font-semibold  leading-[24px]" href="#"> {profile?.name} </a>
                                        <span className="text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body  ">{profile?.firm_type && profile?.firm_type !== "0" && profile?.firm_type !== "Unknown" ? profile?.firm_type : ""}</span>
                                    </div>

                                    <div className="hidden lg:grid grid-cols-8 gap-2 mr-2 h-10">
                                        <div className="col-span-3 mt-2">

                                        </div>

                                        <div className="col-span-5 mt-[5px]">
                                            <div className="grid grid-cols-5 gap-2 flex items-center">
                                                <div className="col-span-1 ">
                                                    <AlertPopUp cid={investorId} type_id={2} page="investor" />{" "}
                                                </div>

                                                <div className="col-span-1" >
                                                    <AddNotePopover
                                                        title="Save List"
                                                        companyId={investorId}
                                                        companyName={profile?.name ?? ""}
                                                        type_id={props.page=="angel" ? Constants.moduleIds.investorsId : Constants.moduleIds.incubators_id}
                                                        sub_type_master_id={subTypeMasterId()}
                                                        IsDetailed={true} />
                                                </div>
                                                <div className="col-span-1 mt-1">
                                                    <PENotesPopup note={note} cid={investorId} type_id={props.page=="angel" ? Constants.moduleIds.investorsId : Constants.moduleIds.incubators_id} />
                                                </div>
                                                <div className="col-span-1">
                                                    <SendDealPopUp data={profile?.name ?? ""} />
                                                </div>
                                                <div className="col-span-1">
                                                    <PdfDownloader
                                                        url={exportPdf}
                                                        fileName={profile?.name?.replaceAll(/\s/g, '')}
                                                        data={{
                                                            "type_id": 2,
                                                            "entity_id": investorId,
                                                            "category": props.page,
                                                            "type": primaryMasterId
                                                        }} />
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </li>
                                {showAdd ? <AddNote id={1} /> : ""}
                            </ul>
                        </div>
                        
                        {profile?.invested_industries != "" && profile?.invested_industries != null ? <div>
                            <p className="text-[11pt] leading-[17pt] lg:block lg:leading-[16px]  text-[#333333]  lg:text-[13px] font-sans_book_body mr-3 pb-3">
                                Industries (Existing Investments): {profile?.invested_industries}
                            </p>
                        </div> : <></>}


                        <div className="bg-[#FFFFFF]">

                            {props.menu === "incubation" && profile?.additional_info !== "" ? (
                                <div>
                                    <p className=" text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                                        {profile?.additional_info}
                                    </p>
                                </div> ) : 
                                desc != "" && desc != null ? (
                                    <div >
                                        <p className=" text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                                            {/* {ProfileHighlightData?.Description ? */}
                                            <ReactReadMoreReadLess
                                                charLimit={descLen}
                                                readMoreText={"View More "}
                                                readLessText={"View Less "}
                                                readMoreClassName="read-more-less--more lg:[text-12px]"
                                                readLessClassName="read-more-less--less lg:[text-12px]"
                                            >
                                                {props.menu === "incubation" ? profile.additional_info : profile?.description ? desc : "--"}

                                            </ReactReadMoreReadLess>
                                            {/* : ""} */}
                                        </p>
                                    </div>  
                                ) : (
                                    <></>
                            )}
                        </div>
                    </div>
                }
              
            </div>
            <div className="bg-[#EDEDED]">
                <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index scrollbar-remove">
                    <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                    <Tab active={isActive === 2} label={props?.page == "angel" ? "Investments":"Incubatee"} onClick={() => onTabClick(2)} />                   
                    {newsAndActivityDataFound && <Tab active={isActive === 3} label={"Recent News & Activity"} onClick={() => onTabClick(3)} />}
                </div>
                    <div className="">
                            {props?.page == "angel" ?
                                <>
                                    <section ref={overviewEl} className="scroll-mt-14" id="profile"><AngelInvestorProfileHighlights isLoading={isLoading} profile={profile} page={props?.page} /></section>
                                    <section ref={investmentEl} className="scroll-mt-10" id="investments"><InvestorAngelPortfolioCompanies investmentsData={investmentPortfolio} page="angels" investorName={profile?.name} /></section>
                                </>
                                :
                                <>
                                    <section ref={overviewEl} className="scroll-mt-14" id="profile"><InvestorProfileHighlights isLoading={isLoading} profile={profile} page={props?.page} /></section>
                                    <section ref={investmentEl} className="scroll-mt-10" id="investments"><InvestorIncubationPortfolioCompanies investmentsData={investmentPortfolio} page="incubations" investorName={profile?.name} /></section>
                                </>
                            }
                        <section ref={newsEl} className="scroll-mt-14" id="news"><InvestorNewsActivity menu="investorDetail" isLoading={isLoading} news={newsletter}/></section>
                    </div>
            </div>
            {showModal ? (<ComparePopUp id={1} onClose={(e) => setShowModal(false)} />) : null}
        </> 
    )

}

export default InverstorCompanyDetail