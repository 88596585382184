import React from 'react';
import financials from '../../assets/assets/products/CFS DB.png';

const Products = () => {
  return (
    <section className="bg-[#ffffff] min-h-[75vh] flex items-center" id="products">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Image Section on Right */}
          <div className="flex justify-center">
              <div className="w-full max-w-xl shadow-lg rounded-lg overflow-hidden">
                <img
                  src={financials}
                  alt="Company Financials Search"
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
            
            {/* Content Section on Left */}
            <div className="p-4 flex flex-col ">
              <h3 className="text-3xl font-bold mb-6 text-gray-800">
                Financial Statements
              </h3>
              <ul className="space-y-4">
                <li className="flex items-start space-x-3">
                  <span className="h-2 w-2 bg-[#bc8b20] rounded-full mt-2" />
                  <p className="text-gray-600">Consolidated and Standalone</p>
                </li>
                <li className="flex items-start space-x-3">
                  <span className="h-2 w-2 bg-[#bc8b20] rounded-full mt-2" />
                  <p className="text-gray-600">Detailed Profit & Loss, Balance Sheet & Cash Flow Statements</p>
                </li>
                <li className="flex items-start space-x-3">
                  <span className="h-2 w-2 bg-[#bc8b20] rounded-full mt-2" />
                  <p className="text-gray-600">All financial statements in excel format in various denominations (INR Lakhs / Cr & USD Million)</p>
                </li>
              </ul>
              {/* Request a Demo Button Below Points */}
              <div className="mt-6">
                <a 
                  href="/trial.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block px-4 py-2 bg-[#bc8b20] text-white rounded-md hover:bg-[#d6b15f] transition-colors duration-300 text-sm font-medium"
                >
                  Request a Demo
                </a>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;