import React, { useState, useEffect, useMemo, useCallback } from 'react';

/**
 * The `MultiSelectEntity` function is a React component that renders a multi-select dropdown list of
 * entities with search functionality, optimized for handling large datasets.
 */
function MultiSelectEntity(props) {
  const [searchText, setSearchText] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [visibleCount, setVisibleCount] = useState(100); // Initial visible count
  
  // Only re-compute filtered entities when primaryEntity or searchText changes
  const filteredEntities = useMemo(() => {
    if (!searchText) {
      return props.primaryEntity || [];
    }
    
    const lowerSearchText = searchText.toLowerCase();
    return (props.primaryEntity || []).filter(
      entity => entity?.name?.toLowerCase()?.includes(lowerSearchText)
    );
  }, [props.primaryEntity, searchText]);
  
  // Calculate selected count once
  const selectedCount = useMemo(() => 
    (props.primaryEntity || []).filter(option => option.isChecked).length,
  [props.primaryEntity]);
  
  // Memoize event handlers to prevent unnecessary re-renders
  const handleSearchTextChange = useCallback((e) => {
    const value = e.target.value;
    setSearchText(value);
    setVisibleCount(100); // Reset visible count when search changes
    if (props.setCitySearchText) {
      props.setCitySearchText(value);
    }
  }, [props.setCitySearchText]);
  
  const handleSelectAll = useCallback(() => {
    const newCheckedState = !selectAllChecked;
    setSelectAllChecked(newCheckedState);
    
    if (searchText === '') {
      // Handle selecting all entities - compatible with existing parent implementation
      props.handleEntityChange({
        target: { name: 'allSelect', checked: newCheckedState },
      });
    } else {
      // For filtered entities, create a custom event with filteredIds
      // This is compatible with the handleSectorChange implementation you shared
      props.handleEntityChange({
        target: { 
          name: 'filteredSelect', 
          checked: newCheckedState,
          filteredIds: filteredEntities.map(entity => entity.id)
        },
      });
    }
  }, [selectAllChecked, searchText, filteredEntities, props.handleEntityChange]);
  
  // Update selectAllChecked state based on props
  useEffect(() => {
    if (props.city === "city") {
      if (!searchText) {
        // When no search, check if all items are selected
        setSelectAllChecked(selectedCount === (props.primaryEntity || []).length && selectedCount > 0);
      } else {
        // When search is active, check if all filtered items are selected
        const filteredSelectedCount = filteredEntities.filter(option => option.isChecked).length;
        setSelectAllChecked(filteredEntities.length > 0 && filteredSelectedCount === filteredEntities.length);
      }
    }
  }, [props.primaryEntity, selectedCount, filteredEntities, props.city, searchText]);
  
  // Handle scrolling to load more items
  const handleScroll = useCallback((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      // When user scrolls to 2/3 of the visible area, load more
      setVisibleCount(prev => Math.min(prev + 50, filteredEntities.length));
    }
  }, [filteredEntities.length]);
  
  // Only render a subset of entities at a time to improve performance
  const visibleEntities = useMemo(() => {
    return filteredEntities.slice(0, visibleCount);
  }, [filteredEntities, visibleCount]);
  
  // Show load message if we have more items than what's being shown
  const hasMoreItems = filteredEntities.length > visibleCount;
  
  // Determine placeholder text
  const placeholderText = useMemo(() => {
    if (props.city === "city") {
      return selectedCount === 0 ? "Select city" : 
             selectedCount > 1 ? `${selectedCount} cities selected` : 
             `${selectedCount} city selected`;
    } else if (props.city === "country") {
      return selectedCount === 0 ? "Select country" : 
             selectedCount > 1 ? `${selectedCount} countries selected` : 
             `${selectedCount} country selected`;
    }
    return "Search";
  }, [selectedCount, props.city]);
  
  // Determine what message to show when no entities are available
  const getEmptyMessage = () => {
    // If search is active but no results, show "No results found"
    if (searchText && (props.primaryEntity?.length > 0)) {
      return 'No results found!';
    }
    
    // If field is sector and no search, show "Choose Industry"
    if (props?.field === 'sector') {
      return 'Choose Industry';
    }
    
    // Default message
    return 'No results found!';
  };
  
  return (
    <div className="multiselect-container">
      {(props.primaryEntity?.length > 0) && (
        <input
          type="search"
          value={searchText}
          onChange={handleSearchTextChange}
          className="input w-[98%] border-b-2 border-black my-2 p-2 pt-0 focus:outline-none"
          placeholder={placeholderText}
        />
      )}
      
      {filteredEntities && filteredEntities.length > 0 && (props.showAll ?? true) && (
        <div
          className="form-check font-sans_medium_body cursor-default flex text-left py-2 pr-2 w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto hover:bg-gray-50"
        >
          <input
            type="checkbox"
            className="form-check-input"
            name="allSelect"
            id="0"
            checked={selectAllChecked}
            onChange={handleSelectAll}
          />
          <label htmlFor='0' className="form-check-label mx-2 mr-2 cursor-pointer text-[13px] text-[#333333] leading-[17px] font-sans_book_body">
            {searchText ? 
              (selectAllChecked ? "Deselect All" : "Select All") : 
              (selectedCount > 822 ? "Deselect All" : "Select All")
            }
          </label>
        </div>
      )}
      
      <div className="entity-list" onScroll={handleScroll} style={{ maxHeight: "8rem", overflowY: "auto" }}>
        {visibleEntities && visibleEntities.length > 0 ? (
          <>
            {visibleEntities.map((entity, index) => (
              <div
                className="form-check font-sans_medium_body cursor-default flex text-left py-2 pr-2 w-full font-medium text-gray-700 border-b border-gray-100 md:w-auto hover:bg-gray-50"
                key={entity.id || index}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  name={entity.name}
                  id={entity.id}
                  checked={entity?.isChecked || false}
                  onChange={props.handleEntityChange}
                />
                <label htmlFor={entity.id} className="form-check-label mx-2 mr-2 cursor-pointer text-[13px] text-[#333333] leading-[17px] font-sans_book_body">
                  {entity.name}
                </label>
              </div>
            ))}
            
            {hasMoreItems && (
              <div className="py-2 text-center text-gray-500 text-sm">
                Showing {visibleCount} of {filteredEntities.length} items
                {searchText ? " (filtered)" : ""}
              </div>
            )}
          </>
        ) : (
          <h1 className="text-[13px] text-[#333333] leading-[17px] font-sans_book_body p-2">
            {getEmptyMessage()}
          </h1>
        )}
      </div>
    </div>
  );
}

export default React.memo(MultiSelectEntity);