import React, { useContext } from 'react'
import { useEffect } from 'react';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/MultiSelectEntity'
import FilterWrapper from './FilterWrapper';

/**
 * The `SubSector` function is a React component that renders a sub-sector filter option with
 * checkboxes for selecting sub-sectors.
 * @returns The SubSector component is returning a JSX element.
 */
function SubSector({ open, accordionClick, SubSectorOptions, setSubSectorOptions, customPathname ,dataPath}) {

    const { SubSectors, refineSearchFilters } = useContext(PeFilterContext);

    /* The `useEffect` hook in this code is used to update the `SubSectorOptions` state based on
    changes in the `SubSectors`, `customPathname`, `refineSearchFilters`, `setSubSectorOptions`, and
    `dataPath` variables. */
    useEffect(() => {
        const industryIds = refineSearchFilters[customPathname]?.subSector?.map(value => value.id) ?? [];
        setSubSectorOptions(SubSectors[dataPath]?.map(industry => ({ ...industry, isChecked: industryIds.includes(industry.id) })))
    }, [SubSectors, customPathname, refineSearchFilters, setSubSectorOptions,dataPath])


    /* The `handleSubSectorChange` function is a callback function that is triggered when a checkbox in
    the sub-sector filter is clicked. It receives an event object `e` as a parameter. */
    const handleSubSectorChange = (e) => {
        const { name, checked, id, selectedEntities, filteredIds } = e.target;
        let updatedSubsectors;
    
        if (name === "allSelect") {
            updatedSubsectors = SubSectorOptions.map(subSector => ({ ...subSector, isChecked: checked }));
        } else if (name === "filteredSelect" && filteredIds) {
            updatedSubsectors = SubSectorOptions.map(subSector => ({
                ...subSector,
                isChecked: filteredIds.includes(parseInt(subSector.id)) ? checked : subSector.isChecked
            }));
        } else if (name === "selectedEntities" && selectedEntities) {
            const selectedEntityIds = selectedEntities.map(entity => parseInt(entity.id));
            updatedSubsectors = SubSectorOptions.map(subSector => ({
                ...subSector,
                isChecked: selectedEntityIds.includes(subSector.id) ? !subSector.isChecked : subSector.isChecked
            }));
        } else {
            updatedSubsectors = SubSectorOptions.map(subSector =>
                subSector.id == id ? { ...subSector, isChecked: checked } : subSector
            );
        }
    
        setSubSectorOptions(updatedSubsectors);
    };
    

    const len = SubSectorOptions?.filter(option => option.isChecked)?.length ?? 0
    // console.log(len,'selected sector count');

    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li
                className={
                    open
                        ? "bg-white relative px-3 py-1"
                        : "relative px-3 py-1"
                }
            >
                <FilterWrapper
                    count={len}
                    isSelect={false}
                    label="Sub Sector"
                    onClick={accordionClick}
                    open={open}
                />

                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >

                    {open === true ? <MultiSelectEntity
                        handleEntityChange={handleSubSectorChange}
                        primaryEntity={SubSectorOptions}
                    /> : <></>}

                </div>

            </li>

        </div>
    )
}

export default SubSector